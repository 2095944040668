import {
  createButtonSpec,
  createDialSpec,
} from '../controlsComponents/controls.model'
import {
  ENodeControls,
  ENodeTargets,
  EWaveForms,
} from '../rackModule/nodes.model'
import { gainRanges } from '../utils/dimensions-and-ranges'
import {
  defaultModuleSpec,
  EModuleTypes,
  IModuleSpec,
} from './moduleSpecs.model'

const masterOutput = (pos: [number, number] = [0, 0]): IModuleSpec => ({
  ...defaultModuleSpec,
  name: ['A Way Out'],
  namePos: [5, 0.15],
  pos,
  dials: {
    [ENodeControls.GAIN]: createDialSpec({
      pos: [7.25, 9],
      width: 1.5,
      range: gainRanges,
      showValue: false,
    }),
  },
  buttons: {
    [ENodeControls.PRE_POST]: createButtonSpec({
      text: 'pre-gain',
      fontSize: 0.8,
      pos: [4, 9.25],
      width: 3,
      height: 1,
    }),
  },
  inputs: [
    {
      fontSize: 1,
      pos: [1, 9.25],
      width: 1,
      target: ENodeTargets.MASTER_OUTPUT,
    },
  ],
  type: EModuleTypes.MASTER_OUTPUT,
  width: 10,
})

export default masterOutput
