/**
 * normalize
 * (value - min) = amount the value that lies within that range
 * (max - min) = range
 * @Description Take a range, and a value in that range, and return a normalized value from 0 to 1
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {number} from 0 to 1
 */
export const normalize = (value: number, min: number, max: number): number =>
  (value - min) / (max - min)

/**
 * lerp (Linear Interpolation)
 * (max - min) * norm = range multiplied by norm
 * ... + min = add min to previous result to push value back in to range
 * @Description Take a range, and a value between 0 and 1, and return a relative value from the range
 * @param {number} norm 0 - 1
 * @param {number} min
 * @param {number} max
 * @returns {number} from min to max
 */
export const lerp = (norm: number, min: number, max: number): number =>
  (max - min) * norm + min

/**
 * clamp
 * @Description Restricts value to min - max range
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const clamp = (value: number, min: number, max: number): number =>
  Math.min(Math.max(value, min), max)

export const randomBetween = (min: number, max: number) =>
  Math.floor(Math.random() * max) + min

/**
 * mapToRange
 * normalize value from source range, then lerp to dest range
 * @Description Maps a value from source range to destination range.
 * @param {number} value
 * @param {number} sourceMin
 * @param {number} sourceMax
 * @param {number} destMin
 * @param {number} destMax
 * @returns {number}
 */
export const mapToRange = (
  value: number,
  sourceMin: number,
  sourceMax: number,
  destMin: number,
  destMax: number,
): number => lerp(normalize(value, sourceMin, sourceMax), destMin, destMax)

export const mapToLog = (
  value: number,
  sourceMin: number,
  sourceMax: number,
  destMin: number,
  destMax: number,
): number => {
  const minv = Math.log(destMin)
  const maxv = Math.log(destMax)
  const scale = (maxv - minv) / (sourceMax - sourceMin)
  return Math.exp(minv + scale * (value - sourceMin))
}

export const mapFromLog = (
  value: number = 0,
  sourceMin: number = 0,
  sourceMax: number = 0,
  destMin: number = 0,
  destMax: number = 0,
): number => {
  const minv = Math.log(destMin)
  const maxv = Math.log(destMax)
  const scale = (maxv - minv) / (sourceMax - sourceMin)
  return (Math.log(value) - minv) / scale + sourceMin
}
