import {
  ISoundcloudEmbed,
  ISoundcloudPlaylist,
  ISoundcloudTrack,
  ISoundcloudTracks,
} from './soundcloud.model'

// TRACKS

const namespace = 'SOUNDCLOUD'

export class ALoadSoundcloudTracks {
  public static readonly type = `${namespace}/LOAD_TRACKS`

  public static create(): ALoadSoundcloudTracks {
    return { type: ALoadSoundcloudTracks.type }
  }

  public readonly type = ALoadSoundcloudTracks.type
}

export class ALoadedSoundcloudTracks {
  public static readonly type = `${namespace}/LOADED_TRACKS`

  public static create(tracks: ISoundcloudTracks): ALoadedSoundcloudTracks {
    return { type: ALoadedSoundcloudTracks.type, tracks }
  }

  public readonly type = ALoadedSoundcloudTracks.type
  public readonly tracks: ISoundcloudTracks
}

export class ALoadSoundcloudTracksFailed {
  public static readonly type = `${namespace}/LOAD_TRACKS_FAILED`

  public static create(err: any): ALoadSoundcloudTracksFailed {
    return { type: ALoadSoundcloudTracksFailed.type, err }
  }

  public readonly type = ALoadSoundcloudTracksFailed.type
  public readonly err: any
}

export class ASetCurrentScTrack {
  public static readonly type = `${namespace}/SET_CURRENT_TRACK`

  public static create(id: ISoundcloudTrack['id']): ASetCurrentScTrack {
    return { type: ASetCurrentScTrack.type, id }
  }

  public readonly type = ASetCurrentScTrack.type
  public readonly id: ISoundcloudTrack['id']
}

// PLAYLISTS

export class ALoadSoundcloudPlaylists {
  public static readonly type = `${namespace}/LOAD_PLAYLISTS`

  public static create(): ALoadSoundcloudPlaylists {
    return { type: ALoadSoundcloudPlaylists.type }
  }

  public readonly type = ALoadSoundcloudPlaylists.type
}

export class ALoadedSoundcloudPlaylists {
  public static readonly type = `${namespace}/LOADED_PLAYLISTS`

  public static create(
    playlists: ISoundcloudPlaylist[],
  ): ALoadedSoundcloudPlaylists {
    return { type: ALoadedSoundcloudPlaylists.type, playlists }
  }

  public readonly type = ALoadedSoundcloudPlaylists.type
  public readonly playlists: ISoundcloudPlaylist[]
}

export class ALoadSoundcloudPlaylistsFailed {
  public static readonly type = `${namespace}/LOAD_PLAYLISTS_FAILED`

  public static create(err: any): ALoadSoundcloudPlaylistsFailed {
    return { type: ALoadSoundcloudPlaylistsFailed.type, err }
  }

  public readonly type = ALoadSoundcloudPlaylistsFailed.type
  public readonly err: any
}
// EMBED

export class ALoadSoundcloudEmbed {
  public static readonly type = `${namespace}/LOAD_EMBED`

  public static create(
    targetSrc: ISoundcloudTrack | ISoundcloudPlaylist,
  ): ALoadSoundcloudEmbed {
    return { type: ALoadSoundcloudEmbed.type, targetSrc }
  }

  public readonly type = ALoadSoundcloudEmbed.type
  public readonly targetSrc: ISoundcloudTrack | ISoundcloudPlaylist
}

export class ALoadedSoundcloudEmbed {
  public static readonly type = `${namespace}/LOADED_EMBED`

  public static create(embed: ISoundcloudEmbed): ALoadedSoundcloudEmbed {
    return { type: ALoadedSoundcloudEmbed.type, embed }
  }

  public readonly type = ALoadedSoundcloudEmbed.type
  public readonly embed: ISoundcloudEmbed
}

export class ALoadSoundcloudEmbedFailed {
  public static readonly type = `${namespace}/LOAD_EMBED_FAILED`

  public static create(err: any): ALoadSoundcloudEmbedFailed {
    return { type: ALoadSoundcloudEmbedFailed.type, err }
  }

  public readonly type = ALoadSoundcloudEmbedFailed.type
  public readonly err: any
}
