import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  currentScTrack,
  currentScTrackEmbed,
  soundcloudPlaylists,
  soundcloudTracks,
} from '../soundcloud/soundcloud.reducers'
import { rootEpic } from './rootEpic'
import rootReducer from './store.reducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'soundcloudTracks',
    'currentScTrack',
    'currentScTrackEmbed',
    'soundcloudPlaylists',
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const epicMiddleware = createEpicMiddleware()

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(epicMiddleware)),
)

epicMiddleware.run(rootEpic as any)

export default { store, persistor: persistStore(store) }
