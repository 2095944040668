import p5 from '../../../components/P5/p5'
import { W_LOWERCASE } from '../../../shared/keyboard'
import { Mover } from './Mover'

export const FrictionCreator = (
  wd: number,
  he: number,
  parentId: string,
  getOpts: () => any,
) => (s: any) => {
  let opts = getOpts()
  const winW = wd
  const winH = he
  const pId = parentId
  const wind = new p5.Vector(opts.windC, 0)
  const circles: Mover[] = []
  const desc = `The circles have a gravity constant of ${
    opts.gravityC
  } being applied to their acceleration, and also friction of ${
    opts.frictionC
  } whenever they touch an edge. 
    
Hold 'w' to apply a ${wind.x} wind force to the circles.`

  s.setup = () => {
    const canvas = s.createCanvas(winW, winH)
    canvas.parent(pId)
    for (let i = 0; i < 10; i++) {
      const mass = s.random(opts.massMin, opts.massMax)
      circles.push(
        new Mover({
          mass,
          s,
          x: s.random(50, s.width - 50),
          y: s.random(50, s.height - 50),
        }),
      )
    }
  }

  s.draw = () => {
    s.clear()
    opts = getOpts()

    s.textFont('Helvetica')
    s.textSize(30)
    s.fill('#fff')
    s.text(desc, 50, 50, winW - 50)

    circles.forEach(circle => {
      const friction = circle.state.velocity.copy()
      friction.normalize()
      friction.mult(opts.frictionC)

      const gravity = new p5.Vector(0, opts.gravityC)
      gravity.mult(circle.state.mass)
      circle.applyForce(gravity)

      if (s.keyIsDown(W_LOWERCASE)) {
        circle.applyForce(wind)
      }

      circle.edgeBounce(friction)
      circle.update()
      circle.display()
    })
  }
}
