import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import P5 from '../../components/P5/P5Component'
import { EP5_COMPONENTS } from './store/visuals.model'

export class LissajousTable extends Component<RouteComponentProps> {
  public render() {
    return <P5 name={EP5_COMPONENTS.LISSAJOUS_TABLE} />
  }
}
