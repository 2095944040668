import { ENodeTargets } from '../rackModule/nodes.model'
import {
  defaultModuleSpec,
  EModuleTypes,
  IModuleSpec,
} from './moduleSpecs.model'

const basicMult = (pos: [number, number] = [0, 0]): IModuleSpec => ({
  ...defaultModuleSpec,
  namePos: [1, 1],
  pos,
  inputs: [
    {
      fontSize: 1,
      pos: [0.5, 1],
      width: 1,
      target: ENodeTargets.GAIN,
    },
    {
      fontSize: 1,
      pos: [0.5, 6],
      width: 1,
      target: ENodeTargets.GAIN2,
    },
  ],
  outputs: [
    {
      fontSize: 1,
      pos: [0.5, 2],
      width: 1,
      target: ENodeTargets.GAIN,
    },
    {
      fontSize: 1,
      pos: [0.5, 3],
      width: 1,
      target: ENodeTargets.GAIN,
    },
    {
      fontSize: 1,
      pos: [0.5, 4],
      width: 1,
      target: ENodeTargets.GAIN,
    },
    {
      fontSize: 1,
      pos: [0.5, 7],
      width: 1,
      target: ENodeTargets.GAIN2,
    },
    {
      fontSize: 1,
      pos: [0.5, 8],
      width: 1,
      target: ENodeTargets.GAIN2,
    },
    {
      fontSize: 1,
      pos: [0.5, 9],
      width: 1,
      target: ENodeTargets.GAIN2,
    },
  ],
  type: EModuleTypes.MULT,
  width: 2,
})

export default basicMult
