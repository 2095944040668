import { AccelerationCreator } from '../lib/AccelerationCreator'
import { FrictionCreator } from '../lib/FrictionCreator'
import { GravityWindCreator } from '../lib/GravityWindCreator'
import { LissajousCreator } from '../lib/LissajousCreator'
import { TerrainCreator } from '../lib/TerrainCreator'

export interface IVisuals {
  [key: string]: IP5Opts
}

export interface IP5Opts {
  ctrl: any
  opts: any
}

export enum EP5_COMPONENTS {
  ACCELERATION = 'acceleration',
  FRICTION = 'friction',
  GRAVITY_AND_WIND = 'gravity-and-wind',
  LISSAJOUS_TABLE = 'lissajous-table',
  TERRAIN = 'terrain',
}

export const defaultAccelerationOpts: IP5Opts = {
  ctrl: AccelerationCreator,
  opts: {
    edges: {
      bounce: false,
      wrap: false,
    },
    numCircles: 250,
    useMass: false,
  },
}

export const defaultFrictionOpts: IP5Opts = {
  ctrl: FrictionCreator,
  opts: {
    frictionC: -0.5,
    gravityC: 0.6,
    massMax: 8,
    massMin: 2,
    windC: 0.8,
  },
}

export const defaultGravityAndWindOpts: IP5Opts = {
  ctrl: GravityWindCreator,
  opts: {
    gravityC: 0.6,
    massMax: 8,
    massMin: 2,
    windC: 0.8,
  },
}

export const defaultLissajousTableOpts: IP5Opts = {
  ctrl: LissajousCreator,
  opts: {
    angle: 0,
    angleInc: 0.02,
    divisor: 15,
  },
}

export const defaultTerrainFlyoverOpts: IP5Opts = {
  ctrl: TerrainCreator,
  opts: {
    h: 2000,
    hMax: 3000,
    hMin: 100,
    r: 2.75,
    rMax: 8,
    rMin: 2,
    v: 0.04,
    vMax: 0.1,
    vMin: 0,
    z: 200,
    zMax: 250,
  },
}

export const defaultVisuals: IVisuals = {
  [EP5_COMPONENTS.ACCELERATION]: defaultAccelerationOpts,
  [EP5_COMPONENTS.FRICTION]: defaultFrictionOpts,
  [EP5_COMPONENTS.GRAVITY_AND_WIND]: defaultGravityAndWindOpts,
  [EP5_COMPONENTS.LISSAJOUS_TABLE]: defaultLissajousTableOpts,
  [EP5_COMPONENTS.TERRAIN]: defaultTerrainFlyoverOpts,
}
