import classNames from 'classnames'
import React, { Component } from 'react'
import { ActionCreator } from 'redux'
import jrLogo from '../../../assets/images/site/jr-circle.svg'
import tonearm from '../../../assets/images/site/tone-arm.svg'
import AudioElement from '../audioElement/AudioElement.container'
import {
  ADecreaseVolume,
  AIncreaseVolume,
  APauseTrack,
  APlayTrack,
} from './store/trackPlayer.actions'
import { isPlaying, ITrackPlayerComponent } from './store/trackPlayer.models'
import './track-player.scss'

export interface ITrackPlayerState {
  tpc: ITrackPlayerComponent
}

export interface ITrackPlayerDispatch {
  play: ActionCreator<APlayTrack>
  pause: ActionCreator<APauseTrack>
  volumeUp: ActionCreator<AIncreaseVolume>
  volumeDown: ActionCreator<ADecreaseVolume>
}

export interface ITrackPlayerProps
  extends ITrackPlayerState,
    ITrackPlayerDispatch {}

export class TrackPlayerComponent extends Component<ITrackPlayerProps> {
  public constructor(props: ITrackPlayerProps) {
    super(props)
  }

  public render() {
    const hasTrack = this.props.tpc.track.id > 0 && this.props.tpc.player
    const record = hasTrack ? (
      <span className="record">
        <span
          className={classNames({
            paused: this.props.tpc.paused,
            playing: !this.props.tpc.paused,
            'tone-arm': true,
          })}
        >
          <img src={tonearm} alt=" " />
        </span>
        <img
          src={this.props.tpc.track.artwork_url || jrLogo}
          alt=" "
          className={classNames({
            playing: !this.props.tpc.paused,
            sticker: true,
          })}
        />
      </span>
    ) : null
    const details = hasTrack ? (
      <span className="track-player__details">
        <a href={this.props.tpc.track.uri} target="_blank">
          {this.props.tpc.track.title}
        </a>{' '}
        by {this.props.tpc.track.user.username}
      </span>
    ) : null
    return (
      <section className="track-player">
        {record}
        {details}
        <div className="track-player__player">
          <AudioElement controls={true} />
        </div>
      </section>
    )
  }
}
