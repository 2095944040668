import { Point, Rectangle, Size } from 'paper'
import {
  defaultModuleSpec,
  EModuleTypes,
  IModuleSpec,
  IModuleSpecBasic,
} from '../moduleSpecs/moduleSpecs.model'
import { IConfig } from '../setup/config'
import BiquadFilter from './modules/BiquadFilter'
import Delay from './modules/Delay'
import MasterOutput from './modules/MasterOutput'
import Mixer from './modules/Mixer'
import Mult from './modules/Mult'
import Oscillator from './modules/Oscillator'
import Oscilloscope from './modules/Oscilloscope'
import Compressor from './modules/Compressor'
import VuMono from './modules/VuMono'

export interface IRackModuleBase {
  ctx: AudioContext
  config: IConfig
  spec: IModuleSpec
  draw: () => void
}

export interface IRackModule extends IRackModuleBase {
  buildNodes: () => void
  drawCtrls: () => void
  createInputs: () => void
  createOutputs: () => void
  applyNodeDefaults: () => void
}

export const createModules = (
  moduleSpecs: Array<IModuleSpecBasic | IModuleSpec>,
  config: IConfig,
): IRackModule[] =>
  moduleSpecs.map(spec => createModule(formatSpec(spec, config), config))

const formatSpec = (
  spec: IModuleSpec | IModuleSpecBasic,
  config: IConfig,
): IModuleSpec => {
  const { rowHeight, unit } = config.dimensions
  const [xOff, yOff] = spec.pos
  return {
    ...defaultModuleSpec,
    ...spec,
    bounds: new Rectangle(
      new Point(xOff * unit, yOff * rowHeight),
      new Size(spec.width * unit, rowHeight),
    ),
  }
}

export const createModule = (
  spec: IModuleSpec,
  config: IConfig,
): IRackModule => {
  switch (spec.type) {
    case EModuleTypes.VU_METER:
      return VuMono.create(spec, config)
    case EModuleTypes.COMPRESSOR:
      return Compressor.create(spec, config)
    case EModuleTypes.MIXER:
      return Mixer.create(spec, config)
    case EModuleTypes.DELAY:
      return Delay.create(spec, config)
    case EModuleTypes.OSCILLOSCOPE:
      return Oscilloscope.create(spec, config)
    case EModuleTypes.MULT:
      return Mult.create(spec, config)
    case EModuleTypes.BIQUADFILTER:
      return BiquadFilter.create(spec, config)
    case EModuleTypes.MASTER_OUTPUT:
      return MasterOutput.create(spec, config)
    case EModuleTypes.OSCILLATOR:
    default:
      return Oscillator.create(spec, config)
  }
}
