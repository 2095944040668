import { Reducer } from 'redux'

import {
  ADecreasedVolume,
  AIncreasedVolume,
  ATrackPaused,
  ATrackPlayerCreated,
  ATrackPlaying,
} from './trackPlayer.actions'
import { defaultTpc, ITrackPlayerComponent } from './trackPlayer.models'

export const trackPlayerComponent: Reducer<
  ITrackPlayerComponent,
  | ATrackPlayerCreated
  | ATrackPlaying
  | ATrackPaused
  | AIncreasedVolume
  | ADecreasedVolume
> = (state = defaultTpc, action) => {
  switch (action.type) {
    case ATrackPlayerCreated.type: {
      return action.trackPlayerComponent
    }
    case AIncreasedVolume.type: {
      return {
        ...state,
        volume: action.volume,
      }
    }
    case ADecreasedVolume.type: {
      return {
        ...state,
        volume: action.volume,
      }
    }
    case ATrackPlaying.type: {
      return {
        ...state,
        paused: false,
      }
    }
    case ATrackPaused.type: {
      return {
        ...state,
        paused: true,
      }
    }
    default:
      return state
  }
}
