import { MouseEvent, Point } from 'paper'
import { IConfig } from '../setup/config'
import { EColours } from '../utils/colors'
import IOBase from './IOBase'
import {
  EPatchTypes,
  IOConnectionCallbacks,
  IoSpec,
  IOutput,
} from './patching.model'

class Output extends IOBase implements IOutput {
  public static create(
    node: AudioNode,
    spec: IoSpec,
    posOffset: Point,
    config: IConfig,
    callbacks?: IOConnectionCallbacks,
  ) {
    return new Output(node, spec, posOffset, config, callbacks)
  }
  constructor(
    public node: AudioNode,
    public spec: IoSpec,
    public posOffset: Point,
    public config: IConfig,
    public callbacks: IOConnectionCallbacks = {},
  ) {
    super(node, spec, posOffset, config, callbacks)
  }

  public build() {
    this.createSymbol(EPatchTypes.OUTPUT)
    this.symbol.symbol.definition.strokeColor = EColours.WHITE
    this.symbol.set({
      onMouseDown: (e: MouseEvent) => this.config.patchCtrl.startPatch(e, this),
      onMouseDrag: this.config.patchCtrl.dragCable,
      onMouseUp: this.config.patchCtrl.completePatch,
      onMouseEnter: this.highlight.bind(this),
      onMouseLeave: this.unhighlight.bind(this),
    })
  }

  private highlight() {
    if (this.config.patchCtrl.isPatching) {
      return
    }
    this.symbol.symbol.definition.strokeWidth = 8
  }

  private unhighlight() {
    this.symbol.symbol.definition.strokeWidth = 0
  }
}

export default Output
