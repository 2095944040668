import { Link, RouteComponentProps } from '@reach/router'
import React from 'react'
import { NavLink } from '../../components/navLink/NavLink.component'

import { MdSwapHoriz } from 'react-icons/md'
import jrLogo from '../../assets/images/site/jr-circle.svg'
import './home.scss'

export const HomePage = (props: RouteComponentProps) => (
  <div className="home">
    <h1>
      CODE <MdSwapHoriz /> DANCE MUSIC
    </h1>
    <div className="home__grid">
      <p>
        Under the pseudonym{' '}
        <a href="https://soundcloud.com/jim_reaper" target="_blank">
          Jim Reaper
        </a>
        , I like to produce breakbeats, synthwave, house and techno, and have
        released on Rapid Fire Records, Broken Monster, Breakz R Boss and Young
        NRG Productions. The music section pulls in my tracks from soundcloud.
        You can then stream a track and view the various visualisers I have
        coded as part of my efforts to become a better programmer. I have also
        been playing with the web audio API, and used it to build the{' '}
        <NavLink to="jr101" text="JR-101 FM synth" />, and{' '}
        <NavLink to="jrbmod" text="JR B-Mod Modular synth" />.
      </p>
      <Link to="music">
        <img src={jrLogo} width={250} />
      </Link>
      <p>
        As a web developer, I'm always trying to improve both my skills and,
        perhaps more importantly, my understanding of programming. The{' '}
        <Link to="visuals">visuals</Link> page will warehouse my dabblings as I
        blow the dust off hazy memories from physics classes of yore. The
        projects have been coded using{' '}
        <a href="https://p5js.org/" target="_blank">
          P5.js
        </a>{' '}
        and are my implementations of the basic physics tutorials seen on{' '}
        <a href="https://www.youtube.com/user/shiffman" target="_blank">
          The Coding Train YouTube channel
        </a>
        . P5 is a pretty powerful HTML canvas manipulation toolset, and with a
        little basic maths we can create interesting, creative, interactive
        visuals.
      </p>
    </div>
  </div>
)
