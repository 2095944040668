import moment, { Moment } from 'moment'
import SC from 'soundcloud'
const clientId = '7b564ab26ef1c7cbb9ce12128e1c10d5'
const jrId = 145003

SC.initialize({
  client_id: clientId,
  redirect_uri: 'http://example.com/callback',
})

export interface ISoundcloudUser {
  readonly id: number
  readonly kind: string
  readonly permalink: string
  readonly username: string
  readonly last_modified: string
  readonly uri: string
  readonly permalink_url: string
  readonly avatar_url: string
}

export const defaultScUser: ISoundcloudUser = {
  id: 0,
  kind: '',
  permalink: '',
  username: '',
  last_modified: '',
  uri: '',
  permalink_url: '',
  avatar_url: '',
}

export interface ISoundcloudTrack {
  readonly artwork_url: string
  readonly attachments_uri: string
  readonly bpm: string
  readonly comment_count: number
  readonly commentable: boolean
  readonly created_at: string
  readonly description: string
  readonly download_count: number
  readonly download_url: string
  readonly downloadable: boolean
  readonly duration: number
  readonly embeddable_by: string
  readonly favoritings_count: number
  readonly genre: string
  readonly id: number
  readonly isrc: string
  readonly key_signature: any
  readonly kind: string
  readonly label_id: any
  readonly label_name: string
  readonly last_modified: string
  readonly license: string
  readonly original_content_size: number
  readonly original_format: string
  readonly permalink: string
  readonly permalink_url: string
  readonly playback_count: number
  readonly purchase_title: string
  readonly purchase_url: string
  readonly release: string
  readonly release_day: number
  readonly release_month: number
  readonly release_year: number
  readonly reposts_count: number
  readonly retrieved: Moment
  readonly sharing: string
  readonly state: string
  readonly stream_url: string
  readonly streamable: boolean
  readonly tag_list: string
  readonly title: string
  readonly track_type: string
  readonly uri: string
  readonly user: ISoundcloudUser
  readonly user_id: number
  readonly video_url: string
  readonly waveform_url: string
}

export interface ISoundcloudTracks {
  [key: string]: ISoundcloudTrack
}

export const defaultSouncloudTrack: ISoundcloudTrack = {
  artwork_url: '',
  attachments_uri: '',
  bpm: '',
  comment_count: 0,
  commentable: false,
  created_at: '',
  description: '',
  download_count: 0,
  download_url: '',
  downloadable: false,
  duration: 0,
  embeddable_by: '',
  favoritings_count: 0,
  genre: '',
  id: 0,
  isrc: '',
  key_signature: undefined,
  kind: '',
  label_id: undefined,
  label_name: '',
  last_modified: '',
  license: '',
  original_content_size: 0,
  original_format: '',
  permalink: '',
  permalink_url: '',
  playback_count: 0,
  purchase_title: '',
  purchase_url: '',
  release: '',
  release_day: 0,
  release_month: 0,
  release_year: 0,
  reposts_count: 0,
  retrieved: moment(),
  sharing: '',
  state: '',
  stream_url: '',
  streamable: false,
  tag_list: '',
  title: '',
  track_type: '',
  uri: '',
  user: defaultScUser,
  user_id: 0,
  video_url: '',
  waveform_url: '',
}

export interface ISoundcloudPlaylist {
  readonly artwork_url: string
  readonly created_at: string
  readonly description: string
  readonly downloadable: boolean
  readonly duration: number
  readonly ean: any
  readonly embeddable_by: string
  readonly genre: string
  readonly id: number
  readonly kind: string
  readonly label_id: any
  readonly label_name: string
  readonly last_modified: string
  readonly license: string
  readonly permalink: string
  readonly permalink_url: string
  readonly playlist_type: string
  readonly purchase_title: string
  readonly purchase_url: string
  readonly release: string
  readonly release_day: number
  readonly release_month: number
  readonly release_year: number
  readonly sharing: string
  readonly streamable: boolean
  readonly tag_list: string
  readonly title: string
  readonly track_count: number
  readonly tracks: ReadonlyArray<ISoundcloudTrack>
  readonly type: string
  readonly uri: string
  readonly user: any
  readonly user_id: number
}

export interface ISoundcloudEmbed {
  readonly author_name: string
  readonly author_url: string
  readonly description: string
  readonly height: number
  readonly html: any
  readonly provider_name: string
  readonly provider_url: string
  readonly thumbnail_url: string
  readonly title: string
  readonly type: string
  readonly version: number
  readonly width: string
}

export const defaultSoundcloudEmbed = {
  author_name: '',
  author_url: '',
  description: '',
  height: 0,
  html: '',
  provider_name: '',
  provider_url: '',
  thumbnail_url: '',
  title: '',
  type: '',
  version: 0,
  width: '',
}
export const loadTracks = (): Promise<ISoundcloudTrack[]> =>
  SC.get(`users/${jrId}/tracks`)

export const loadPlaylists = (): Promise<ISoundcloudPlaylist[]> =>
  SC.get(`users/${jrId}/playlists`, { embeddable_by: 'all' })

export const loadEmbed = (
  dataSrc: ISoundcloudTrack | ISoundcloudPlaylist,
  autoPlay = false,
): Promise<ISoundcloudEmbed> => {
  return SC.oEmbed(dataSrc.permalink_url, {
    auto_play: autoPlay,
    maxheight: '2000px',
  })
}

export const getAudioElement = (track: ISoundcloudTrack): HTMLAudioElement => {
  const parent = document.getElementById('tpc__player')
  const audio = new Audio()
  audio.setAttribute('crossorigin', 'anonymous')
  audio.setAttribute('controls', 'true')
  audio.setAttribute('autoplay', 'true')
  audio.setAttribute(
    'src',
    [track.stream_url, '?client_id=', clientId, '&allow_redirects=False'].join(
      '',
    ),
  )
  if (parent) {
    while (parent.firstChild) {
      ;(parent.firstChild as HTMLAudioElement).pause()
      parent.removeChild(parent.firstChild)
    }
    parent.appendChild(audio)
  }
  return audio
}

export const formatTracks = (tracks: ISoundcloudTrack[]): ISoundcloudTracks => {
  const ts: ISoundcloudTracks = {}
  tracks.forEach(track => (ts[track.id] = { ...track, retrieved: moment() }))
  return ts
}

export const selectTrack = (
  tracks: ISoundcloudTracks,
  id: ISoundcloudTrack['id'],
): ISoundcloudTrack => tracks[id]

export const getFirstTrackId = (tracks: ISoundcloudTracks) => {
  const len = Object.values(tracks)
  if (len) {
    return sortByDate(tracks)[0].id
  }
  throw new Error('could not get first track id')
}

export const sortByDate = (tracks: ISoundcloudTracks): ISoundcloudTrack[] =>
  Object.values(tracks).sort((a, b) => {
    const x = getTrackMoment(a).valueOf()
    const y = getTrackMoment(b).valueOf()
    return x > y ? -1 : x < y ? 1 : 0
  })

export const getTrackMoment = (track: ISoundcloudTrack): Moment =>
  moment(track.created_at, 'YYYY/MM/DD HH:mm:ss ZZ')

export const getTrackDate = (track: ISoundcloudTrack): string =>
  getTrackMoment(track).format('LL')
