import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import { ActionCreator } from 'redux'
import P5 from '../../../components/P5/P5Component'
import { AUpdateP5Canvas } from '../store/visuals.actions'
import { EP5_COMPONENTS, IP5Opts } from '../store/visuals.model'
import '../visual.scss'

export interface ITerrainStateProps {
  opts: IP5Opts
}

export interface ITerrainDispatchProps {
  update: ActionCreator<AUpdateP5Canvas>
}

export interface ITerrainProps
  extends RouteComponentProps,
    ITerrainStateProps,
    ITerrainDispatchProps {}

export class Terrain extends Component<ITerrainProps> {
  public hInput: any
  public rInput: any
  public zInput: any
  public vInput: any

  constructor(props: ITerrainProps) {
    super(props)
    this.hInput = React.createRef()
    this.rInput = React.createRef()
    this.zInput = React.createRef()
    this.vInput = React.createRef()

    this.updateH = this.updateH.bind(this)
    this.updateR = this.updateR.bind(this)
    this.updateV = this.updateV.bind(this)
    this.updateZ = this.updateZ.bind(this)
  }

  public updateH() {
    this.props.update(EP5_COMPONENTS.TERRAIN, {
      h: parseInt(this.hInput.current.value, 10),
    })
  }

  public updateR() {
    this.props.update(EP5_COMPONENTS.TERRAIN, {
      r: parseFloat(this.rInput.current.value),
    })
  }

  public updateV() {
    this.props.update(EP5_COMPONENTS.TERRAIN, {
      v: parseFloat(this.vInput.current.value),
    })
  }

  public updateZ() {
    this.props.update(EP5_COMPONENTS.TERRAIN, {
      z: parseInt(this.zInput.current.value, 10),
    })
  }

  public render() {
    const { opts } = this.props.opts
    return (
      <section className="terrain">
        <div className="visual_ctrls terrain__ctrls">
          <label>Max Z</label>
          <input
            type="range"
            ref={this.zInput}
            onChange={this.updateZ}
            min="0"
            max={opts.zMax}
            value={opts.z}
            step="1"
          />
          <label>Flight speed</label>
          <input
            type="range"
            ref={this.vInput}
            onChange={this.updateV}
            min={opts.vMin}
            max={opts.vMax}
            value={opts.v}
            step="0.01"
          />
          <label>Rotation</label>
          <input
            type="range"
            ref={this.rInput}
            onChange={this.updateR}
            min={opts.rMin}
            max={opts.rMax}
            value={opts.r}
            step="0.01"
          />
          <label>Zoom</label>
          <input
            type="range"
            ref={this.hInput}
            onChange={this.updateH}
            min={opts.hMin}
            max={opts.hMax}
            step="20"
            value={opts.h}
          />
        </div>
        <P5 name={EP5_COMPONENTS.TERRAIN} />
      </section>
    )
  }
}
