import { createDialSpec } from '../controlsComponents/controls.model'
import { EPosLocations } from '../patching/patching.model'
import { ENodeControls, ENodeTargets } from '../rackModule/nodes.model'
import {
  compressorAttackRanges,
  compressorKneeRanges,
  compressorRatioRanges,
  compressorReleaseRanges,
  compressorThresholdRanges,
  delayTimeRanges,
  dryWetRanges,
  feedbackRanges,
} from '../utils/dimensions-and-ranges'
import {
  defaultModuleSpec,
  EModuleTypes,
  IModuleSpec,
} from './moduleSpecs.model'

const basicCompressor = (pos: [number, number] = [0, 0]): IModuleSpec => ({
  ...defaultModuleSpec,
  type: EModuleTypes.COMPRESSOR,
  name: ['Skvish'],
  namePos: [3, 0],
  pos,
  width: 6,
  dials: {
    [ENodeControls.RATIO]: createDialSpec({
      pos: [0.75, 1.5],
      width: 1,
      range: compressorRatioRanges,
    }),
    [ENodeControls.ATTACK]: createDialSpec({
      text: ENodeControls.ATTACK,
      pos: [2.5, 1.5],
      width: 1,
      range: compressorAttackRanges,
    }),
    [ENodeControls.THRESHOLD]: createDialSpec({
      text: 'thr',
      pos: [4.25, 1.5],
      width: 1,
      range: compressorThresholdRanges,
    }),
    [ENodeControls.KNEE]: createDialSpec({
      text: ENodeControls.KNEE,
      pos: [0.75, 3.5],
      width: 1,
      range: compressorKneeRanges,
    }),
    [ENodeControls.RELEASE]: createDialSpec({
      text: 'Rel',
      pos: [2.5, 3.5],
      width: 1,
      range: compressorReleaseRanges,
    }),
    [ENodeControls.DRY_WET]: createDialSpec({
      text: 'mix',
      pos: [4.25, 3.5],
      width: 1,
      range: dryWetRanges,
    }),
  },
  inputs: [
    {
      name: 'in',
      namePos: EPosLocations.TOP,
      fontSize: 1,
      pos: [1, 9.75],
      width: 1,
      target: ENodeTargets.COMPRESSOR,
    },
  ],
  outputs: [
    {
      name: 'out',
      namePos: EPosLocations.TOP,
      fontSize: 1,
      pos: [4, 9.75],
      width: 1,
      target: ENodeTargets.COMPRESSOR,
    },
  ],
})

export default basicCompressor
