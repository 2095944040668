import { Point } from 'paper'
import { IConfig } from '../setup/config'
import { IDialRange } from '../utils/dimensions-and-ranges'
import positionController from '../utils/positionController'
import rotationController from '../utils/rotationController'
import { IModuleDialSpec } from './controls.model'
import Dial from './Dial'

class ParamController {
  set value(value: number) {
    if (this.param instanceof AudioNode) {
      return
    }
    this.param.value = value
  }

  get value(): number {
    if (this.param instanceof AudioNode) {
      return 0
    }
    return this.param.value
  }

  set rotation(value: number) {
    this.dial.rotation = value
  }

  set range(range: IDialRange) {
    this.dial.range = range
  }

  public static create(
    param: AudioNode | AudioParam,
    spec: IModuleDialSpec,
    onUpdate: (value?: number) => void,
    onReset: () => void,
    posOffset: Point,
    config: IConfig,
  ) {
    return new ParamController(
      param,
      spec,
      onUpdate,
      onReset,
      posOffset,
      config,
    )
  }
  public dial!: Dial

  public constructor(
    public param: AudioNode | AudioParam,
    public spec: IModuleDialSpec,
    public onUpdate: (value?: number) => void,
    public onReset: () => void,
    public posOffset: Point,
    public config: IConfig,
  ) {
    this.createDialCtrl()
  }

  public updateParam(param: AudioNode | AudioParam) {
    this.param = param
  }

  public setText(text: string) {
    this.dial.setText(text)
  }

  public currentRotation(linLog: 'linear' | 'log'): number {
    return rotationController.currentDialValue(this.dial, linLog)
  }

  public rotate(value: number, linLog: 'linear' | 'log') {
    this.dial.rotation = 0
    linLog === 'linear'
      ? rotationController.rotateByLinearValue(this.dial, value)
      : rotationController.rotateByLogValue(this.dial, value)
  }

  private createDialCtrl() {
    this.dial = Dial.create(
      this.spec,
      positionController.getCtrlPosition(this.spec, this.posOffset),
      this.config,
    )
    this.dial.setData({
      update: this.onUpdate,
    })
    this.dial.set({
      onDoubleClick: this.onReset,
    })
  }
}

export default ParamController
