import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IAppState } from '../../../store/store.model'
import {
  AAddTrackToPlayer,
  APauseTrack,
  APlayTrack,
} from '../trackPlayer/store/trackPlayer.actions'
import {
  AddToPlayerButton,
  IAddToPlayerButtonDispatch,
  IAddToPlayerButtonOwnProps,
  IAddToPlayerButtonState,
} from './addToPlayerButton.component'

const mapStateToProps = (
  state: IAppState,
  ownProps: IAddToPlayerButtonOwnProps,
): IAddToPlayerButtonState => ({
  shouldAdd:
    !state.trackPlayerComponent.track ||
    state.trackPlayerComponent.track.id !== ownProps.trackId,
  tpc: state.trackPlayerComponent,
  track: state.soundcloudTracks[ownProps.trackId],
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: IAddToPlayerButtonOwnProps,
): IAddToPlayerButtonDispatch => ({
  add: () => dispatch(AAddTrackToPlayer.create(ownProps.trackId)),
  play: () => dispatch(APlayTrack.create()),
  pause: () => dispatch(APauseTrack.create()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddToPlayerButton)
