import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import P5 from '../../components/P5/P5Component'
import { EP5_COMPONENTS } from './store/visuals.model'

export class Acceleration extends Component<RouteComponentProps> {
  public render() {
    return (
      <div className={EP5_COMPONENTS.ACCELERATION}>
        <P5 name={EP5_COMPONENTS.ACCELERATION} />
      </div>
    )
  }
}
