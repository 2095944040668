import React from 'react'
import { MdPlayCircleOutline } from 'react-icons/md'
import { ActionCreator } from 'redux'
import { ISoundcloudTrack } from '../../../soundcloud/soundcloud.model'
import {
  AAddTrackToPlayer,
  APauseTrack,
  APlayTrack,
} from '../trackPlayer/store/trackPlayer.actions'
import { ITrackPlayerComponent } from '../trackPlayer/store/trackPlayer.models'

export interface IAddToPlayerButtonOwnProps {
  trackId: ISoundcloudTrack['id']
}

export interface IAddToPlayerButtonState {
  shouldAdd: boolean
  tpc: ITrackPlayerComponent
  track: ISoundcloudTrack
}

export interface IAddToPlayerButtonDispatch {
  add: ActionCreator<AAddTrackToPlayer>
  play: ActionCreator<APlayTrack>
  pause: ActionCreator<APauseTrack>
}

export interface IAddToPlayerButtonProps
  extends IAddToPlayerButtonState,
    IAddToPlayerButtonDispatch {}

export const AddToPlayerButton = (props: IAddToPlayerButtonProps) => (
  <button onClick={props.add} disabled={props.track.id === props.tpc.track.id}>
    {props.track.id !== props.tpc.track.id ? (
      <MdPlayCircleOutline />
    ) : (
      'playing'
    )}
  </button>
)
