import { Symbol } from 'paper'
import { IConfig } from '../setup/config'

export const webAudioImgSrcs = [
  { name: 'patch_input', src: './assets/images/web-audio/patch_input.svg' },
  { name: 'patch_output', src: './assets/images/web-audio/patch_output.svg' },
  { name: 'dial', src: './assets/images/web-audio/dial.svg' },
  { name: 'sine', src: './assets/images/web-audio/waveforms/sine.svg' },
  { name: 'square', src: './assets/images/web-audio/waveforms/square.svg' },
  { name: 'sawtooth', src: './assets/images/web-audio/waveforms/sawtooth.svg' },
  { name: 'triangle', src: './assets/images/web-audio/waveforms/triangle.svg' },
  { name: 'logo', src: './assets/images/site/jr-circle.svg' },
]

export const loadSymbols = async (config: IConfig): Promise<any> =>
  new Promise((res, rej) => {
    try {
      webAudioImgSrcs.forEach((img: any, i: number) => {
        config.project.importSVG(img.src, {
          insert: false,
          onLoad: itemGrp => {
            config.symbols[img.name] = new Symbol(itemGrp)
            if (i === webAudioImgSrcs.length - 1) {
              res()
            }
          },
        })
      })
    } catch (e) {
      rej(e.message)
    }
  })
