import React, { Component } from 'react'
import jrLogo from '../../assets/images/site/jr-circle.svg'
import interworld from '../../assets/images/site/the_interworld.jpg'
import { IMusicProps, shouldLoadTracks } from './Music.model'
import './music.scss'
import SelectedTrack from './selectedTrack/SelectedTrack.container'
import TrackList from './trackList/TrackList.container'

export class MusicComponent extends Component<IMusicProps> {
  public componentDidMount() {
    if (shouldLoadTracks(this.props.tracks)) {
      this.props.loadTracks()
    }
  }

  public render() {
    return (
      <section className="music">
        <div className="music__studio">
          <img src={interworld} alt="The Interworld" />
          <img src={jrLogo} alt="Jim Reaper logo" className="jr-logo" />
        </div>
        <TrackList />
        <SelectedTrack />
      </section>
    )
  }
}
