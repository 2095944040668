import React, { Component } from 'react'

import './tooltip.scss'

interface ITooltipProps {
  show: boolean
}

export class Tooltip extends Component<ITooltipProps> {
  public constructor(props: ITooltipProps) {
    super(props)
  }

  public render() {
    return this.props.show ? (
      <div className="tooltip">
        <div className="tooltip__text">{this.props.children}</div>
      </div>
    ) : null
  }
}
