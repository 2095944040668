import {
  createButtonSpec,
  createDialSpec,
} from '../controlsComponents/controls.model'
import { EPosLocations } from '../patching/patching.model'
import {
  ENodeControls,
  ENodeTargets,
  EOutputs,
  EWaveForms,
} from '../rackModule/nodes.model'
import {
  detuneRanges,
  fmGainRanges,
  frequencyRanges,
} from '../utils/dimensions-and-ranges'
import { defaultModuleSpec, IModuleSpec } from './moduleSpecs.model'

export interface IOscillatorSpec extends IModuleSpec {
  waveform: EWaveForms
  frequency: number
  detune: number
}

export const defaultOscillator: IOscillatorSpec = {
  ...defaultModuleSpec,
  waveform: EWaveForms.SINE,
  frequency: 440,
  detune: 0,
}

const basicOscillator = (pos: [number, number] = [0, 0]): IOscillatorSpec => ({
  ...defaultOscillator,
  name: ['Groove', 'Molecule Oscillator'],
  pos,
  namePos: [3, 0],
  width: 6,
  dials: {
    [ENodeControls.FREQUENCY]: createDialSpec({
      pos: [1.5, 3],
      range: frequencyRanges,
      width: 3,
    }),
    [ENodeControls.DETUNE]: createDialSpec({
      text: 'detune',
      pos: [0.7, 6],
      range: detuneRanges,
    }),
    [ENodeControls.FM_AMOUNT]: createDialSpec({
      text: 'fm amt',
      pos: [4.3, 6],
      range: fmGainRanges,
    }),
  },
  buttons: {
    [EWaveForms.SINE]: createButtonSpec({
      pos: [0.7, 1.75],
      width: 1,
      height: 0.5,
      symbolName: EWaveForms.SINE,
    }),
    [EWaveForms.SQUARE]: createButtonSpec({
      pos: [1.9, 1.75],
      width: 1,
      height: 0.5,
      symbolName: EWaveForms.SQUARE,
    }),
    [EWaveForms.SAWTOOTH]: createButtonSpec({
      pos: [3.1, 1.75],
      width: 1,
      height: 0.5,
      symbolName: EWaveForms.SAWTOOTH,
    }),
    [EWaveForms.TRIANGLE]: createButtonSpec({
      pos: [4.3, 1.75],
      width: 1,
      height: 0.5,
      symbolName: EWaveForms.TRIANGLE,
    }),
    [ENodeControls.LFO_TOGGLE]: createButtonSpec({
      text: 'LFO mode',
      fontSize: 0.8,
      pos: [2.5, 7.75],
      width: 2,
      height: 0.75,
    }),
  },
  inputs: [
    {
      fontSize: 0.8,
      name: 'freq',
      namePos: EPosLocations.BOTTOM,
      pos: [1, 9],
      width: 1,
      target: ENodeTargets.OSC_FREQUENCY,
    },
    {
      fontSize: 0.8,
      name: 'fm amt',
      namePos: EPosLocations.BOTTOM,
      pos: [2.5, 9],
      width: 1,
      target: ENodeTargets.FM_AMOUNT,
    },
  ],
  outputs: [
    {
      fontSize: 0.8,
      name: EOutputs.OUT,
      namePos: EPosLocations.BOTTOM,
      pos: [4, 9],
      width: 1,
      target: ENodeTargets.OSC,
    },
  ],
})

export default basicOscillator
