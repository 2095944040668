import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { ISoundcloudTrack } from '../../../soundcloud/soundcloud.model'
import TrackListItem from '../trackListItem/TrackListItem.container'
import { ITrackPlayerComponent } from '../trackPlayer/store/trackPlayer.models'
import './track-list.component.scss'

export interface ITrackListStateProps {
  tracks: ISoundcloudTrack[]
  currentTrack: ISoundcloudTrack['id'] | null
  tpc: ITrackPlayerComponent
}

export interface ITrackListProps
  extends ITrackListStateProps,
    RouteComponentProps {}

export const TrackList = (props: ITrackListProps) => (
  <section className="track-list">
    {props.tracks.map(t => (
      <TrackListItem key={t.id} trackId={t.id} />
    ))}
  </section>
)
