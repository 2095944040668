const red = '#f00'
const orange = '#f90'
const colors = {
  active: red,
  inactive: orange,
  orange,
  red,
}

export default colors
