import classNames from 'classnames'
import React, { Component } from 'react'
import {
  getTrackDate,
  ISoundcloudTrack,
} from '../../../soundcloud/soundcloud.model'
import './track-list-item.scss'

import { ActionCreator } from 'redux'
import jrCircle from '../../../assets/images/site/jr-circle.svg'
import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { ASetCurrentScTrack } from '../../../soundcloud/soundcloud.actions'
import {
  isPlaying,
  ITrackPlayerComponent,
} from '../trackPlayer/store/trackPlayer.models'

export interface ITrackListItemOwnProps {
  trackId: ISoundcloudTrack['id']
}

export interface ITrackListItemStateProps {
  isCurrentTrack: boolean
  track: ISoundcloudTrack
  tpc: ITrackPlayerComponent
}

export interface ITrackListItemDispatchProps {
  setCurrentTrack: ActionCreator<ASetCurrentScTrack>
}

export interface ITrackListItemProps
  extends ITrackListItemStateProps,
    ITrackListItemDispatchProps {}

interface ITrackListItemState {
  showTooltip: boolean
}

export class TrackListItem extends Component<ITrackListItemProps> {
  public state: ITrackListItemState

  constructor(props: ITrackListItemProps) {
    super(props)
    this.state = { showTooltip: false }
    this.showTooltip = this.showTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)
  }

  public showTooltip(): void {
    this.setState({ showTooltip: true })
  }

  public hideTooltip(): void {
    this.setState({ showTooltip: false })
  }

  public render() {
    return (
      <div className="track-list-item" onClick={this.props.setCurrentTrack}>
        <img
          src={this.props.track.artwork_url || jrCircle}
          alt=" "
          onMouseOver={this.showTooltip}
          onMouseOut={this.hideTooltip}
          className={classNames({
            paused: !isPlaying(this.props.track, this.props.tpc),
            'track-list-item__img': true,
          })}
        />
        <Tooltip show={this.state.showTooltip}>
          {this.props.track.title}
        </Tooltip>
      </div>
    )
  }
}
