import { IHSBColor, Layer, MouseEvent, PlacedSymbol, Point } from 'paper'
import { ENodeTargets } from '../rackModule/nodes.model'
import { IConfig } from '../setup/config'
import { ELayerNames, getLayerByName } from '../setup/layers'
import { EColours, getHSBColor } from '../utils/colors'
import Cable from './Cable'
import Input from './Input'
import Output from './Output'

export enum EPatchTypes {
  INPUT = 'patch_input',
  OUTPUT = 'patch_output',
}

export enum EPosLocations {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export interface IoSpec {
  name?: string
  namePos?: EPosLocations
  pos: [number, number]
  width: number
  fontSize: number
  target: ENodeTargets
}

export interface IIoOpts {
  name: string
  applyMatrix: boolean
  position: Point
  fillColor?: EColours
  strokeColor?: EColours
  strokeWidth?: number
}

export interface IOConnectionCallbacks {
  beforeConnect?: (output: AudioNode, input: AudioParam) => void
  afterConnect?: (output: AudioNode, input: AudioParam) => void
  beforeDisconnect?: (output: AudioNode, input: AudioParam) => void
  afterDisconnect?: (output: AudioNode, input: AudioParam) => void
}

export const defaultIoOpts: IIoOpts = {
  name: '',
  applyMatrix: false,
  position: new Point([0, 0]),
  fillColor: EColours.BLUE,
  strokeColor: EColours.WHITE,
  strokeWidth: 0,
}

export interface IPatchCableProps {
  color: IHSBColor
  origin: Point
  inputAnchor: Point
  inputAnchorY: number
  outputAnchor: Point
}

export const defaultPatchCableProps: IPatchCableProps = {
  color: getHSBColor(),
  origin: new Point(0, 0),
  inputAnchor: new Point(0, 0),
  outputAnchor: new Point(0, 0),
  inputAnchorY: 350,
}

export interface IIOBase {
  spec: IoSpec
  config: IConfig
  layer: Layer
  symbol: PlacedSymbol
  build: () => void
}

export interface IOutput extends IIOBase {
  node: AudioNode
}

export interface IInput extends IIOBase {
  node: AudioParam
}

export interface IConnection {
  id: number
  cable: Cable
  output: Output
  input?: Input
}

export const inputAtPosition = (position: Point): Input | undefined => {
  const layer = getLayerByName(ELayerNames.CONTROLS)
  layer.activate()
  const candidates = layer.getItems({
    overlapping: position,
  })
  const input = candidates.find(
    x => x.data && x.data.type === EPatchTypes.INPUT,
  )
  return input ? input.data.parent : undefined
}

export const getItemAtPosition = (position: Point) => {
  const layer = getLayerByName(ELayerNames.CONTROLS)
  layer.activate()
  const hits = layer
    .getItems({
      overlapping: position,
    })
    .filter(item => item.data.type)
  return hits.length ? hits[0] : undefined
}
