import {
  Group,
  MouseEvent,
  Path,
  PlacedSymbol,
  Point,
  PointText,
  Rectangle,
  Size,
} from 'paper'
import { EPosLocations } from '../patching/patching.model'
import { IConfig } from '../setup/config'
import { ELayerNames, getLayerByName } from '../setup/layers'
import { EColours } from '../utils/colors'
import { IModuleButtonSpec } from './controls.model'

class Button {
  public grp!: Group
  private btn!: Path.Rectangle
  private symbol!: PlacedSymbol
  private text!: PointText

  public constructor(
    public spec: IModuleButtonSpec,
    public position: Point,
    public config: IConfig,
  ) {
    this.build()
  }

  get name() {
    return this.grp.name
  }

  get data() {
    return this.grp.data
  }

  get selected() {
    return this.data.selected
  }

  set selected(selected: boolean) {
    this.data.selected = selected
  }

  set fillColor(color: string) {
    this.btn.fillColor = color
  }

  set color(color: string) {
    if (this.text) {
      this.text.fillColor = color
    }
  }

  set strokeColor(color: string) {
    if (!this.symbol) {
      return
    }
    this.symbol.symbol.definition.strokeColor = color
  }

  public set(props: any) {
    this.grp.set(props)
  }

  public setSelected() {
    this.grp.data.updateStyle()
  }

  public setData(props: any) {
    this.grp.data = {
      ...this.grp.data,
      ...props,
    }
  }

  public setText(text: string) {
    this.text.content = text
  }

  private build() {
    getLayerByName(ELayerNames.CONTROLS).activate()
    this.createGrp()
    this.createBtn()
    this.createSymbol()
    this.createText()
  }

  private createGrp = () => {
    const { symbolName, text } = this.spec
    this.grp = new Group({
      applyMatrix: false,
      data: {
        selected: false,
        updateStyle: () => {
          this.selected = !this.selected
          this.fillColor = this.selected ? EColours.PINK : EColours.ORANGE
          this.color = this.selected ? EColours.WHITE : EColours.BLACK
          this.strokeColor = this.selected ? EColours.WHITE : EColours.BLACK
        },
      },
      name: text || symbolName,
      onMouseDown: () => {
        this.data.updateStyle()
        if (this.data.onMouseDown) {
          this.data.onMouseDown()
        }
      },
    })
  }

  private createBtn() {
    const { height, width } = this.spec
    const {
      dimensions: { unit },
    } = this.config
    this.btn = new Path.Rectangle({
      applyMatrix: false,
      height: (height || 1) * unit,
      position: this.position.add(new Point(unit / 2, unit / 2)),
      text: this.spec.text,
      width: (width || 1) * unit,
      ...this.spec.props,
    })
    this.grp.addChild(this.btn)
  }

  private createSymbol() {
    const { symbolName, width, height } = this.spec
    const {
      symbols,
      dimensions: { unit },
    } = this.config
    if (symbolName && symbols[symbolName]) {
      this.symbol = symbols[symbolName].clone().place()
      this.symbol.fitBounds(
        new Rectangle(
          new Point(0, 0),
          new Size((width || 1) * unit, (height || 1) * unit).multiply(0.8),
        ),
      )
      this.strokeColor = this.selected ? EColours.WHITE : EColours.BLACK
      this.symbol.position = this.btn.bounds.center
      this.grp.addChild(this.symbol)
    }
  }

  private createText() {
    if (!this.spec.text || !this.spec.textPos) {
      return
    }
    const { text } = this.spec
    const { point, fontSize, justification } = this.getTextPoint()
    this.text = new PointText({
      content: text || '0',
      fillColor: EColours.BLACK,
      fontSize,
      fontWeight: 'bold',
      justification,
      point,
    })
    this.text.point = point.add(new Point(0, 4))
    this.grp.addChild(this.text)
  }

  private getTextPoint() {
    if (!this.btn) {
      return { fontSize: 1, point: new Point(0, 0), justification: 'center' }
    }
    const { textPos, fontSize } = this.spec
    let point = this.btn.bounds.center
    let justification = 'center'
    const newFontSize = (this.config.dimensions.unit / 2.2) * (fontSize || 1)
    switch (textPos) {
      case EPosLocations.TOP:
        point = point.subtract(new Point(0, this.btn.bounds.height))
        break
      case EPosLocations.LEFT:
        point = point.subtract(
          new Point(this.btn.bounds.width / 1.5, -newFontSize / 3),
        )
        justification = 'right'
        break
      case EPosLocations.RIGHT:
        point = point.add(
          new Point(this.btn.bounds.width / 1.5, newFontSize / 3),
        )
        justification = 'left'
        break
      case EPosLocations.BOTTOM:
        point = point.add(new Point(0, this.btn.bounds.height + 10))
        break
      case EPosLocations.CENTER:
      default:
        break
    }
    return {
      fontSize: newFontSize,
      justification,
      point,
    }
  }
}

export default Button
