import ParamController from '../../controlsComponents/ParamController'
import { IModuleSpec } from '../../moduleSpecs/moduleSpecs.model'
import Input from '../../patching/Input'
import Output from '../../patching/Output'
import { IConfig } from '../../setup/config'
import { ENodeControls } from '../nodes.model'
import RackModule from '../RackModule'
import { IRackModule } from '../rackModule.model'

class Mixer extends RackModule implements IRackModule {
  public static create(spec: IModuleSpec, config: IConfig): Mixer {
    return new Mixer(spec, config)
  }
  private output!: GainNode

  private gain1!: GainNode
  private gain2!: GainNode
  private gain3!: GainNode

  private pan1!: StereoPannerNode
  private pan2!: StereoPannerNode
  private pan3!: StereoPannerNode

  private outputCtrl!: ParamController

  private gain1Ctrl!: ParamController
  private gain2Ctrl!: ParamController
  private gain3Ctrl!: ParamController

  private pan1Ctrl!: ParamController
  private pan2Ctrl!: ParamController
  private pan3Ctrl!: ParamController

  public constructor(public spec: IModuleSpec, public config: IConfig) {
    super(spec, config)
    this.buildNodes()
    this.drawCtrls()
    this.createInputs()
    this.createOutputs()
    this.applyNodeDefaults()
  }

  public buildNodes(): void {
    this.output = this.ctx.createGain()
    this.gain1 = this.ctx.createGain()
    this.gain2 = this.ctx.createGain()
    this.gain3 = this.ctx.createGain()
    this.pan1 = this.ctx.createStereoPanner()
    this.pan2 = this.ctx.createStereoPanner()
    this.pan3 = this.ctx.createStereoPanner()

    this.output.gain.value = 1
    this.gain1.gain.value = 1
    this.gain2.gain.value = 1
    this.gain3.gain.value = 1

    this.gain1.connect(this.pan1)
    this.gain2.connect(this.pan2)
    this.gain3.connect(this.pan3)

    this.pan1.connect(this.output)
    this.pan2.connect(this.output)
    this.pan3.connect(this.output)
  }

  public drawCtrls(): void {
    this.createGain1Dial()
    this.createGain2Dial()
    this.createGain3Dial()
    this.createPan1Dial()
    this.createPan2Dial()
    this.createPan3Dial()
    this.createMixDial()
  }

  public applyNodeDefaults(): void {
    this.resetGain1()
    this.resetGain2()
    this.resetGain3()
    this.resetPan1()
    this.resetPan2()
    this.resetPan3()
    this.resetMix()
  }

  /**
   * Gain 1
   */
  public createGain1Dial() {
    this.gain1Ctrl = ParamController.create(
      this.gain1.gain,
      this.spec.dials.gain1,
      this.setGain1,
      this.resetGain1,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Pan 1
   */
  public createPan1Dial() {
    this.pan1Ctrl = ParamController.create(
      this.pan1.pan,
      this.spec.dials.pan1,
      this.setPan1,
      this.resetPan1,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Gain 2
   */
  public createGain2Dial() {
    this.gain2Ctrl = ParamController.create(
      this.gain2.gain,
      this.spec.dials.gain2,
      this.setGain2,
      this.resetGain2,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Pan 2
   */
  public createPan2Dial() {
    this.pan2Ctrl = ParamController.create(
      this.pan2.pan,
      this.spec.dials.pan2,
      this.setPan2,
      this.resetPan2,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Gain 3
   */
  public createGain3Dial() {
    this.gain3Ctrl = ParamController.create(
      this.gain3.gain,
      this.spec.dials.gain3,
      this.setGain3,
      this.resetGain3,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Pan 3
   */
  public createPan3Dial() {
    this.pan3Ctrl = ParamController.create(
      this.pan3.pan,
      this.spec.dials.pan3,
      this.setPan3,
      this.resetPan3,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Mix
   */
  public createMixDial() {
    this.outputCtrl = ParamController.create(
      this.output.gain,
      this.spec.dials[ENodeControls.GAIN],
      this.setMix,
      this.resetMix,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Inputs
   */

  public createInputs() {
    this.spec.inputs.forEach((spec, i) =>
      Input.create(
        [this.gain1, this.gain2, this.gain3][i],
        spec,
        this.spec.bounds.topLeft,
        this.config,
        {},
      ),
    )
  }

  /**
   * Outputs
   */
  public createOutputs(): void {
    Output.create(
      this.output,
      this.spec.outputs[0],
      this.spec.bounds.topLeft,
      this.config,
      {},
    )
  }

  private setGain1 = (value?: number) => {
    if (!this.gain1Ctrl) {
      return
    }
    this.gain1Ctrl.value =
      typeof value === 'number'
        ? value
        : this.gain1Ctrl.currentRotation('linear')
  }

  private resetGain1 = () => {
    this.setGain1(1)
    this.gain1Ctrl.rotate(1, 'linear')
  }

  private setPan1 = (value?: number) => {
    if (!this.pan1Ctrl) {
      return
    }
    this.pan1Ctrl.value =
      typeof value === 'number'
        ? value
        : this.pan1Ctrl.currentRotation('linear')
  }

  private resetPan1 = () => {
    this.setPan1(0)
    this.pan1Ctrl.rotate(0, 'linear')
  }

  private setGain2 = (value?: number) => {
    if (!this.gain2Ctrl) {
      return
    }
    this.gain2Ctrl.value =
      typeof value === 'number'
        ? value
        : this.gain2Ctrl.currentRotation('linear')
  }

  private resetGain2 = () => {
    this.setGain2(1)
    this.gain2Ctrl.rotate(1, 'linear')
  }

  private setPan2 = (value?: number) => {
    if (!this.pan2Ctrl) {
      return
    }
    this.pan2Ctrl.value =
      typeof value === 'number'
        ? value
        : this.pan2Ctrl.currentRotation('linear')
  }

  private resetPan2 = () => {
    this.setPan2(0)
    this.pan2Ctrl.rotate(0, 'linear')
  }

  private setGain3 = (value?: number) => {
    if (!this.gain3Ctrl) {
      return
    }
    this.gain3Ctrl.value =
      typeof value === 'number'
        ? value
        : this.gain3Ctrl.currentRotation('linear')
  }

  private resetGain3 = () => {
    this.setGain3(1)
    this.gain3Ctrl.rotate(1, 'linear')
  }

  private setPan3 = (value?: number) => {
    if (!this.pan3Ctrl) {
      return
    }
    this.pan3Ctrl.value =
      typeof value === 'number'
        ? value
        : this.pan3Ctrl.currentRotation('linear')
  }

  private resetPan3 = () => {
    this.setPan3(0)
    this.pan3Ctrl.rotate(0, 'linear')
  }

  private setMix = (value?: number) => {
    if (!this.outputCtrl) {
      return
    }
    this.outputCtrl.value =
      typeof value === 'number'
        ? value
        : this.outputCtrl.currentRotation('linear')
  }

  private resetMix = () => {
    this.setMix(1)
    this.outputCtrl.rotate(1, 'linear')
  }
}

export default Mixer
