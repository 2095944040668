import * as _ from 'lodash'
import {
  defaultSouncloudTrack,
  ISoundcloudTrack,
} from '../../../../soundcloud/soundcloud.model'

export interface ITrackPlayerComponent {
  paused: boolean
  player: HTMLAudioElement | null
  track: ISoundcloudTrack
  volume: number
}

export const defaultTpc: ITrackPlayerComponent = {
  paused: false,
  player: null,
  track: defaultSouncloudTrack,
  volume: 10,
}

export const isPlaying = (
  track: ISoundcloudTrack,
  tpc: ITrackPlayerComponent,
): boolean =>
  tpc.player ? !tpc.paused && _.isEqual(tpc.track.uri, track.uri) : false
