import { Redirect, Router } from '@reach/router'
import React from 'react'
import { Header } from '../components/header/Header'
import AudioHome from '../features/audio/audioHome/AudioHome.container'
import { HomePage } from '../features/home/Home'
import Music from '../features/music/Music.container'
import { Acceleration } from '../features/visual/Acceleration'
import { Friction } from '../features/visual/Friction'
import { GravityWind } from '../features/visual/GravityWind'
import { LissajousTable } from '../features/visual/LissajousTable'
import Terrain from '../features/visual/terrainFlyover/TerrainFlyover.container'
import VisualsHome from '../features/visual/visualsHome/VisualsHome.container'
import { IAppStateProps } from './App.model'

import './App.scss'
import Modular from '../features/Modular/modular'

export const App = (props: IAppStateProps) => (
  <div className="App">
    <Header />
    <Router className="App__router">
      <HomePage path="/" />
      <Music path="music" />
      <Modular path="/jrbmod" />
      <Redirect from="audio" to="audio/jr101" />
      <AudioHome path="audio" />
      <Redirect from="visuals" to="visuals/lissajous-table" />
      <VisualsHome path="visuals">
        <LissajousTable path="lissajous-table" />
        <Terrain path="terrain" />
        <Acceleration path="acceleration" />
        <GravityWind path="gravity-and-wind" />
        <Friction path="friction" />
      </VisualsHome>
    </Router>
    <div className="visualiser">
      <canvas id="visualiser-canvas" />
    </div>
  </div>
)
