import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import { NavLink } from '../../../components/navLink/NavLink.component'
import { SideMenuAndContent } from '../../../layouts/sideMenuAndContent/sideMenuAndContent.component'
import { ISoundcloudEmbed } from '../../../soundcloud/soundcloud.model'
import './audio-home.scss'

export interface IAudioHomeStateProps {
  embed: ISoundcloudEmbed
}

export interface IAudioHomeProps
  extends IAudioHomeStateProps,
    RouteComponentProps {}

export class AudioHome extends Component<IAudioHomeProps> {
  public state = {
    height: 0,
  }
  private ref: HTMLElement | null

  public constructor(props: IAudioHomeProps) {
    super(props)
    this.ref = null
  }

  public componentDidMount(): void {
    if (this.ref) {
      this.setState({ height: window.innerHeight - this.ref.offsetTop })
    }
  }

  public render() {
    const links = [
      <NavLink key={0} to="jr101" text="JR-101 synth" />,
      <NavLink key={1} to="jrbmod" text="JR B-Mod Modular synth" />,
    ]
    return (
      <section className="audio-home">
        <SideMenuAndContent navLinks={links}>
          {this.props.children}
        </SideMenuAndContent>
      </section>
    )
  }
}
