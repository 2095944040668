import { Reducer } from 'redux'
import { AUpdateP5Canvas } from './visuals.actions'
import { defaultVisuals, IVisuals } from './visuals.model'

export const visuals: Reducer<IVisuals> = (state = defaultVisuals, action) => {
  switch (action.type) {
    case AUpdateP5Canvas.type: {
      const n = action.name.toLowerCase()
      return {
        ...state,
        [n]: {
          ...state[n],
          opts: {
            ...state[n].opts,
            ...action.opts,
          },
        },
      }
    }
    default:
      return state
  }
}
