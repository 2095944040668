import { IHSBColor } from 'paper'
import { clamp } from './conversions'

export enum EColours {
  ORANGE = '#f90',
  PINK = '#ff197a',
  GREEN = '#32cd32',
  BLUE = '#47a2ff',
  RED = '#f00',
  WHITE = '#fff',
  BLACK = '#000',
  GREY = '#666',
  GREY_LIGHTER = '#999',
  BACKGROUND = '#333',
  BACKGROUND_DARKER = '#161616',
}

/**
 * getHSBColor
 * @Description returns a color object described by paper.IHSBColor. By default, a random
 * hue is generated with a saturation of 0.6
 * @param {number} hue 0 - 360
 * @param {number} saturation 0 - 1
 * @param {number} brightness 0 - 1
 * @param {number} alpha 0 - 1
 * @param {boolean} randomizeHue
 * @param {boolean} randomizeSaturation
 * @param {boolean} randomizeBrightness
 * @param {boolean} randomizeAlpha
 * @returns {paper.IHSBColor}
 */
export const getHSBColor = (
  hue: number = 360,
  saturation: number = 0.6,
  brightness: number = 1,
  alpha: number = 1,
  randomizeHue: boolean = true,
  randomizeSaturation: boolean = false,
  randomizeBrightness: boolean = false,
  randomizeAlpha: boolean = false,
): IHSBColor => {
  const clampedHue = clamp(hue, 0, 360)
  const [h, s, b, a] = [
    randomizeHue ? clampedHue * Math.random() : clampedHue,
    randomizeSaturation ? saturation * Math.random() : saturation,
    randomizeBrightness ? brightness * Math.random() : brightness,
    randomizeAlpha ? alpha * Math.random() : alpha,
  ]
  return { hue: h, saturation: s, brightness: b, alpha: a }
}
