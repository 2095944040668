import { combineReducers, Reducer } from 'redux'
import { trackPlayerComponent } from '../features/music/trackPlayer/store/trackPlayer.reducers'
import { visuals } from '../features/visual/store/visuals.reducer'
import {
  currentScTrack,
  currentScTrackEmbed,
  soundcloudPlaylists,
  soundcloudTracks,
} from '../soundcloud/soundcloud.reducers'
import { IAppState } from './store.model'

const rootReducer: Reducer<IAppState> = combineReducers({
  currentScTrack,
  currentScTrackEmbed,
  soundcloudPlaylists,
  soundcloudTracks,
  trackPlayerComponent,
  visuals,
})

export default rootReducer
