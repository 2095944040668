import ParamController from '../../controlsComponents/ParamController'
import { IModuleSpec } from '../../moduleSpecs/moduleSpecs.model'
import Input from '../../patching/Input'
import Output from '../../patching/Output'
import { IConfig } from '../../setup/config'
import { ENodeControls } from '../nodes.model'
import RackModule from '../RackModule'
import { IRackModule } from '../rackModule.model'

class Delay extends RackModule implements IRackModule {
  public static create(spec: IModuleSpec, config: IConfig): Delay {
    return new Delay(spec, config)
  }
  private delay!: DelayNode
  private feedback!: GainNode
  private wetGain!: GainNode
  private dryGain!: GainNode
  private output!: GainNode
  private timeCtrl!: ParamController
  private feedbackCtrl!: ParamController
  private mixCtrl!: ParamController
  private defaultTime = 5.0
  private defaultFeedback = 0.6
  private defaultMix = 0.5
  private defaultMixGain = 0.5

  public constructor(public spec: IModuleSpec, public config: IConfig) {
    super(spec, config)
    this.buildNodes()
    this.drawCtrls()
    this.createInputs()
    this.createOutputs()
    this.applyNodeDefaults()
  }

  public buildNodes(): void {
    this.delay = this.ctx.createDelay(10.0)
    this.feedback = this.ctx.createGain()
    this.dryGain = this.ctx.createGain()
    this.wetGain = this.ctx.createGain()
    this.output = this.ctx.createGain()
    this.delay.delayTime.value = this.defaultTime
    this.feedback.gain.value = this.defaultFeedback
    this.dryGain.gain.value = this.defaultMixGain
    this.wetGain.gain.value = this.defaultMixGain

    this.delay.connect(this.feedback)
    this.feedback.connect(this.delay)
    this.delay.connect(this.wetGain)
    this.dryGain.connect(this.output)
    this.wetGain.connect(this.output)
  }

  public drawCtrls(): void {
    this.createTimeDial()
    this.createFeedbackDial()
    this.createMixDial()
  }

  /**
   * Time
   */
  public createTimeDial() {
    this.timeCtrl = ParamController.create(
      this.delay.delayTime,
      this.spec.dials[ENodeControls.DELAY_TIME],
      this.setTime,
      this.resetTime,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Feedback
   */
  public createFeedbackDial() {
    this.feedbackCtrl = ParamController.create(
      this.feedback.gain,
      this.spec.dials[ENodeControls.FEEDBACK],
      this.setFeedback,
      this.resetFeedback,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Mix
   */
  public createMixDial() {
    this.mixCtrl = ParamController.create(
      this.output.gain,
      this.spec.dials[ENodeControls.DRY_WET],
      this.setMix,
      this.resetMix,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Inputs
   */

  public createInputs() {
    Input.create(
      this.delay,
      this.spec.inputs[0],
      this.spec.bounds.topLeft,
      this.config,
      {
        afterConnect: output => output.connect(this.dryGain),
        beforeDisconnect: output => output.disconnect(this.dryGain),
      },
    )
  }

  /**
   * Outputs
   */
  public createOutputs(): void {
    Output.create(
      this.output,
      this.spec.outputs[0],
      this.spec.bounds.topLeft,
      this.config,
      {},
    )
  }
  public applyNodeDefaults(): void {
    this.resetTime()
    this.resetFeedback()
    this.resetMix()
  }

  private setTime = (value?: number) => {
    if (!this.timeCtrl) {
      return
    }
    this.timeCtrl.value =
      typeof value === 'number'
        ? value
        : this.timeCtrl.currentRotation('linear')
    this.timeCtrl.setText((this.timeCtrl.value * 1000).toFixed(0) + ' ms')
  }

  private resetTime = () => {
    this.setTime(this.defaultTime)
    this.timeCtrl.rotate(this.defaultTime, 'linear')
  }

  private setFeedback = (value?: number) => {
    if (!this.feedbackCtrl) {
      return
    }
    this.feedbackCtrl.value =
      typeof value === 'number'
        ? value
        : this.feedbackCtrl.currentRotation('linear')
  }

  private resetFeedback = () => {
    this.setFeedback(this.defaultFeedback)
    this.feedbackCtrl.rotate(this.defaultFeedback, 'linear')
  }

  private setMix = (value?: number) => {
    if (!this.mixCtrl) {
      return
    }
    const newValue =
      typeof value === 'number' ? value : this.mixCtrl.currentRotation('linear')

    this.dryGain.gain.value = -newValue + 1
    this.wetGain.gain.value = newValue
  }

  private resetMix = () => {
    this.setMix(this.defaultMix)
    this.mixCtrl.rotate(this.defaultMix, 'linear')
  }
}

export default Delay
