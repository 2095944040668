import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { ASetCurrentScTrack } from '../../../soundcloud/soundcloud.actions'
import { IAppState } from '../../../store/store.model'
import {
  ITrackListItemDispatchProps,
  ITrackListItemOwnProps,
  ITrackListItemStateProps,
  TrackListItem,
} from './TrackListItem.component'

const mapStateToProps = (
  state: IAppState,
  ownProps: ITrackListItemOwnProps,
): ITrackListItemStateProps => ({
  isCurrentTrack: state.currentScTrack === ownProps.trackId,
  track: state.soundcloudTracks[ownProps.trackId],
  tpc: state.trackPlayerComponent,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: ITrackListItemOwnProps,
): ITrackListItemDispatchProps => ({
  setCurrentTrack: () => dispatch(ASetCurrentScTrack.create(ownProps.trackId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrackListItem)
