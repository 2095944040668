import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { ActionCreator } from 'redux'
import { ALoadSoundcloudTracks } from '../../soundcloud/soundcloud.actions'
import {
  ISoundcloudEmbed,
  ISoundcloudPlaylist,
  ISoundcloudTrack,
} from '../../soundcloud/soundcloud.model'

export interface IMusicStateProps {
  tracks: ISoundcloudTrack[]
  playlists: ISoundcloudPlaylist[]
  currentTrack: ISoundcloudTrack['id'] | null
  currentTrackEmbed: ISoundcloudEmbed | null
}

export interface IMusicDispatchProps {
  loadTracks: ActionCreator<ALoadSoundcloudTracks>
}

export interface IMusicProps
  extends IMusicStateProps,
    IMusicDispatchProps,
    RouteComponentProps {}

export const shouldLoadTracks = (tracks: ISoundcloudTrack[] = []): boolean =>
  tracks.length
    ? moment(tracks[0].retrieved).isBefore(
        moment().subtract(moment.duration(15, 'minutes')),
      )
    : true
