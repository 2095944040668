import p5 from 'p5'

export class Curve {
  public state: any

  constructor(s: any) {
    this.state = {
      color: Array(3)
        .fill(0)
        .map(() => Math.floor(s.random(50, 255))),
      current: new p5.Vector(0, 0),
      path: [],
      s,
    }

    this.setState = this.setState.bind(this)
    this.setX = this.setX.bind(this)
    this.setY = this.setY.bind(this)
    this.addPoint = this.addPoint.bind(this)
    this.reset = this.reset.bind(this)
    this.show = this.show.bind(this)
  }

  public setState(newState: any) {
    this.state = { ...this.state, ...newState }
    return this
  }

  public setX(x: number) {
    this.setState({ current: new p5.Vector(x, this.state.current.y) })
    return this
  }

  public setY(y: number) {
    this.setState({ current: new p5.Vector(this.state.current.x, y) })
    return this
  }

  public addPoint() {
    this.state.path.push(this.state.current)
    return this
  }

  public reset() {
    this.setState({ path: [] })
  }

  public show() {
    const { color, current, path, s } = this.state
    s.stroke(color)
    s.strokeWeight(1)
    s.noFill()
    s.beginShape()
    path.map((v: any) => s.vertex(v.x, v.y))
    s.endShape()
    s.strokeWeight(5)
    s.point(current.x, current.y)
    this.setState({ current: new p5.Vector(0, 0) })
  }
}
