import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import moduleSpecs from './moduleSpecs/moduleSpecs'
import { IRackModule } from './rackModule/rackModule.model'
import { IConfig, initConfig } from './setup/config'
import drawRack from './setup/rack'

class Modular extends Component<RouteComponentProps> {
  get ctx(): AudioContext {
    return this.config.ctx
  }
  public canvas!: HTMLCanvasElement
  public config!: IConfig

  public render() {
    return <canvas id="modular" ref={this.init} />
  }

  public setCtx = async () => {
    await this.ctx.resume()
  }

  public audioAllowed = () => {
    this.setCtx()
    this.canvas.removeEventListener('click', this.audioAllowed)
  }

  private init = async (canvas: HTMLCanvasElement) => {
    if (!canvas) {
      return
    }
    this.canvas = canvas
    this.config = await initConfig(canvas, moduleSpecs)
    drawRack(this.config)
    this.config.modules.forEach((mod: IRackModule) => mod.draw())
    this.canvas.addEventListener('click', this.audioAllowed)
  }
}

export default Modular
