import {
  createButtonSpec,
  createDialSpec,
} from '../controlsComponents/controls.model'
import { EPosLocations } from '../patching/patching.model'
import {
  EBiquadFilterTypes,
  ENodeControls,
  ENodeTargets,
} from '../rackModule/nodes.model'
import {
  detuneRanges,
  filterGainRanges,
  fmGainRanges,
  frequencyRanges,
  gainRanges,
  qAmtRanges,
  qRanges,
} from '../utils/dimensions-and-ranges'
import {
  defaultModuleSpec,
  EModuleTypes,
  IModuleSpec,
} from './moduleSpecs.model'

export interface IBiquadFilterSpec extends IModuleSpec {
  filterType: EBiquadFilterTypes
  frequency: number
  Q: number
  gain: number
  detune: number
}

export const defaultBiquadFilter: IBiquadFilterSpec = {
  ...defaultModuleSpec,
  type: EModuleTypes.BIQUADFILTER,
  filterType: EBiquadFilterTypes.LOW_PASS,
  frequency: 1000,
  Q: 0,
  gain: 0,
  detune: 0,
  width: 9,
  namePos: [4.5, 0],
}

const filterTypeBtnRow1y = 1.5
const filterTypeBtnRow2y = 2.5

const basicBiquadFilter = (
  pos: [number, number] = [0, 0],
): IBiquadFilterSpec => ({
  ...defaultBiquadFilter,
  name: ['Filter Me Softly'],
  pos,
  dials: {
    [ENodeControls.FREQUENCY]: createDialSpec({
      pos: [0.5, 4],
      range: frequencyRanges,
      width: 1.5,
    }),
    [ENodeControls.FM_AMOUNT]: createDialSpec({
      showValue: false,
      pos: [0.75, 6.5],
      range: fmGainRanges,
    }),
    [ENodeControls.Q]: createDialSpec({
      text: 'Q',
      pos: [2.66, 4],
      range: qRanges,
      width: 1.5,
    }),
    [ENodeControls.Q_AMOUNT]: createDialSpec({
      showValue: false,
      pos: [2.92, 6.5],
      range: qAmtRanges,
    }),
    [ENodeControls.GAIN]: createDialSpec({
      text: 'gain',
      pos: [4.82, 4],
      range: filterGainRanges,
      width: 1.5,
    }),
    [ENodeControls.GAIN_AMOUNT]: createDialSpec({
      showValue: false,
      pos: [5.08, 6.5],
      range: gainRanges,
    }),
    [ENodeControls.DETUNE]: createDialSpec({
      text: 'detune',
      pos: [7, 4],
      range: detuneRanges,
      width: 1.5,
    }),
    [ENodeControls.DETUNE_AMOUNT]: createDialSpec({
      showValue: false,
      pos: [7.25, 6.5],
      range: detuneRanges,
    }),
  },
  buttons: {
    [EBiquadFilterTypes.LOW_PASS]: createButtonSpec({
      pos: [1, filterTypeBtnRow1y],
      width: 2,
      fontSize: 0.7,
      text: EBiquadFilterTypes.LOW_PASS,
    }),
    [EBiquadFilterTypes.HIGH_PASS]: createButtonSpec({
      pos: [3, filterTypeBtnRow1y],
      width: 2,
      fontSize: 0.7,
      text: EBiquadFilterTypes.HIGH_PASS,
    }),
    [EBiquadFilterTypes.BAND_PASS]: createButtonSpec({
      text: EBiquadFilterTypes.BAND_PASS,
      pos: [5, filterTypeBtnRow1y],
      width: 2,
      fontSize: 0.7,
    }),
    [EBiquadFilterTypes.LOW_SHELF]: createButtonSpec({
      text: EBiquadFilterTypes.LOW_SHELF,
      pos: [7, filterTypeBtnRow1y],
      width: 2,
      fontSize: 0.7,
    }),
    [EBiquadFilterTypes.HIGH_SHELF]: createButtonSpec({
      text: EBiquadFilterTypes.HIGH_SHELF,
      pos: [1, filterTypeBtnRow2y],
      width: 2,
      fontSize: 0.7,
    }),
    [EBiquadFilterTypes.PEAKING]: createButtonSpec({
      text: EBiquadFilterTypes.PEAKING,
      pos: [3, filterTypeBtnRow2y],
      width: 2,
      fontSize: 0.7,
    }),
    [EBiquadFilterTypes.NOTCH]: createButtonSpec({
      text: EBiquadFilterTypes.NOTCH,
      pos: [5, filterTypeBtnRow2y],
      width: 2,
      fontSize: 0.7,
    }),
    [EBiquadFilterTypes.ALL_PASS]: createButtonSpec({
      text: EBiquadFilterTypes.ALL_PASS,
      pos: [7, filterTypeBtnRow2y],
      width: 2,
      fontSize: 0.7,
    }),
  },
  inputs: [
    {
      fontSize: 0.8,
      name: 'f amt',
      namePos: EPosLocations.TOP,
      pos: [0.75, 8.25],
      width: 1,
      target: ENodeTargets.FILTER_FREQUENCY,
    },
    {
      fontSize: 0.8,
      name: 'Q amt',
      namePos: EPosLocations.TOP,
      pos: [2.92, 8.25],
      width: 1,
      target: ENodeTargets.FILTER_Q,
    },
    {
      fontSize: 0.8,
      name: 'g amt',
      namePos: EPosLocations.TOP,
      pos: [5.1, 8.25],
      width: 1,
      target: ENodeTargets.FILTER_GAIN,
    },
    {
      fontSize: 0.8,
      name: 'd amt',
      namePos: EPosLocations.TOP,
      pos: [7.25, 8.25],
      width: 1,
      target: ENodeTargets.FILTER_DETUNE,
    },
    {
      fontSize: 0.8,
      name: 'in',
      namePos: EPosLocations.TOP,
      pos: [1.75, 9.75],
      width: 1,
      target: ENodeTargets.FILTER,
    },
  ],
  outputs: [
    {
      fontSize: 0.8,
      name: 'out',
      namePos: EPosLocations.TOP,
      pos: [6.25, 9.75],
      width: 1,
      target: ENodeTargets.FILTER,
    },
  ],
})

export default basicBiquadFilter
