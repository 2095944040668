import { connect } from 'react-redux'
import { sortByDate } from '../../../soundcloud/soundcloud.model'
import { IAppState } from '../../../store/store.model'
import { ITrackListStateProps, TrackList } from './TrackList.component'

const mapStateToProps = (state: IAppState): ITrackListStateProps => ({
  tracks: sortByDate(state.soundcloudTracks),
  currentTrack: state.currentScTrack,
  tpc: state.trackPlayerComponent,
})

export default connect(mapStateToProps)(TrackList)
