import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import { NavLink } from '../../../components/navLink/NavLink.component'
import { SideMenuAndContent } from '../../../layouts/sideMenuAndContent/sideMenuAndContent.component'
import './visuals-home.scss'

export class VisualsHome extends Component<RouteComponentProps> {
  public render() {
    const links = [
      <NavLink key={0} to="lissajous-table">
        Lissajous Table
      </NavLink>,
      <NavLink key={1} to="terrain">
        Terrain Flyover
      </NavLink>,
      <NavLink key={2} to="acceleration">
        Acceleration
      </NavLink>,
      <NavLink key={3} to="gravity-and-wind">
        Gravity and Wind
      </NavLink>,
      <NavLink key={4} to="friction">
        Friction
      </NavLink>,
    ]
    return (
      <section className="visuals-home">
        <SideMenuAndContent navLinks={links}>
          {this.props.children}
        </SideMenuAndContent>
      </section>
    )
  }
}
