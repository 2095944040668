import React, { Component } from 'react'
import { ActionCreator } from 'redux'
import {
  APauseTrack,
  APlayTrack,
} from '../trackPlayer/store/trackPlayer.actions'
import { ITrackPlayerComponent } from '../trackPlayer/store/trackPlayer.models'
import './audio-element.scss'

export interface IAudioElementOwnProps {
  controls: boolean
}

export interface IAudioElementStateProps extends IAudioElementOwnProps {
  tpc: ITrackPlayerComponent
}

export interface IAudioElementDispatch {
  play: ActionCreator<APlayTrack>
  pause: ActionCreator<APauseTrack>
}

export interface IAudioElementProps
  extends IAudioElementStateProps,
    IAudioElementDispatch {}

export class AudioElement extends Component<IAudioElementProps> {
  public render() {
    if (this.props.tpc.player) {
      this.props.tpc.player.onplay = this.props.play
      this.props.tpc.player.onpause = this.props.pause
    }
    return <div className="audio-element" id={'tpc__player'} />
  }
}
