import { Layer, Project } from 'paper'

let layers: { [key: string]: Layer } = {}

export enum ELayerNames {
  RACK = 'rack',
  MODULES = 'modules',
  CONTROLS = 'controls',
  CABLES = 'cables',
}

const createLayers = (project: Project) => {
  Object.values(ELayerNames).map((layerName: string) => {
    const newLayer = new Layer({ text: layerName })
    layers = {
      ...layers,
      [layerName]: newLayer,
    }
    project.addLayer(newLayer)
  })
  layers.rack.activate()
  return layers
}

export const getLayerByName = (name: string): Layer => layers[name]

export default createLayers
