import { MouseEvent, PlacedSymbol } from 'paper'
import Dial from '../controlsComponents/Dial'
import { IConfigDimensions } from '../setup/config'
import { IDialRange } from './dimensions-and-ranges'
import { mapFromLog, mapToLog, mapToRange } from './conversions'

class RotationController {
  public dimensions!: IConfigDimensions

  public setDimensions(dimensions: IConfigDimensions) {
    this.dimensions = dimensions
  }

  get unit() {
    return this.dimensions ? this.dimensions.unit : 0
  }

  public canRotate(
    evt: MouseEvent,
    ctrl: PlacedSymbol,
    range: IDialRange,
  ): boolean {
    const targetRotation = ctrl.rotation - evt.delta.y
    return targetRotation < range.dialMax && targetRotation > range.dialMin
  }

  public rotate(ctrl: PlacedSymbol, value: number) {
    ctrl.rotate(value, ctrl.bounds.center)
  }

  public rotateByLinearValue(dial: Dial, value: number): void {
    if (!dial) {
      return
    }
    const ctrl = dial.knob
    const range = dial.ranges
    ctrl.rotate(
      mapToRange(
        value,
        range.valMin,
        range.valMax,
        range.dialMin,
        range.dialMax,
      ),
    )
  }

  public rotateByLogValue(dial: Dial, value: number): void {
    const ctrl = dial.knob
    const range = dial.ranges
    const minOffset = Math.sqrt(range.dialMin * range.dialMin)
    ctrl.rotate(
      mapFromLog(
        value,
        0,
        range.dialMax + minOffset,
        range.valMin,
        range.valMax,
      ) - minOffset,
    )
  }

  public currentDialValue(dial: Dial, linOrLog: 'linear' | 'log'): number {
    const ctrl = dial.knob
    const range = dial.ranges
    switch (linOrLog) {
      case 'linear':
        return mapToRange(
          ctrl.rotation,
          range.dialMin,
          range.dialMax,
          range.valMin,
          range.valMax,
        )
      case 'log':
        return mapToLog(
          ctrl.rotation,
          range.dialMin,
          range.dialMax,
          range.valMin,
          range.valMax,
        )
      default:
        return 0
    }
  }
}

const rotationController = new RotationController()

export default rotationController
