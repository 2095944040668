import { Path, Point, Size, Symbol } from 'paper'
import { EColours } from '../utils/colors'
import { defaultConfigDimensions, IConfig, IConfigDimensions } from './config'
import { ELayerNames, getLayerByName } from './layers'

interface IRackConfig extends IConfigDimensions {
  bgColor: string
  color: string
  screwRadius: number
  symbols: { [key: string]: any }
}

let config: IRackConfig = {
  ...defaultConfigDimensions,
  bgColor: EColours.BACKGROUND_DARKER,
  color: EColours.GREY_LIGHTER,
  screwRadius: 8,
  symbols: {},
}

const drawRack = (newConfig: IConfig) => {
  config = {
    ...config,
    ...newConfig.dimensions,
    screwRadius: newConfig.dimensions.unit / 4,
    symbols: newConfig.symbols,
  }
  const { numRows, rowHeight, unit } = config

  getLayerByName(ELayerNames.RACK).activate()

  // BACKGROUND
  const bg = new Path.Rectangle(
    new Point(0, 0),
    new Size(config.width, config.height),
  )
  bg.fillColor = config.bgColor

  // LOGO
  const logo = config.symbols.logo.clone().place()
  logo.position = bg.position
  logo.fitBounds(bg.bounds)

  // RAILS
  for (let row = 0; row < numRows; row++) {
    const y = row * rowHeight
    createRail(y)
    createRail(y + rowHeight - unit)
  }
}

const createRail = (y: number) => {
  const { color, numCols, screwRadius, unit, width } = config

  const topEdge = new Path()
  topEdge.add(new Point(0, y))
  topEdge.add(new Point(width, y))
  topEdge.strokeColor = color

  const bottomEdge = new Path()
  bottomEdge.add(new Point(0, y + unit))
  bottomEdge.add(new Point(width, y + unit))
  bottomEdge.strokeColor = color

  for (let x = 0; x < numCols; x++) {
    const circle = new Path.Circle(
      new Point(x * unit + unit / 2, y + unit / 2),
      screwRadius,
    )
    circle.fillColor = color
  }
}

export default drawRack
