import { debounce } from 'lodash'
import { Layer, PlacedSymbol, Point, PointText, Rectangle, Size } from 'paper'
import { IConfig } from '../setup/config'
import { ELayerNames, getLayerByName } from '../setup/layers'
import { EColours } from '../utils/colors'
import {
  defaultIoOpts,
  EPatchTypes,
  EPosLocations,
  IIOBase,
  IOConnectionCallbacks,
  IoSpec,
} from './patching.model'

export default class IOBase implements IIOBase {
  get beforeConnect() {
    return this.callbacks.beforeConnect
  }

  get afterConnect() {
    return this.callbacks.afterConnect
  }

  get beforeDisconnect() {
    return this.callbacks.beforeDisconnect
  }

  get afterDisconnect() {
    return this.callbacks.afterDisconnect
  }

  get center() {
    return this.symbol.bounds.center
  }

  get unit() {
    return this.config.dimensions.unit
  }

  public static onConnectCreator = (cb: (node: AudioNode) => void) =>
    debounce(cb, 1)

  public static onDisconnectCreator = (cb: (node: AudioNode) => void) =>
    debounce(cb, 1)
  public position: Point
  public symbol!: PlacedSymbol
  public readonly layer: Layer = getLayerByName(ELayerNames.CONTROLS)
  private text!: PointText

  constructor(
    public node: AudioNode | AudioParam,
    public spec: IoSpec,
    public posOffset: Point,
    public config: IConfig,
    public callbacks: IOConnectionCallbacks = {},
  ) {
    this.position = this.initPosition()
    this.build()
  }

  public build() {
    // should be implemented by instance
  }

  protected initPosition() {
    const [xOff, yOff] = this.spec.pos
    return new Point(xOff * this.unit, yOff * this.unit).add(this.posOffset)
  }

  protected getBuildParams() {
    const { width } = this.spec
    const {
      patchCtrl,
      symbols,
      dimensions: { unit },
    } = this.config
    return {
      patchCtrl,
      symbols,
      unit,
      width,
    }
  }

  protected createSymbol(type: EPatchTypes) {
    const { unit, width } = this.getBuildParams()
    this.layer.activate()
    this.symbol = this.config.symbols[type].clone().place(this.position)
    this.symbol.set({
      ...defaultIoOpts,
      data: {
        parent: this,
        type,
      },
      bounds: new Rectangle(
        this.position,
        new Size((width || 1) * unit, (width || 1) * unit),
      ),
    })
    this.createText()
  }

  protected createText() {
    if (!this.spec.name || !this.spec.namePos) {
      return
    }
    const { name } = this.spec
    const { point, fontSize, justification } = this.getTextPoint()
    this.text = new PointText({
      point,
      fillColor: EColours.WHITE,
      content: name || '0',
      fontWeight: 'bold',
      fontSize,
      justification,
    })
  }

  private getTextPoint() {
    const { namePos, fontSize } = this.spec
    let point = this.symbol.bounds.center
    let justification = 'center'
    const newFontSize = (this.config.dimensions.unit / 2.2) * (fontSize || 1)
    switch (namePos) {
      case EPosLocations.TOP:
        point = point.subtract(new Point(0, this.symbol.bounds.height / 1.5))
        break
      case EPosLocations.LEFT:
        point = point.subtract(
          new Point(this.symbol.bounds.width / 1.5, -newFontSize / 3),
        )
        justification = 'right'
        break
      case EPosLocations.RIGHT:
        point = point.add(
          new Point(this.symbol.bounds.width / 1.5, newFontSize / 3),
        )
        justification = 'left'
        break
      case EPosLocations.BOTTOM:
      default:
        point = point.add(new Point(0, this.symbol.bounds.height / 1.15))
        break
    }
    return {
      fontSize: newFontSize,
      point,
      justification,
    }
  }
}
