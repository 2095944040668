import { EP5_COMPONENTS, IP5Opts } from './visuals.model'

const namespace = 'VISUALS'

export class AUpdateP5Canvas {
  public static readonly type = `${namespace}/UPDATE_P5_CANVAS`

  public static create(name: EP5_COMPONENTS, opts: IP5Opts): AUpdateP5Canvas {
    return { type: AUpdateP5Canvas.type, name, opts }
  }

  public readonly type = AUpdateP5Canvas.type
  public readonly name: EP5_COMPONENTS
  public readonly opts: IP5Opts
}
