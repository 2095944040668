import { Group, Point } from 'paper'
import { IModuleSpec } from '../../moduleSpecs/moduleSpecs.model'
import Input from '../../patching/Input'
import { IOConnectionCallbacks } from '../../patching/patching.model'
import { IConfig } from '../../setup/config'
import { ENodeTargets } from '../nodes.model'
import RackModule from '../RackModule'
import { IRackModule } from '../rackModule.model'
import VuMeter from '../../components/VuMeter'

class VuMono extends RackModule implements IRackModule {
  public static create(spec: IModuleSpec, config: IConfig): VuMono {
    return new VuMono(spec, config)
  }
  private screen: VuMeter

  public constructor(public spec: IModuleSpec, public config: IConfig) {
    super(spec, config)
    const {
      dimensions: { unit },
    } = this.config
    this.screen = VuMeter.create(
      new Point(0.5 * unit, 1.5 * unit).add(this.bounds.topLeft),
      this.spec.width,
      this.config,
    )
    this.buildNodes()
    this.createInputs()
    this.createOutputs()
    this.drawCtrls()
    this.applyNodeDefaults()
  }

  public buildNodes(): void {
    const grp = new Group({name: 'test'})
    grp.onFrame = this.screen.draw.bind(this.screen)
  }

  public createInputs() {
    this.spec.inputs.forEach(spec => {
      let target
      const callbacks: IOConnectionCallbacks = {}
      switch (spec.target) {
        case ENodeTargets.ANALYSER:
        default:
          target = this.screen.analyser
          callbacks.afterConnect = () => {
            this.screen.setHasSrc(true)
          }
          callbacks.afterDisconnect = () => {
            this.screen.setHasSrc(false)
          }
          break
      }
      Input.create(
        target,
        spec,
        this.spec.bounds.topLeft,
        this.config,
        callbacks,
      )
    })
  }

  public createOutputs() {
    // not required
  }

  public drawCtrls(): void {
    const {
      dimensions: { unit },
    } = this.config
  }

  public applyNodeDefaults(): void {
    // not required
  }
}

export default VuMono
