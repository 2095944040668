import { RouteComponentProps } from '@reach/router'
import { Component } from 'react'
import React from 'react'
import { SideMenu } from '../../components/sideMenu/SideMenu'

import { PageTitle } from '../../components/pageTitle/PageTitle'
import './side-menu-and-content.scss'

export interface ISideMenuAndContentOwnProps {
  navLinks: any
  navMenuTitle?: string
}

export interface ISideMenuAndContentStateProps {
  navLinks: any
}

export interface ISideMenuAndContentProps
  extends ISideMenuAndContentOwnProps,
    ISideMenuAndContentStateProps,
    RouteComponentProps {}

export class SideMenuAndContent extends Component<ISideMenuAndContentProps> {
  public state: any = {
    height: 0,
  }
  private menuRef: HTMLDivElement | null
  public constructor(props: ISideMenuAndContentProps) {
    super(props)
  }

  public componentDidMount() {
    if (!this.menuRef) {
      return
    }
    this.setState({ height: window.innerHeight - this.menuRef.offsetTop })
  }

  public render() {
    return (
      <div className="side-menu-layout">
        <div
          className="side-menu-layout__content"
          ref={el => (this.menuRef = el)}
        >
          <SideMenu height={this.state.height}>
            {this.props.navMenuTitle ? (
              <PageTitle title={this.props.navMenuTitle} />
            ) : null}

            {this.props.navLinks.map((n: any, i: number) => (
              <div className="side-menu-layout__nav-link" key={i}>
                {n}
              </div>
            ))}
          </SideMenu>
          <div className="side-menu-layout__children">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}
