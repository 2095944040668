import { PlacedSymbol, Rectangle, Size } from 'paper'
import Dial from '../../controlsComponents/Dial'
import { IModuleSpec } from '../../moduleSpecs/moduleSpecs.model'
import Input from '../../patching/Input'
import Output from '../../patching/Output'
import { IConfig } from '../../setup/config'
import { ELayerNames, getLayerByName } from '../../setup/layers'
import { EColours } from '../../utils/colors'
import rotationController from '../../utils/rotationController'
import { ENodeControls } from '../nodes.model'
import RackModule from '../RackModule'
import { IRackModule } from '../rackModule.model'

class Mult extends RackModule implements IRackModule {
  get volume() {
    return this.gain.gain.value
  }

  set volume(value: number) {
    this.gain.gain.value = value
  }

  public static create(spec: IModuleSpec, config: IConfig): Mult {
    return new Mult(spec, config)
  }
  private gain!: GainNode
  private gain2!: GainNode

  public constructor(public spec: IModuleSpec, public config: IConfig) {
    super(spec, config)
    this.buildNodes()
    this.createInputs()
    this.createOutputs()
    this.drawLogo()
  }

  public buildNodes(): void {
    this.gain = this.ctx.createGain()
    this.gain2 = this.ctx.createGain()
    this.gain.gain.value = 1
    this.gain2.gain.value = 1
  }

  public createInputs() {
    Input.create(
      this.gain,
      this.spec.inputs[0],
      this.spec.bounds.topLeft,
      this.config,
      {},
    )
    Input.create(
      this.gain2,
      this.spec.inputs[1],
      this.spec.bounds.topLeft,
      this.config,
      {},
    )
  }

  public drawCtrls(): void {
    //
  }

  public createOutputs(): void {
    this.spec.outputs.forEach((spec, i) => {
      i < 3
        ? Output.create(
            this.gain,
            spec,
            this.spec.bounds.topLeft,
            this.config,
            {},
          )
        : Output.create(
            this.gain2,
            spec,
            this.spec.bounds.topLeft,
            this.config,
            {},
          )
    })
  }
  public applyNodeDefaults(): void {
    //
  }

  private drawLogo() {
    this.layer.activate()
    const {
      dimensions: { unit },
    } = this.config
    const w = unit * 0.8
    const logo = new PlacedSymbol(this.config.symbols.logo.clone())
    logo.fitBounds(
      new Rectangle(
        this.bounds.center.subtract([w / 2, w / 2]),
        new Size(w, w),
      ),
    )
    logo.symbol.definition.fillColor = EColours.WHITE
  }
}

export default Mult
