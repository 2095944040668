import { EPosLocations } from '../patching/patching.model'
import { ENodeTargets } from '../rackModule/nodes.model'
import {
  defaultModuleSpec,
  EModuleTypes,
  IModuleSpec,
} from './moduleSpecs.model'

const oscilloscope = (pos: [number, number] = [0, 0]): IModuleSpec => ({
  ...defaultModuleSpec,
  name: ['Synth Waves'],
  namePos: [6, 0],
  pos,
  width: 12,
  dials: {},
  inputs: [
    {
      name: 'in',
      namePos: EPosLocations.BOTTOM,
      fontSize: 0.8,
      pos: [5.5, 9],
      width: 1,
      target: ENodeTargets.ANALYSER,
    },
  ],
  type: EModuleTypes.OSCILLOSCOPE,
})

export default oscilloscope
