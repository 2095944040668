import { MouseEvent, PlacedSymbol, Point, PointText } from 'paper'
import { IConfig } from '../setup/config'
import { IDialRange } from '../utils/dimensions-and-ranges'
import { ELayerNames, getLayerByName } from '../setup/layers'
import { EColours } from '../utils/colors'
import positionController from '../utils/positionController'
import rotationController from '../utils/rotationController'
import { IModuleDialSpec } from './controls.model'

class Dial {
  set onDoubleClick(callback: () => {}) {
    // should be overwritten by instance
  }

  set rotation(value: number) {
    this.knob.rotation = value
  }

  get data() {
    return this.knob.data
  }

  set range(newRange: any) {
    this.ranges = newRange
  }

  private get update() {
    return this.knob.data.update
  }

  private get dialSymbol() {
    return this.config.symbols.dial
  }

  private get dialCenter() {
    return this.knob.bounds.center
  }

  public static create(
    spec: IModuleDialSpec,
    position: Point,
    config: IConfig,
  ): Dial {
    return new Dial(spec, position, config)
  }
  public knob!: PlacedSymbol
  public ranges: IDialRange
  private text!: PointText

  public constructor(
    public spec: IModuleDialSpec,
    public position: Point,
    public config: IConfig,
  ) {
    this.ranges = spec.range
    this.build()
  }

  public set(props: any) {
    this.knob.set(props)
  }

  public setData(props: any) {
    this.knob.set({
      data: {
        ...this.knob.data,
        ...props,
      },
    })
  }

  public setText(value: string) {
    this.text.content = value
  }

  private build() {
    getLayerByName(ELayerNames.CONTROLS).activate()
    this.createDial()
    this.createText()
  }

  private createDial() {
    this.knob = this.dialSymbol.place()
    this.knob.set({
      applyMatrix: false,
      bounds: positionController.getCtrlBounds(this.position, this.spec),
      onMouseDrag: (evt: MouseEvent) => {
        if (
          rotationController.canRotate(evt, this.knob, this.ranges) &&
          this.update
        ) {
          rotationController.rotate(this.knob, -evt.delta.y)
          this.update()
        }
      },
      rotation: 0,
    })
  }

  private createText() {
    const fontSize =
      (this.config.dimensions.unit / 2.2) * (this.spec.fontSize || 1)
    this.text = new PointText({
      content: this.spec.text || '0',
      fillColor: EColours.WHITE,
      fontSize,
      fontWeight: 'bold',
      justification: 'center',
      point: [this.dialCenter.x, 0],
    })
    this.text.bounds.top = this.knob.bounds.bottom + 3
    this.text.visible = this.spec.showValue
  }
}

export default Dial
