import * as p5 from 'p5'
import { getDimensions } from '../../../components/P5/p5'
import { Curve } from './curve'

export const LissajousCreator = (
  wd: number,
  he: number,
  parentId: string,
  getOpts: () => any,
) => (s: any) => {
  let opts = getOpts()
  let canvas = null
  const pId = parentId
  const parent = document.getElementById(pId)
  const winW = wd
  const winH = he
  let w = parent ? (winW - (winW % opts.divisor)) / opts.divisor : 80
  // let shouldTakePic = false
  let cols = 0
  let rows = 0
  let curves: Curve[][] = []

  const setup = () => {
    canvas = s.createCanvas(winW, winH)
    canvas.parent(pId)
    s.pixelDensity(1)
    cols = (s.width - (s.width % w)) / w - 1
    rows = (s.height - (s.height % w)) / w - 1
    curves = Array(rows)
      .fill(0)
      .map(() => Array(cols).fill(0))
    for (let j = 0; j < rows; j++) {
      for (let i = 0; i < cols; i++) {
        curves[j][i] = new Curve(s)
      }
    }
  }

  const draw = () => {
    s.clear()
    opts = getOpts()

    s.noFill()
    const d = w - 20
    const r = d / 2
    drawX(d, r)
    drawY(d, r)
    drawCurves()
    updateAngle()
    // takePic()
  }

  const windowResized = () => {
    if (parent) {
      const [nw, nh] = getDimensions(parent)
      w = (nw - (nh % opts.divisor)) / opts.divisor
      s.resizeCanvas(nw, nh)
      s.setup()
    }
  }

  const drawX = (d: number, r: number) => {
    // columns
    for (let i = 0; i < cols; i++) {
      const c = new p5.Vector(w + i * w + w / 2, w / 2)
      const pos = new p5.Vector(
        r * Math.cos(opts.angle * (i + 1) - s.HALF_PI),
        r * Math.sin(opts.angle * (i + 1) - s.HALF_PI),
      )
      drawCircles(c, pos, d)
      // rows
      for (let j = 0; j < rows; j++) {
        curves[j][i].setX(c.x + pos.x)
      }
    }
  }

  const drawY = (d: number, r: number) => {
    // rows
    for (let j = 0; j < rows; j++) {
      const c = new p5.Vector(w / 2, w + j * w + w / 2)
      const pos = new p5.Vector(
        r * Math.cos(opts.angle * (j + 1) - s.HALF_PI),
        r * Math.sin(opts.angle * (j + 1) - s.HALF_PI),
      )
      drawCircles(c, pos, d, 'y')
      // cols
      for (let i = 0; i < cols; i++) {
        curves[j][i].setY(c.y + pos.y)
      }
    }
  }

  const drawCircles = (c: any, pos: any, d: number, xORy = 'x') => {
    const isX = xORy === 'x'
    drawCircle(c, d)
    drawCirclePoint(c, pos)
    drawPointLine(xORy, isX ? c.x : c.y, isX ? pos.x : pos.y)
  }

  const drawCircle = (c: any, d: number) => {
    s.stroke(255)
    s.strokeWeight(1)
    s.ellipse(c.x, c.y, d, d)
  }

  const drawCirclePoint = (c: any, pos: any) => {
    s.strokeWeight(8)
    s.point(c.x + pos.x, c.y + pos.y)
  }

  const drawPointLine = (axis: string, center: number, pos: number) => {
    let x
    let y
    let wdth
    let h
    switch (axis) {
      case 'x':
        x = center + pos
        y = 0
        wdth = x
        h = s.height
        break
      case 'y':
        x = 0
        y = center + pos
        wdth = s.width
        h = y
        break
      default:
        break
    }
    s.stroke(255, 255, 255, 100)
    s.strokeWeight(1)
    s.line(x, y, wdth, h)
  }

  const drawCurves = () => {
    for (let j = 0; j < rows; j++) {
      for (let i = 0; i < cols; i++) {
        curves[j][i].addPoint().show()
      }
    }
  }

  const updateAngle = () => {
    opts.angle += opts.angleInc

    if (opts.angle > s.TWO_PI) {
      for (let j = 0; j < rows; j++) {
        for (let i = 0; i < cols; i++) {
          curves[j][i].reset()
        }
      }
      opts.angle = 0
    }
  }

  // const takePic = () => {
  //   if (shouldTakePic)
  //     s.saveCanvas(canvas, `lissajous`, 'png')
  // }

  s.setup = setup
  s.draw = draw
  s.windowResized = windowResized
}
