import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  ISoundcloudTrack,
  selectTrack,
} from '../../../soundcloud/soundcloud.model'
import { IAppState } from '../../../store/store.model'
import {
  AAddTrackToPlayer,
  APauseTrack,
  APlayTrack,
} from '../trackPlayer/store/trackPlayer.actions'
import {
  ISelectedTrackDispatchProps,
  ISelectedTrackStateProps,
  SelectedTrackComponent,
} from './SelectedTrack.component'

const mapStateToProps = (state: IAppState): ISelectedTrackStateProps => {
  const track = selectTrack(state.soundcloudTracks, state.currentScTrack)
  return {
    embed: state.currentScTrackEmbed,
    tpc: state.trackPlayerComponent,
    track,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
): ISelectedTrackDispatchProps => ({
  add: (id: ISoundcloudTrack['id']) => dispatch(AAddTrackToPlayer.create(id)),
  play: () => dispatch(APlayTrack.create()),
  pause: () => dispatch(APauseTrack.create()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectedTrackComponent)
