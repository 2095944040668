import Button from '../../controlsComponents/Button'
import ButtonGroup from '../../controlsComponents/ButtonGroup'
import ParamController from '../../controlsComponents/ParamController'
import { IOscillatorSpec } from '../../moduleSpecs/basicOscillator'
import { IModuleSpec } from '../../moduleSpecs/moduleSpecs.model'
import Input from '../../patching/Input'
import Output from '../../patching/Output'
import { IOConnectionCallbacks } from '../../patching/patching.model'
import { IConfig } from '../../setup/config'
import {
  fmGainRanges,
  frequencyRanges,
  lfoRanges,
} from '../../utils/dimensions-and-ranges'
import {
  ENodeControls,
  ENodeTargets,
  EWaveForms,
} from '../nodes.model'
import RackModule from '../RackModule'
import { IRackModule } from '../rackModule.model'

class Oscillator extends RackModule implements IRackModule {
  public static create(spec: IModuleSpec, config: IConfig): Oscillator {
    return new Oscillator(spec as IOscillatorSpec, config)
  }
  private osc!: OscillatorNode
  private oscOutput: Output

  // param ctrls
  private freqCtrl!: ParamController
  private detuneCtrl!: ParamController
  private fmAmtCtrl!: ParamController
  private waveformBtns!: ButtonGroup
  private lfoToggle!: Button

  // input gains
  private fmInputGain!: GainNode
  private fmAmtInputGain!: GainNode

  public constructor(public spec: IOscillatorSpec, public config: IConfig) {
    super(spec, config)
    this.buildNodes()
    this.drawCtrls()
    this.createInputs()
    this.createOutputs()
    this.applyNodeDefaults()
  }

  public buildNodes(): void {
    this.osc = this.ctx.createOscillator()

    this.fmInputGain = this.ctx.createGain()
    this.fmInputGain.gain.value = 1
    this.fmInputGain.connect(this.osc.frequency)

    this.fmAmtInputGain = this.ctx.createGain()
    this.fmAmtInputGain.gain.value = 100
    this.fmAmtInputGain.connect(this.fmInputGain.gain)
  }

  public drawCtrls(): void {
    this.createFreqDial()
    this.createDetuneDial()
    this.createFmAmtDial()
    this.createBtns()
  }

  public applyNodeDefaults() {
    this.resetFrequency()
    this.resetDetune()
    this.resetFmAmt()
  }

  //////////////////////////////////////////////////
  // Create I/O
  //////////////////////////////////////////////////

  public createInputs() {
    this.spec.inputs.forEach(spec => {
      let target
      const callbacks: IOConnectionCallbacks = {}
      switch (spec.target) {
        case ENodeTargets.OSC_FREQUENCY:
          target = this.fmInputGain
          break
        case ENodeTargets.FM_AMOUNT:
        default:
          target = this.fmAmtInputGain
          break
      }
      Input.create(
        target,
        spec,
        this.spec.bounds.topLeft,
        this.config,
        callbacks,
      )
    })
  }

  public createOutputs() {
    this.oscOutput = Output.create(
      this.osc,
      this.spec.outputs[0],
      this.spec.bounds.topLeft,
      this.config,
      {
        beforeConnect: () => this.osc.start(),
        afterDisconnect: () => this.createOsc(),
      },
    )
  }

  //////////////////////////////////////////////////
  // Create controls
  //////////////////////////////////////////////////

  /**
   * Frequency
   */
  public createFreqDial() {
    this.freqCtrl = ParamController.create(
      this.osc.frequency,
      this.spec.dials[ENodeControls.FREQUENCY],
      this.setFrequency,
      this.resetFrequency,
      this.bounds.topLeft,
      this.config,
    )
  }

  private setFrequency = (value?: number) => {
    if (!this.osc) {
      return
    }
    const selected = this.lfoToggle.grp.data.selected
    this.freqCtrl.value =
      typeof value === 'number'
        ? value
        : this.freqCtrl.currentRotation(selected ? 'linear' : 'log')
    this.freqCtrl.setText(this.freqCtrl.value.toFixed(selected ? 2 : 0) + ' hz')
  }

  private resetFrequency = () => {
    const selected = this.lfoToggle.grp.data.selected
    const value = selected ? 1 : this.spec.frequency
    this.setFrequency(value)
    this.freqCtrl.rotate(value, selected ? 'linear' : 'log')
  }

  /**
   * Detune
   */
  public createDetuneDial() {
    this.detuneCtrl = ParamController.create(
      this.osc.detune,
      this.spec.dials[ENodeControls.DETUNE],
      this.setDetune,
      this.resetDetune,
      this.bounds.topLeft,
      this.config,
    )
  }

  private setDetune = (value?: number) => {
    if (!this.detuneCtrl) {
      return
    }
    this.detuneCtrl.value =
      typeof value === 'number'
        ? value
        : this.detuneCtrl.currentRotation('linear')
  }

  private resetDetune = () => {
    this.setDetune(this.spec.detune)
    this.detuneCtrl.rotate(this.spec.detune, 'linear')
  }

  /**
   * FM
   */
  public createFmAmtDial() {
    this.fmAmtCtrl = ParamController.create(
      this.fmInputGain.gain,
      this.spec.dials[ENodeControls.FM_AMOUNT],
      this.setFmAmt,
      this.resetFmAmt,
      this.bounds.topLeft,
      this.config,
    )
  }

  private createOsc() {
    const d = this.osc.detune.value
    const f = this.osc.frequency.value
    const g = this.fmInputGain.gain.value
    this.fmInputGain.gain.setValueAtTime(0, this.ctx.currentTime)
    this.osc.stop()
    this.fmInputGain.disconnect(this.osc.frequency)
    this.osc = this.ctx.createOscillator()
    this.osc.frequency.value = f
    this.osc.detune.value = d
    this.oscOutput.node = this.osc
    this.freqCtrl.updateParam(this.osc.frequency)
    this.detuneCtrl.updateParam(this.osc.detune)
    this.fmInputGain.connect(this.osc.frequency)
    this.fmInputGain.gain.setValueAtTime(g, this.ctx.currentTime)
  }

  private setFmAmt = (value?: number) => {
    if (!this.osc) {
      return
    }
    const currRotationVal = this.fmAmtCtrl.currentRotation('linear')
    this.fmAmtCtrl.value =
      typeof value === 'number'
        ? value
        : currRotationVal < 5
        ? 0
        : currRotationVal
  }

  private resetFmAmt = () => {
    this.setFmAmt(fmGainRanges.valMin)
    this.fmAmtCtrl.rotate(this.fmAmtCtrl.value, 'linear')
  }

  /**
   * Buttons
   */
  private createBtns() {
    this.lfoToggle = this.createBtnCtrl(ENodeControls.LFO_TOGGLE, btn => {
      btn.setData({
        onMouseDown: () => {
          this.freqCtrl.range = btn.selected ? lfoRanges : frequencyRanges
          this.resetFrequency()
        },
      })
    })
    this.waveformBtns = new ButtonGroup(
      'radio',
      Object.values(EWaveForms).map(waveform =>
        this.createBtnCtrl(waveform, button => {
          button.setData({
            onMouseDown: () => this.setWaveform(waveform),
          })
        }),
      ),
    )

    this.waveformBtns.setSelected(this.spec.waveform)
  }

  private setWaveform(waveform: string) {
    if (this.osc) {
      this.osc.type = waveform as EWaveForms
    }
  }
}

export default Oscillator
