export interface IDialRange {
  dialMin: number
  dialMax: number
  valMin: number
  valMax: number
}

export const frequencyRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 40,
  valMax: 15000,
}

export const gainRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0.0,
  valMax: 1.0,
}

export const panRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: -1,
  valMax: 1,
}

export const delayTimeRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0.0,
  valMax: 10.0,
}

export const feedbackRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0.0,
  valMax: 1.2,
}

export const dryWetRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0.0,
  valMax: 1.0,
}

export const filterGainRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: -40,
  valMax: 40,
}

export const qRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0,
  valMax: 100,
}

export const qAmtRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0,
  valMax: 100,
}

export const fmGainRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0,
  valMax: 1000,
}

export const lfoRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0.01,
  valMax: 40.0,
}

export const detuneRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: -100,
  valMax: 100,
}

export const compressorAttackRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0,
  valMax: 1,
}

export const compressorKneeRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0,
  valMax: 40,
}

export const compressorRatioRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0,
  valMax: 20,
}

export const compressorReleaseRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: 0,
  valMax: 1,
}

export const compressorThresholdRanges: IDialRange = {
  dialMin: -130,
  dialMax: 130,
  valMin: -100,
  valMax: 0,
}
