import ParamController from '../../controlsComponents/ParamController'
import { IModuleSpec } from '../../moduleSpecs/moduleSpecs.model'
import Input from '../../patching/Input'
import Output from '../../patching/Output'
import { IConfig } from '../../setup/config'
import { ENodeControls } from '../nodes.model'
import RackModule from '../RackModule'
import { IRackModule } from '../rackModule.model'

class Compressor extends RackModule implements IRackModule {
  public static create(spec: IModuleSpec, config: IConfig): Compressor {
    return new Compressor(spec, config)
  }
  private compressor!: DynamicsCompressorNode
  private inputGain!: GainNode
  private outputGain!: GainNode
  private dryGain!: GainNode
  private wetGain!: GainNode
  private attackCtrl!: ParamController
  private kneeCtrl!: ParamController
  private ratioCtrl!: ParamController
  private releaseCtrl!: ParamController
  private thresholdCtrl!: ParamController
  private dryWetCtrl!: ParamController

  private defaultAttack = 0.003
  private defaultKnee = 30
  private defaultRatio = 12
  private defaultRelease = 0.25
  private defaultThreshold = -24
  private defaultDryWet = 0.5

  public constructor(public spec: IModuleSpec, public config: IConfig) {
    super(spec, config)
    this.buildNodes()
    this.createInputs()
    this.createOutputs()
    this.drawCtrls()
    this.applyNodeDefaults()
  }

  public buildNodes(): void {
    this.compressor = this.ctx.createDynamicsCompressor()
    this.inputGain = this.ctx.createGain()
    this.outputGain = this.ctx.createGain()
    this.dryGain = this.ctx.createGain()
    this.wetGain = this.ctx.createGain()

    this.compressor.attack.value = this.defaultAttack
    this.compressor.knee.value = this.defaultKnee
    this.compressor.ratio.value = this.defaultRatio
    this.compressor.release.value = this.defaultRelease
    this.compressor.threshold.value = this.defaultThreshold

    this.inputGain.gain.value = 1
    this.outputGain.gain.value = 1
    this.dryGain.gain.value = 1
    this.wetGain.gain.value = 1

    this.inputGain.connect(this.compressor)
    this.compressor.connect(this.wetGain)
    this.dryGain.connect(this.outputGain)
    this.wetGain.connect(this.outputGain)
  }

  public drawCtrls(): void {
    this.createAttackDial()
    this.createKneeDial()
    this.createRatioDial()
    this.createReleaseDial()
    this.createThresholdDial()
    this.createDryWetDial()
  }
  public applyNodeDefaults(): void {
    this.resetAttack()
    this.resetKnee()
    this.resetRatio()
    this.resetRelease()
    this.resetThreshold()
    this.resetDryWet()
  }

  /**
   * Attack
   */
  public createAttackDial() {
    this.attackCtrl = ParamController.create(
      this.compressor.attack,
      this.spec.dials[ENodeControls.ATTACK],
      this.setAttack,
      this.resetAttack,
      this.bounds.topLeft,
      this.config,
    )
  }

  private setAttack = (value?: number) => {
    if (!this.attackCtrl) {
      return
    }
    this.attackCtrl.value =
      typeof value === 'number'
        ? value
        : this.attackCtrl.currentRotation('linear')
    this.attackCtrl.setText((this.attackCtrl.value * 1000).toFixed(0) + ' ms')
  }

  private resetAttack = () => {
    this.setAttack(this.defaultAttack)
    this.attackCtrl.rotate(this.defaultAttack, 'linear')
  }

  /**
   * Knee
   */
  public createKneeDial() {
    this.kneeCtrl = ParamController.create(
      this.compressor.knee,
      this.spec.dials[ENodeControls.KNEE],
      this.setKnee,
      this.resetKnee,
      this.bounds.topLeft,
      this.config,
    )
  }

  private setKnee = (value?: number) => {
    if (!this.kneeCtrl) {
      return
    }
    this.kneeCtrl.value =
      typeof value === 'number'
        ? value
        : this.kneeCtrl.currentRotation('linear')
  }

  private resetKnee = () => {
    this.setKnee(this.defaultKnee)
    this.kneeCtrl.rotate(this.defaultKnee, 'linear')
  }

  /**
   * Ratio
   */
  public createRatioDial() {
    this.ratioCtrl = ParamController.create(
      this.compressor.ratio,
      this.spec.dials[ENodeControls.RATIO],
      this.setRatio,
      this.resetRatio,
      this.bounds.topLeft,
      this.config,
    )
  }

  private setRatio = (value?: number) => {
    if (!this.ratioCtrl) {
      return
    }
    const newValue = Math.floor(
      typeof value === 'number'
        ? value
        : this.ratioCtrl.currentRotation('linear') + 1,
    )
    this.ratioCtrl.value = newValue
    this.ratioCtrl.setText(`${newValue === 20 ? '∞' : newValue}:1`)
  }

  private resetRatio = () => {
    this.setRatio(this.defaultRatio)
    this.ratioCtrl.rotate(this.defaultRatio, 'linear')
  }

  /**
   * Release
   */
  public createReleaseDial() {
    this.releaseCtrl = ParamController.create(
      this.compressor.release,
      this.spec.dials[ENodeControls.RELEASE],
      this.setRelease,
      this.resetRelease,
      this.bounds.topLeft,
      this.config,
    )
  }

  private setRelease = (value?: number) => {
    if (!this.releaseCtrl) {
      return
    }
    this.releaseCtrl.value =
      typeof value === 'number'
        ? value
        : this.releaseCtrl.currentRotation('linear')
  }

  private resetRelease = () => {
    this.setRelease(this.defaultRelease)
    this.releaseCtrl.rotate(this.defaultRelease, 'linear')
  }

  /**
   * Threshold
   */
  public createThresholdDial() {
    this.thresholdCtrl = ParamController.create(
      this.compressor.threshold,
      this.spec.dials[ENodeControls.THRESHOLD],
      this.setThreshold,
      this.resetThreshold,
      this.bounds.topLeft,
      this.config,
    )
  }

  private setThreshold = (value?: number) => {
    if (!this.thresholdCtrl) {
      return
    }
    this.thresholdCtrl.value =
      typeof value === 'number'
        ? value
        : this.thresholdCtrl.currentRotation('linear')
  }

  private resetThreshold = () => {
    this.setThreshold(this.defaultThreshold)
    this.thresholdCtrl.rotate(this.defaultThreshold, 'linear')
  }

  /**
   * DryWet
   */
  public createDryWetDial() {
    this.dryWetCtrl = ParamController.create(
      this.outputGain.gain,
      this.spec.dials[ENodeControls.DRY_WET],
      this.setDryWet,
      this.resetDryWet,
      this.bounds.topLeft,
      this.config,
    )
  }

  private setDryWet = (value?: number) => {
    if (!this.dryWetCtrl) {
      return
    }
    const newValue =
      typeof value === 'number'
        ? value
        : this.dryWetCtrl.currentRotation('linear')

    this.dryGain.gain.value = -newValue + 1
    this.wetGain.gain.value = newValue
  }

  private resetDryWet = () => {
    this.setDryWet(this.defaultDryWet)
    this.dryWetCtrl.rotate(this.defaultDryWet, 'linear')
  }

  /**
   * Inputs
   */

  public createInputs() {
    Input.create(
      this.inputGain,
      this.spec.inputs[0],
      this.spec.bounds.topLeft,
      this.config,
      {
        afterConnect: output => output.connect(this.dryGain),
        beforeDisconnect: output => output.disconnect(this.dryGain),
      },
    )
  }

  /**
   * Outputs
   */
  public createOutputs(): void {
    Output.create(
      this.outputGain,
      this.spec.outputs[0],
      this.spec.bounds.topLeft,
      this.config,
      {},
    )
  }
}

export default Compressor
