import { Group, IFrameEvent, Path, Point, Rectangle, Size } from 'paper'
import { IConfig } from '../setup/config'
import { EColours } from '../utils/colors'

class VuMeter {
  public static create(pos: Point, width: number, config: IConfig): VuMeter {
    return new VuMeter(pos, width, config)
  }
  public analyser!: AnalyserNode
  private pN:ScriptProcessorNode;

  public hasSrc = false
  private meter!: Path.Rectangle

  public constructor(
    public pos: Point,
    public width: number,
    public config: IConfig,
  ) {
    this.buildNodes()
  }

  public buildNodes(): void {
    this.analyser = this.config.ctx.createAnalyser()
    this.analyser.fftSize = 2048
  }

  public setHasSrc(hasSrc: boolean) {
    this.hasSrc = hasSrc
    if (!this.hasSrc) {
      this.meter.remove()
    }
  }

  public draw = () => {
    window.requestAnimationFrame(this.draw.bind(this))
    if (!this.meter || !this.hasSrc) {
      return
    }

    if (this.meter) {
      this.meter.remove()
    }

    console.log('draw')
    const {
      dimensions: { unit },
    } = this.config

    // get the average, bincount is fftsize / 2
    let arrayL = new Uint8Array(this.analyser.frequencyBinCount),
      modifier = 9,
      avgL
    this.analyser.getByteFrequencyData(arrayL)
    avgL = this.getAverageVolume(arrayL)
    // clear the current state
    this.meter = new Path.Rectangle({
      point: this.pos,
      radius: new Size(3, 3),
      size: new Size(this.meter.bounds.width, this.meter.bounds.height - avgL * modifier),
      opacity: 0.4,
      fillColor: EColours.RED,
      shadowColor: EColours.GREEN,
      shadowBlur: 16,
    })
  }

  private getAverageVolume(frequencyBins: Uint8Array) {
    return (
      frequencyBins.reduce((val, prev) => val + prev, 0) / frequencyBins.length
    )
  }
}

export default VuMeter
