import p5 from '../../../components/P5/p5'
import { W_LOWERCASE } from '../../../shared/keyboard'
import { Mover } from './Mover'

export const GravityWindCreator = (
  wd: number,
  he: number,
  parentId: string,
  getOpts: () => any,
) => (s: any) => {
  let opts = getOpts()
  const winW = wd
  const winH = he
  const pId = parentId
  const wind = new p5.Vector(opts.windC, 0)
  const circles: Mover[] = []
  const desc = `Each circle has a gravity constant of ${
    opts.gravityC
  } being applied to its acceleration. 

There is no air resistance or friction, so the circles will bounce forever. 
    
Hold down 'w' to apply a ${wind.x} wind force to the circles.`

  s.setup = () => {
    const canvas = s.createCanvas(winW, winH)
    canvas.parent(pId)
    for (let i = 0; i < 10; i++) {
      const mass = s.random(opts.massMin, opts.massMax)
      circles.push(
        new Mover({
          mass,
          s,
          x: s.random(s.width - 200),
          y: s.random(s.height - 100),
        }),
      )
    }
  }

  s.draw = () => {
    s.clear()
    opts = getOpts()

    s.textFont('Helvetica')
    s.textSize(30)
    s.fill('#fff')
    s.text(desc, 50, 50, winW - 50)

    circles.forEach(ball => {
      const gravity = new p5.Vector(0, opts.gravityC)
      gravity.mult(ball.state.mass)
      ball.applyForce(gravity)

      if (s.keyIsDown(W_LOWERCASE)) {
        ball.applyForce(wind)
      }

      ball.edgeBounce()
      ball.update()
      ball.display()
    })
  }
}
