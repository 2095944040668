import { Group, MouseEvent } from 'paper'
import Button from './Button'

class ButtonGroup {
  public grp: Group
  public btnsByName: { [key: string]: Button } = {}

  public constructor(
    public type: 'radio' | 'button',
    private buttons: Button[],
  ) {
    this.grp = new Group(buttons.map(b => b.grp))
    this.grp.children.forEach(g => (g.onMouseDown = () => undefined))
    this.grp.onMouseDown =
      this.type === 'radio'
        ? this.handleRadio.bind(this)
        : this.handleButton.bind(this)
    this.buttons.forEach(btn => (this.btnsByName[btn.name] = btn))
  }

  public handleButton(evt: MouseEvent) {
    this.setSelected(evt.target.name)
    if (evt.target.data.onMouseDown) {
      evt.target.onMouseDown(evt)
    }
  }

  public handleRadio(evt: MouseEvent) {
    this.buttons.forEach(button => {
      if (button.selected) {
        button.data.updateStyle()
      }
      if (button.grp === evt.target) {
        button.data.updateStyle()
        if (button.data.onMouseDown) {
          button.data.onMouseDown(evt)
        }
      }
    })
  }

  public setSelected(name: string) {
    const button = this.getButton(name)
    if (button) {
      button.setSelected()
    }
  }

  public getButton(name: string): Button | undefined {
    return this.btnsByName[name]
  }
}

export default ButtonGroup
