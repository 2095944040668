import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IAppState } from '../../../store/store.model'
import {
  ADecreaseVolume,
  AIncreaseVolume,
  APauseTrack,
  APlayTrack,
} from './store/trackPlayer.actions'
import {
  ITrackPlayerDispatch,
  ITrackPlayerState,
  TrackPlayerComponent,
} from './trackPlayer.component'

const mapStateToProps = (state: IAppState): ITrackPlayerState => ({
  tpc: state.trackPlayerComponent,
})

const mapDispatchToProps = (dispatch: Dispatch): ITrackPlayerDispatch => ({
  play: () => dispatch(APlayTrack.create()),
  pause: () => dispatch(APauseTrack.create()),
  volumeUp: () => dispatch(AIncreaseVolume.create()),
  volumeDown: () => dispatch(ADecreaseVolume.create()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrackPlayerComponent)
