import { createDialSpec } from '../controlsComponents/controls.model'
import { EPosLocations } from '../patching/patching.model'
import { ENodeControls, ENodeTargets } from '../rackModule/nodes.model'
import { gainRanges, panRanges } from '../utils/dimensions-and-ranges'
import {
  defaultModuleSpec,
  EModuleTypes,
  IModuleSpec,
} from './moduleSpecs.model'

const basicMixer = (pos: [number, number] = [0, 0]): IModuleSpec => ({
  ...defaultModuleSpec,
  type: EModuleTypes.MIXER,
  name: ['Mixermitosis'],
  namePos: [3, 0],
  pos,
  width: 6,
  dials: {
    [ENodeControls.GAIN]: createDialSpec({
      text: ENodeControls.GAIN,
      pos: [1, 7.5],
      width: 2.5,
      range: gainRanges,
    }),
    gain1: createDialSpec({
      text: 'gain 1',
      pos: [0.25, 3],
      width: 1.5,
      range: gainRanges,
    }),
    gain2: createDialSpec({
      text: 'gain 2',
      pos: [2.25, 3],
      width: 1.5,
      range: gainRanges,
    }),
    gain3: createDialSpec({
      text: 'gain 3',
      pos: [4.25, 3],
      width: 1.5,
      range: gainRanges,
    }),
    pan1: createDialSpec({
      text: 'pan 1',
      pos: [0.5, 5.5],
      width: 1,
      range: panRanges,
    }),
    pan2: createDialSpec({
      text: 'pan 2',
      pos: [2.5, 5.5],
      width: 1,
      range: panRanges,
    }),
    pan3: createDialSpec({
      text: 'pan 3',
      pos: [4.5, 5.5],
      width: 1,
      range: panRanges,
    }),
  },
  inputs: [
    {
      name: 'in 1',
      namePos: EPosLocations.TOP,
      fontSize: 1,
      pos: [0.5, 1.75],
      width: 1,
      target: ENodeTargets.GAIN,
    },
    {
      name: 'in 2',
      namePos: EPosLocations.TOP,
      fontSize: 1,
      pos: [2.5, 1.75],
      width: 1,
      target: ENodeTargets.GAIN,
    },
    {
      name: 'in 3',
      namePos: EPosLocations.TOP,
      fontSize: 1,
      pos: [4.5, 1.75],
      width: 1,
      target: ENodeTargets.GAIN,
    },
  ],
  outputs: [
    {
      name: 'out',
      namePos: EPosLocations.TOP,
      fontSize: 1,
      pos: [4, 9.75],
      width: 1,
      target: ENodeTargets.DELAY,
    },
  ],
})

export default basicMixer
