import React from 'react'
import TrackPlayer from '../../features/music/trackPlayer/trackPlayer.container'
import { NavLink } from '../navLink/NavLink.component'
import './header.scss'

export const Header = () => (
  <div className="header">
    <NavLink to="/">
      <h2>Jim Wilde</h2>
    </NavLink>
    <TrackPlayer />
    <nav>
      <NavLink to="/music" text="Music" />
      &nbsp;|&nbsp;
      <NavLink to="/jrbmod" text="JR B-Mod" />
      &nbsp;|&nbsp;
      {/*<NavLink to="/audio" text="Audio" partial={true} />*/}
      {/*&nbsp;|&nbsp;*/}
      <NavLink to="/visuals" text="Visuals" partial={true} />
    </nav>
  </div>
)
