import * as _ from 'lodash'
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { switchMap, withLatestFrom } from 'rxjs/operators'
import { IEpic } from '../store/store.model'
import {
  ALoadedSoundcloudEmbed,
  ALoadedSoundcloudPlaylists,
  ALoadedSoundcloudTracks,
  ALoadSoundcloudEmbed,
  ALoadSoundcloudEmbedFailed,
  ALoadSoundcloudPlaylists,
  ALoadSoundcloudPlaylistsFailed,
  ALoadSoundcloudTracks,
  ALoadSoundcloudTracksFailed,
  ASetCurrentScTrack,
} from './soundcloud.actions'
import {
  formatTracks,
  getFirstTrackId,
  ISoundcloudEmbed,
  loadEmbed,
  loadPlaylists,
  loadTracks,
  selectTrack,
} from './soundcloud.model'

export const loadTracksEpic: IEpic = ($action, $store) =>
  $action.pipe(
    ofType(ALoadSoundcloudTracks.type),
    switchMap((action: ALoadSoundcloudTracks) =>
      from(
        loadTracks()
          .then(tracks => ALoadedSoundcloudTracks.create(formatTracks(tracks)))
          .catch(e => ALoadSoundcloudTracksFailed.create(e)),
      ),
    ),
  )

export const loadPlaylistsEpic: IEpic = ($action, $store) =>
  $action.pipe(
    ofType(ALoadSoundcloudPlaylists.type),
    switchMap((action: ALoadSoundcloudPlaylists) =>
      from(
        loadPlaylists()
          .then(res => ALoadedSoundcloudPlaylists.create(res))
          .catch(e => ALoadSoundcloudPlaylistsFailed.create(e)),
      ),
    ),
  )

export const currentScTrackEpic: IEpic = $action =>
  $action.pipe(
    ofType(ALoadedSoundcloudTracks.type),
    switchMap(action =>
      of(ASetCurrentScTrack.create(getFirstTrackId(action.tracks))),
    ),
  )

export const currentScTrackEmbedEpic: IEpic = ($action, $store) =>
  $action.pipe(
    ofType(ALoadSoundcloudEmbed.type),
    withLatestFrom($store),
    switchMap(([action, store]) => {
      const src = Object.keys(action).includes('id')
        ? selectTrack(store.soundcloudTracks, action.id)
        : action.targetSrc
      return from(
        loadEmbed(src)
          .then((embed: ISoundcloudEmbed) =>
            ALoadedSoundcloudEmbed.create(embed),
          )
          .catch((e: Error) => ALoadSoundcloudEmbedFailed.create(e)),
      )
    }),
  )

export default [
  loadTracksEpic,
  loadPlaylistsEpic,
  currentScTrackEpic,
  currentScTrackEmbedEpic,
]
