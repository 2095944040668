import React, { Component } from 'react'

import './side-menu.scss'

export class SideMenu extends Component<{ children: any; height: number }> {
  public render() {
    return (
      <div className="side-menu" style={{ height: this.props.height }}>
        <nav className="side-menu__nav">{this.props.children}</nav>
      </div>
    )
  }
}
