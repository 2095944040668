import { MouseEvent } from 'paper'
import { getHSBColor } from '../utils/colors'
import Cable from './Cable'
import Output from './Output'
import {
  defaultPatchCableProps,
  IConnection,
  inputAtPosition,
} from './patching.model'

let nextId = 0

class PatchCtrl {
  get isPatching(): boolean {
    return this.patching
  }

  public static create() {
    return new PatchCtrl()
  }

  public static connect(cnx: IConnection) {
    const { input, output } = cnx
    if (!input) {
      return
    }
    if (output.beforeConnect) {
      output.beforeConnect(output.node, input.node)
    }
    if (input.beforeConnect) {
      input.beforeConnect(output.node, input.node)
    }
    output.node.connect(input.node)
    if (output.afterConnect) {
      output.afterConnect(output.node, input.node)
    }
    if (input.afterConnect) {
      input.afterConnect(output.node, input.node)
    }
  }

  public static disconnect(cnx: IConnection) {
    const { input, output } = cnx
    if (!input) {
      return
    }
    if (output.beforeDisconnect) {
      output.beforeDisconnect(output.node, input.node)
    }
    if (input.beforeDisconnect) {
      input.beforeDisconnect(output.node, input.node)
    }
    output.node.disconnect(input.node)
    if (output.afterDisconnect) {
      output.afterDisconnect(output.node, input.node)
    }
    if (input.afterDisconnect) {
      input.afterDisconnect(output.node, input.node)
    }
  }
  private candidate!: IConnection
  private patching = false
  private connex: {
    [key: string]: IConnection
  } = {}

  public startPatch(evt: MouseEvent, output: Output) {
    if (!output) {
      return
    }
    this.patching = true
    const existingCnx = this.getConnection(output)
    this.candidate = existingCnx
      ? existingCnx
      : this.createConnection({
          id: nextId++,
          cable: Cable.create({
            ...defaultPatchCableProps,
            color: getHSBColor(),
            origin: output.center,
          }),
          output,
        })
  }

  public dragCable = (evt: MouseEvent) => {
    const { cable, input, output } = this.candidate
    if (!cable || !output) {
      return
    }
    const foundInput = inputAtPosition(evt.point)
    if (input && !foundInput) {
      PatchCtrl.disconnect(this.candidate)
      this.removeCandidateConnection()
    }
    this.patching = true
    cable.onDrag(evt)
  }

  public completePatch = (evt: MouseEvent) => {
    const { cable, input, output } = this.candidate
    if (!cable || !output) {
      return
    }

    const candidate = this.candidate
    const foundInput = inputAtPosition(evt.point)
    const killCnx = input && !foundInput
    const newCnx = !input && foundInput
    const noCnx = !input && !foundInput
    const updateCnx = input && foundInput && input !== foundInput

    if (killCnx) {
      PatchCtrl.disconnect(candidate)
      this.removeCandidateConnection()
      this.removeConnection(candidate)
    } else if (newCnx) {
      candidate.input = foundInput
      PatchCtrl.connect(candidate)
    } else if (noCnx) {
      this.removeConnection(candidate)
    } else if (updateCnx) {
      PatchCtrl.disconnect(candidate)
      candidate.input = foundInput
      PatchCtrl.connect(candidate)
    }

    cable.completePatch(candidate.input ? candidate.input.center : undefined)
    this.patching = false
  }

  private getConnection(output: Output): IConnection | undefined {
    const cnx = Object.values(this.connex)
    const match = cnx.find(x => x.output === output)
    return match ? match : undefined
  }

  private createConnection(cnx: IConnection): IConnection {
    this.connex[cnx.id] = { ...cnx }
    return this.connex[cnx.id]
  }

  private removeConnection(cnx: IConnection) {
    delete this.connex[cnx.id]
  }

  private removeCandidateConnection() {
    delete this.candidate.input
  }
}
export default PatchCtrl
