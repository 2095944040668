import p5 from '../../../components/P5/p5'

import jrLogo from '../../../assets/images/site/jr-circle.svg'
import { Mover } from './Mover'

export const AccelerationCreator = (
  wd: number,
  he: number,
  parentId: string,
  getOpts: any,
) => (s: any) => {
  let opts = getOpts()
  const winW = wd
  const winH = he
  const pId = parentId
  let logo: any
  const logoR = 20
  let imgPos: any
  const circles: Mover[] = []
  const description = `Every frame we loop through the circles and apply an acceleration towards the mouse position.
    
If a circle is too close to the mouse, the acceleration is reversed and doubled`

  s.preload = () => {
    logo = s.loadImage(jrLogo)
  }

  s.setup = () => {
    const canvas = s.createCanvas(winW, winH)
    canvas.parent(pId)
    s.imageMode(s.CENTER)
    imgPos = new p5.Vector(s.width / 2, s.height / 2)
    for (let i = 0; i < opts.numCircles; i++) {
      let mass = s.randomGaussian(1, 2)
      const minMass = 0.4
      const maxMass = 6
      mass = mass < minMass ? minMass : mass >= maxMass ? maxMass : mass
      circles.push(
        new Mover({
          mass,
          s,
          velocityLimit: s.map(mass, minMass, maxMass, 12, 5),
          x: s.random(s.width),
          y: s.random(s.height),
        }),
      )
    }
  }

  s.mouseMoved = () => {
    let x = s.mouseX
    let y = s.mouseY
    const r = (logo ? logo.width : 40) / 2
    x = x < 0 ? r : x > s.width - r ? s.width - r : x
    y = y < 0 ? r : y > s.height - r ? s.height - r : y
    imgPos = new p5.Vector(x, y)
  }

  s.draw = () => {
    s.clear()
    opts = getOpts()
    showText()
    showLogo()
    updateBalls()
  }

  const showLogo = () => {
    logo.resize(2 * logoR, 0)
    s.image(logo, imgPos.x, imgPos.y)
  }

  const updateBalls = () =>
    circles.forEach(ball => {
      ball.applyForce(getMouseV(ball))
      ball.update()
      checkEdges(ball)
      ball.display()
    })

  const getMouseV = (ball: Mover) => {
    const mouse = new p5.Vector(imgPos.x, imgPos.y)
    mouse.sub(ball.state.location)
    let c = 0.5 / ball.state.mass
    if (mouse.mag() < ball.state.d) {
      c *= -2
    }
    mouse.setMag(c)
    if (!opts.useMass) {
      mouse.mult(ball.state.mass)
    }
    return mouse
  }

  const checkEdges = (ball: Mover) =>
    opts.wrap ? ball.edgeWrap() : opts.bounce ? ball.edgeBounce() : null

  const showText = () => {
    s.textFont('Helvetica')
    s.textSize(30)
    s.fill('#fff')
    s.text(description, 50, 50, winW - 50)
  }
}
