import { Group, IFrameEvent, Path, Point, Size } from 'paper'
import { IConfig } from '../setup/config'
import { EColours } from '../utils/colors'

class OscilloscopeScreen {
  public static create(
    pos: Point,
    width: number,
    config: IConfig,
  ): OscilloscopeScreen {
    return new OscilloscopeScreen(pos, width, config)
  }
  public analyser!: AnalyserNode

  public hasSrc = false
  private box!: Group
  private screen!: Path.Rectangle
  private line!: Path
  private bufferLength!: number
  private dataArray!: Uint8Array

  public constructor(
    public pos: Point,
    public width: number,
    public config: IConfig,
  ) {
    this.buildNodes()
    this.drawScreen()
  }

  public buildNodes(): void {
    this.analyser = this.config.ctx.createAnalyser()
    this.analyser.fftSize = 2048
    this.bufferLength = this.analyser.frequencyBinCount
    this.dataArray = new Uint8Array(this.bufferLength)
  }

  public drawScreen(): void {
    const {
      dimensions: { unit },
    } = this.config
    this.screen = new Path.Rectangle({
      point: this.pos,
      radius: new Size(3, 3),
      size: new Size((this.width - 1) * unit, 7 * unit),
      opacity: 0.4,
      fillColor: EColours.GREEN,
      shadowColor: EColours.GREEN,
      shadowBlur: 16,
    })
    this.box = new Group({
      name: 'oscilloscopeScreen',
      children: [this.screen],
    })

    this.box.onFrame = this.onFrame
  }

  public setHasSrc(hasSrc: boolean) {
    this.hasSrc = hasSrc
    if (!this.hasSrc) {
      this.line.remove()
    }
  }

  private onFrame = (evt: IFrameEvent) => {
    if (!this.screen || !this.hasSrc) {
      return
    }

    if (this.line) {
      this.line.remove()
    }

    this.analyser.getByteTimeDomainData(this.dataArray)
    const sliceWidth =
      parseFloat(this.screen.bounds.width.toString(10)) / this.bufferLength
    let x = this.screen.bounds.leftCenter.x

    this.line = new Path(this.screen.bounds.leftCenter)
    this.line.strokeColor = EColours.WHITE

    for (let i = 0; i < this.bufferLength; i++) {
      const v = this.dataArray[i] / 256.0
      const y = v * (this.screen.bounds.height - 16)
      this.line.add(new Point(x, y + this.screen.bounds.top + 8))
      x += sliceWidth
    }
    this.line.add(this.screen.bounds.rightCenter)
    this.line.closed = false
  }
}

export default OscilloscopeScreen
