export enum EAudioNodes {
  GAIN = 'gain',
  OSCILLATOR = 'oscillator',
}

export enum EOutputs {
  OSCILLATOR = 'oscillator',
  OUT = 'out',
}

export enum ENodeControls {
  ATTACK = 'attack',
  DELAY_TIME = 'delay_time',
  DETUNE = 'detune',
  DETUNE_AMOUNT = 'detune_amount',
  DRY_WET = 'dry_wet',
  FEEDBACK = 'feedback',
  FM_AMOUNT = 'fm_amount',
  FREQUENCY = 'frequency',
  GAIN = 'gain',
  GAIN_AMOUNT = 'gain_amount',
  KNEE = 'knee',
  LFO_TOGGLE = 'lfo_toggle',
  MASTER_OUTPUT = 'master_output',
  PRE_POST = 'pre_post',
  Q = 'Q',
  Q_AMOUNT = 'Q_amount',
  RATIO = 'ratio',
  REDUCTION = 'reduction',
  RELEASE = 'release',
  THRESHOLD = 'threshold',
}

export enum ENodeTargets {
  ANALYSER = 'analyser',
  COMPRESSOR = 'compressor',
  DELAY = 'delay',
  FILTER = 'filter',
  FILTER_DETUNE = 'filter_detune',
  FILTER_FREQUENCY = 'filter_frequency',
  FILTER_GAIN = 'filter_gain',
  FILTER_Q = 'filter_q',
  FM_AMOUNT = 'fm_amount',
  GAIN = 'gain',
  GAIN2 = 'gain2',
  MASTER_OUTPUT = 'master_output',
  OSC = 'oscillator',
  OSC_DETUNE = 'osc_detune',
  OSC_FREQUENCY = 'osc_frequency',
  OSC_TYPE = 'osc_type',
}

export enum EWaveForms {
  SINE = 'sine',
  SQUARE = 'square',
  SAWTOOTH = 'sawtooth',
  TRIANGLE = 'triangle',
}

export enum EBiquadFilterTypes {
  LOW_PASS = 'lowpass',
  HIGH_PASS = 'highpass',
  BAND_PASS = 'bandpass',
  LOW_SHELF = 'lowshelf',
  HIGH_SHELF = 'highshelf',
  PEAKING = 'peaking',
  NOTCH = 'notch',
  ALL_PASS = 'allpass',
}
