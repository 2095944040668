import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { ALoadSoundcloudTracks } from '../../soundcloud/soundcloud.actions'
import { sortByDate } from '../../soundcloud/soundcloud.model'
import { IAppState } from '../../store/store.model'
import { MusicComponent } from './Music.component'
import { IMusicDispatchProps, IMusicStateProps } from './Music.model'

const mapStateToProps = (state: IAppState): IMusicStateProps => {
  return {
    tracks: sortByDate(state.soundcloudTracks),
    playlists: state.soundcloudPlaylists,
    currentTrack: state.currentScTrack,
    currentTrackEmbed: state.currentScTrackEmbed,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IMusicDispatchProps => ({
  loadTracks: () => dispatch(ALoadSoundcloudTracks.create()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MusicComponent)
