import { MouseEvent, Point } from 'paper'
import OscilloscopeScreen from '../../components/OscilloscopeScreen'
import Button from '../../controlsComponents/Button'
import ButtonGroup from '../../controlsComponents/ButtonGroup'
import Dial from '../../controlsComponents/Dial'
import { IModuleSpec } from '../../moduleSpecs/moduleSpecs.model'
import Input from '../../patching/Input'
import { IConfig } from '../../setup/config'
import { frequencyRanges, lfoRanges } from '../../utils/dimensions-and-ranges'
import rotationController from '../../utils/rotationController'
import { ENodeControls, EWaveForms } from '../nodes.model'
import RackModule from '../RackModule'
import { IRackModule } from '../rackModule.model'

class MasterOutput extends RackModule implements IRackModule {
  get volume() {
    return this.outputGain.gain.value
  }

  set volume(value: number) {
    this.outputGain.gain.value = value
  }

  public static create(spec: IModuleSpec, config: IConfig): MasterOutput {
    return new MasterOutput(spec, config)
  }
  private outputGain!: GainNode
  private inputGain!: GainNode
  private gainCtrl!: Dial
  private screen: OscilloscopeScreen
  private prePostToggle!: Button
  private pre = true

  public constructor(public spec: IModuleSpec, public config: IConfig) {
    super(spec, config)
    this.buildNodes()
    this.createInputs()
    this.createOutputs()
    this.drawCtrls()
    this.applyNodeDefaults()
  }

  public buildNodes(): void {
    this.createScreen()
    this.inputGain = this.ctx.createGain()
    this.outputGain = this.ctx.createGain()
    this.inputGain.connect(this.outputGain)
    this.outputGain.connect(this.ctx.destination)
    this.connectAnalyser(this.pre ? this.inputGain : this.outputGain)
  }

  public createInputs() {
    this.spec.inputs.forEach(spec => {
      Input.create(
        this.inputGain,
        spec,
        this.spec.bounds.topLeft,
        this.config,
        {
          beforeConnect: () => this.screen.analyser.disconnect(),
          afterConnect: () => this.screen.setHasSrc(true),
          afterDisconnect: () => this.screen.setHasSrc(false),
        },
      )
    })
  }

  public drawCtrls(): void {
    this.createGainDial()
    this.createBtns()
  }

  public createGainDial() {
    this.gainCtrl = this.createDialCtrl(ENodeControls.GAIN, dial =>
      dial.set({
        data: {
          ...dial.data,
          update: () => {
            if (!this.outputGain) {
              return
            }
            this.volume = rotationController.currentDialValue(dial, 'linear')
          },
        },
      }),
    )
  }

  public createOutputs(): void {
    // unrequired
  }

  public applyNodeDefaults(): void {
    this.outputGain.gain.value = 0.05
    rotationController.rotateByLinearValue(
      this.gainCtrl,
      this.outputGain.gain.value,
    )
    this.gainCtrl.setText(Math.floor(this.outputGain.gain.value).toString(10))
  }

  private createScreen() {
    const {
      dimensions: { unit },
    } = this.config
    this.screen = OscilloscopeScreen.create(
      new Point(0.5 * unit, 1.5 * unit).add(this.bounds.topLeft),
      this.spec.width,
      this.config,
    )
  }

  /**
   * Buttons
   */
  private createBtns() {
    this.prePostToggle = this.createBtnCtrl(ENodeControls.PRE_POST, btn => {
      btn.setData({
        onMouseDown: this.prePostToggled,
      })
    })
  }

  private prePostToggled = () => {
    this.disconnectAnalyser(this.pre ? this.inputGain : this.outputGain)
    this.pre = !this.pre
    this.prePostToggle.setText(this.pre ? 'pre-gain' : 'post-gain')
    this.connectAnalyser(this.pre ? this.inputGain : this.outputGain)
  }

  private connectAnalyser(from: GainNode) {
    from.connect(this.screen.analyser)
  }

  private disconnectAnalyser(from: GainNode) {
    from.disconnect(this.screen.analyser)
  }
}

export default MasterOutput
