import { AnyAction, Reducer } from 'redux'
import * as actions from './soundcloud.actions'
import {
  ALoadedSoundcloudEmbed,
  ALoadedSoundcloudPlaylists,
} from './soundcloud.actions'
import {
  defaultSoundcloudEmbed,
  ISoundcloudEmbed,
  ISoundcloudPlaylist,
  ISoundcloudTrack,
  ISoundcloudTracks,
} from './soundcloud.model'

export const soundcloudTracks: Reducer<ISoundcloudTracks> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case actions.ALoadedSoundcloudTracks.type: {
      return action.tracks
    }
    default:
      return state
  }
}

export const currentScTrack: Reducer<ISoundcloudTrack['id']> = (
  state = 0,
  action: AnyAction,
) => {
  switch (action.type) {
    case actions.ASetCurrentScTrack.type: {
      return action.id
    }
    default:
      return state
  }
}

export const currentScTrackEmbed: Reducer<
  ISoundcloudEmbed,
  ALoadedSoundcloudEmbed
> = (state = defaultSoundcloudEmbed, action) => {
  switch (action.type) {
    case actions.ALoadedSoundcloudEmbed.type: {
      return action.embed
    }
    default:
      return state
  }
}

export const soundcloudPlaylists: Reducer<
  ISoundcloudPlaylist[],
  ALoadedSoundcloudPlaylists
> = (state = [], action) => {
  switch (action.type) {
    case actions.ALoadedSoundcloudPlaylists.type: {
      return action.playlists
    }
    default:
      return state
  }
}

export default [
  soundcloudTracks,
  currentScTrack,
  currentScTrackEmbed,
  soundcloudPlaylists,
]
