import P5 from 'p5'

const p5 = P5

export const load = (mod: any) => new p5(mod)

export const getDimensions = (parent: HTMLElement | null) =>
  parent
    ? [
        window.innerWidth - parent.offsetLeft,
        window.innerHeight - parent.offsetTop,
      ]
    : [800, 800]

export default p5
