import { MouseEvent, Path, Point } from 'paper'
import { ELayerNames, getLayerByName } from '../setup/layers'
import { defaultPatchCableProps, IPatchCableProps } from './patching.model'

class Cable {
  public static create(props: IPatchCableProps) {
    return new Cable(props)
  }
  private connected = false
  private cable: Path
  private layer = getLayerByName(ELayerNames.CABLES)

  public constructor(
    public props: IPatchCableProps = { ...defaultPatchCableProps },
  ) {
    this.props.outputAnchor = this.props.origin
    this.cable = this.createCablePath()
  }

  public onDrag(evt: MouseEvent) {
    this.dragCable(evt.point)
  }

  public completePatch(point: Point | undefined) {
    this.connected = !!point
    point ? this.drawCable(point) : this.removeCable()
  }

  private createCablePath(): Path {
    const { color, origin } = this.props
    this.layer.activate()
    const cable = new Path({
      segments: [origin],
      strokeColor: color,
      strokeWidth: 10,
      strokeCap: 'round',
      opacity: 0.3,
      locked: true,
    })

    cable.set({
      data: {
        onMouseEnter: () => {
          if (this.connected) {
            cable.opacity = 0.5
          }
        },
        onMouseLeave: () => {
          cable.opacity = 0.3
        },
      },
    })

    return cable
  }

  private dragCable(end: Point) {
    this.drawCable(end)
  }

  private removeCable() {
    this.cable.remove()
  }

  private drawCable(end: Point) {
    const { inputAnchorY, origin, outputAnchor } = this.props
    this.removeCable()
    this.cable = this.createCablePath()
    const inputAnchorX = end.subtract(origin).x / 4
    this.props.inputAnchor = origin.add([inputAnchorX, inputAnchorY])
    this.cable.cubicCurveTo(outputAnchor, this.props.inputAnchor, end)
  }
}

export default Cable
