import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IAppState } from '../../../store/store.model'
import { AUpdateP5Canvas } from '../store/visuals.actions'
import { EP5_COMPONENTS, IP5Opts } from '../store/visuals.model'
import {
  ITerrainDispatchProps,
  ITerrainStateProps,
  Terrain,
} from './TerrainFlyover.component'

const mapStateToProps = (store: IAppState): ITerrainStateProps => ({
  opts: store.visuals[EP5_COMPONENTS.TERRAIN],
})

const mapDispatchToProps = (dispatch: Dispatch): ITerrainDispatchProps => ({
  update: (name: EP5_COMPONENTS, opts: IP5Opts) =>
    dispatch(AUpdateP5Canvas.create(name, opts)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Terrain)
