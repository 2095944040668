import paper from 'paper'
import { IModuleSpec, IModuleSpecBasic } from '../moduleSpecs/moduleSpecs.model'
import PatchCtrl from '../patching/PatchCtrl'
import { createModules, IRackModule } from '../rackModule/rackModule.model'
import positionController from '../utils/positionController'
import rotationController from '../utils/rotationController'
import { loadSymbols } from '../utils/symbols'
import createLayers from './layers'

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export interface IConfigDimensions {
  height: number
  width: number
  numCols: number
  numRows: number
  unit: number
  screwRadius: number
  unitsPerRow: number
  rowHeight: number
}

export interface IConfig {
  ctx: AudioContext
  canvas: HTMLCanvasElement
  dimensions: IConfigDimensions
  modules: any[]
  moduleSpecs: IModuleSpec[]
  project: paper.Project
  symbols: { [key: string]: any }
  view: paper.View
  patchCtrl: PatchCtrl
}

const defaultUnit = window.innerHeight / 50
export const defaultConfigDimensions: IConfigDimensions = {
  height: 0,
  width: 0,
  numCols: window.innerHeight / defaultUnit,
  numRows: 2,
  unit: defaultUnit,
  screwRadius: 8,
  unitsPerRow: 11,
  rowHeight: 0,
}

/**
 * Creates the main config for the whole rack
 * @param canvas
 * @param moduleSpecs
 */
export const initConfig = async (
  canvas: HTMLCanvasElement,
  moduleSpecs: IModuleSpec[] | IModuleSpecBasic[],
): Promise<IConfig> => {
  const ctx = new AudioContext()
  await ctx.suspend()
  const width = window.innerWidth
  const height = window.innerHeight
  const unit = width / defaultConfigDimensions.numCols
  const rowHeight = defaultConfigDimensions.unitsPerRow * unit
  const screwRadius = unit / 4
  canvas.height = height
  canvas.width = width
  paper.setup(canvas)
  createLayers(paper.project)
  const patchCtrl = PatchCtrl.create()
  const newConfig = {
    canvas,
    ctx,
    dimensions: {
      ...defaultConfigDimensions,
      width,
      height,
      unit,
      screwRadius,
      rowHeight,
    },
    modules: [],
    moduleSpecs: [],
    patchCtrl,
    project: paper.project,
    symbols: {},
    view: paper.view,
  }
  positionController.setDimensions(newConfig.dimensions)
  rotationController.setDimensions(newConfig.dimensions)
  await loadSymbols(newConfig)
  const modules = createModules(moduleSpecs, newConfig)
  return {
    ...newConfig,
    modules,
    moduleSpecs: modules.map((module: IRackModule) => module.spec),
  }
}
