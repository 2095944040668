import p5 from '../../../components/P5/p5'

export class Mover {
  public state: any

  public constructor(props: any) {
    this.state = {
      acceleration: new p5.Vector(0, 0),
      color: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255,
      )}, ${Math.floor(Math.random() * 255)}, ${Math.random()})`,
      d: props.mass * 20,
      h: props.s.height,
      location: new p5.Vector(props.x, props.y),
      mass: 4,
      s: null,
      velocity: new p5.Vector(0, 0),
      velocityLimit: undefined,
      w: props.s.width,
      x: 0,
      y: 0,
      ...props,
    }
    this.update = this.update.bind(this)
    this.applyForce = this.applyForce.bind(this)
    this.edgeBounce = this.edgeBounce.bind(this)
    this.edgeWrap = this.edgeWrap.bind(this)
    this.display = this.display.bind(this)
  }

  public setState(newState: any) {
    this.state = { ...this.state, ...newState }
  }

  public update() {
    const { acceleration, location, velocity, velocityLimit } = this.state
    velocity.add(acceleration)
    location.add(velocity)
    if (velocityLimit) {
      velocity.limit(velocityLimit)
    }
    acceleration.mult(0)
  }

  public applyForce(force: any) {
    const f = p5.Vector.div(force, this.state.mass)
    this.state.acceleration.add(f)
  }

  public edgeBounce(friction?: any) {
    let hit = false
    const { d, location, h, velocity, w } = this.state
    if (location.x > w - d / 2) {
      hit = true
      location.x = w - d / 2
      velocity.x *= -1
    } else if (location.x < d / 2) {
      hit = true
      velocity.x *= -1
      location.x = d / 2
    }

    if (location.y > h - d / 2) {
      hit = true
      velocity.y *= -1
      location.y = h - d / 2
    } else if (location.y < d / 2) {
      hit = true
      velocity.y *= -1
      location.y = d / 2
    }
    if (hit && friction) {
      this.applyForce(friction)
    }
  }

  public edgeWrap() {
    const { location, h, w } = this.state
    if (location.x > w) {
      location.x = 0
    }
    if (location.x < 0) {
      location.x = w
    }
    if (location.y > h) {
      location.y = 0
    }
    if (location.y < 0) {
      location.y = h
    }
  }

  public display() {
    const { color, d, location, s } = this.state
    s.noStroke()
    s.fill(color)
    s.ellipse(location.x, location.y, d, d)
  }
}
