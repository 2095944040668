import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import { getDimensions, load } from '../p5'

export interface IP5CanvasOwnProps {
  opts: any
}

export interface IP5CanvasProps
  extends IP5CanvasOwnProps,
    RouteComponentProps {}

export class P5Canvas extends Component<IP5CanvasProps> {
  public state = {
    loading: false,
    pId: 'p5-canvas',
    sketch: undefined,
  }
  private elRef: HTMLDivElement | null

  constructor(props: IP5CanvasProps) {
    super(props)
    this.elRef = null
    this.getOpts = this.getOpts.bind(this)
  }

  public getOpts() {
    return this.props.opts.opts
  }

  public componentDidMount() {
    if (!this.state.loading) {
      this.setState({ loading: true })
      const timer = setInterval(() => {
        if (this.elRef) {
          clearInterval(timer)
          this.setState({
            loading: false,
            sketch: load(
              this.props.opts.ctrl(
                ...getDimensions(this.elRef.parentElement),
                this.state.pId,
                this.getOpts,
              ),
            ),
          })
        }
      }, 0)
    }
  }

  public componentWillUnmount() {
    if (this.state.sketch) {
      // @ts-ignore
      this.state.sketch.remove()
    }
  }

  public render() {
    return (
      <div className="p5" ref={el => (this.elRef = el)}>
        <div id={this.state.pId} className="canvas" />
      </div>
    )
  }
}
