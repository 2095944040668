import Button from '../../controlsComponents/Button'
import ButtonGroup from '../../controlsComponents/ButtonGroup'
import ParamController from '../../controlsComponents/ParamController'
import { IBiquadFilterSpec } from '../../moduleSpecs/basicBiquadFilter'
import { IModuleSpec } from '../../moduleSpecs/moduleSpecs.model'
import Input from '../../patching/Input'
import Output from '../../patching/Output'
import { IOConnectionCallbacks, IoSpec } from '../../patching/patching.model'
import { IConfig } from '../../setup/config'
import {
  detuneRanges,
  fmGainRanges,
  qAmtRanges,
} from '../../utils/dimensions-and-ranges'
import { EBiquadFilterTypes, ENodeControls, ENodeTargets } from '../nodes.model'
import RackModule from '../RackModule'
import { IRackModule } from '../rackModule.model'

class BiquadFilter extends RackModule implements IRackModule {
  public static create(spec: IModuleSpec, config: IConfig): BiquadFilter {
    return new BiquadFilter(spec as IBiquadFilterSpec, config)
  }
  public filter!: BiquadFilterNode

  private filterTypeBtns!: ButtonGroup

  private freqCtrl!: ParamController
  private freqInputGain!: GainNode
  private freqAmtCtrl!: ParamController

  private qCtrl!: ParamController
  private qInputGain!: GainNode
  private qAmtCtrl!: ParamController

  private gainCtrl!: ParamController
  private gainInputGain!: GainNode
  private gainAmtCtrl!: ParamController

  private detuneCtrl!: ParamController
  private detuneInputGain!: GainNode
  private detuneAmtCtrl!: ParamController

  public constructor(public spec: IBiquadFilterSpec, public config: IConfig) {
    super(spec, config)
    this.buildNodes()
    this.createInputs()
    this.createOutputs()
    this.drawCtrls()
    this.applyNodeDefaults()
  }

  public buildNodes(): void {
    this.filter = this.ctx.createBiquadFilter()
    this.filter.type = this.spec.filterType

    this.freqInputGain = this.ctx.createGain()
    this.freqInputGain.connect(this.filter.frequency)

    this.qInputGain = this.ctx.createGain()
    this.qInputGain.connect(this.filter.Q)

    this.gainInputGain = this.ctx.createGain()
    this.gainInputGain.connect(this.filter.gain)

    this.detuneInputGain = this.ctx.createGain()
    this.detuneInputGain.connect(this.filter.detune)
  }

  public drawCtrls(): void {
    this.createFreqDial()
    this.createFreqAmtDial()

    this.createQDial()
    this.createQAmtDial()

    this.createGainDial()
    this.createGainAmtDial()

    this.createDetuneDial()
    this.createDetuneAmtDial()

    this.createBtns()
  }

  public applyNodeDefaults() {
    this.resetFrequency()
    this.resetFrequencyAmt()
    this.resetQ()
    this.resetQAmt()
    this.resetGain()
    this.resetGainAmt()
    this.resetDetune()
    this.resetDetuneAmt()
  }

  //////////////////////////////////////////////////
  // Create I/O
  //////////////////////////////////////////////////

  public createInputs() {
    this.spec.inputs.forEach(spec => {
      let target
      const callbacks: IOConnectionCallbacks = {}
      switch (spec.target) {
        case ENodeTargets.FILTER_Q:
          target = this.qInputGain
          break
        case ENodeTargets.FILTER_FREQUENCY:
          target = this.freqInputGain
          break
        case ENodeTargets.FILTER_GAIN:
          target = this.gainInputGain
          break
        case ENodeTargets.FILTER_DETUNE:
          target = this.detuneInputGain
          break
        case ENodeTargets.FILTER:
        default:
          target = this.filter
          break
      }
      Input.create(
        target,
        spec,
        this.spec.bounds.topLeft,
        this.config,
        callbacks,
      )
    })
  }

  public createOutputs() {
    this.spec.outputs.forEach((spec: IoSpec) => {
      Output.create(this.filter, spec, this.spec.bounds.topLeft, this.config)
    })
  }

  //////////////////////////////////////////////////
  // Create controls
  //////////////////////////////////////////////////

  /**
   * Frequency
   */
  public createFreqDial() {
    this.freqCtrl = ParamController.create(
      this.filter.frequency,
      this.spec.dials[ENodeControls.FREQUENCY],
      this.setFrequency,
      this.resetFrequency,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Frequency Amount
   */
  public createFreqAmtDial() {
    this.freqAmtCtrl = ParamController.create(
      this.freqInputGain.gain,
      this.spec.dials[ENodeControls.FM_AMOUNT],
      this.setFrequencyAmt,
      this.resetFrequencyAmt,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Q
   */
  public createQDial() {
    this.qCtrl = ParamController.create(
      this.filter.Q,
      this.spec.dials[ENodeControls.Q],
      this.setQ,
      this.resetQ,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Q Amount
   */
  public createQAmtDial() {
    this.qAmtCtrl = ParamController.create(
      this.qInputGain.gain,
      this.spec.dials[ENodeControls.Q_AMOUNT],
      this.setQAmt,
      this.resetQAmt,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Gain
   */
  public createGainDial() {
    this.gainCtrl = ParamController.create(
      this.filter.gain,
      this.spec.dials[ENodeControls.GAIN],
      this.setGain,
      this.resetGain,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Gain Amount
   */
  public createGainAmtDial() {
    this.gainAmtCtrl = ParamController.create(
      this.qInputGain.gain,
      this.spec.dials[ENodeControls.GAIN_AMOUNT],
      this.setGainAmt,
      this.resetGainAmt,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Detune
   */
  public createDetuneDial() {
    this.detuneCtrl = ParamController.create(
      this.filter.detune,
      this.spec.dials[ENodeControls.DETUNE],
      this.setDetune,
      this.resetDetune,
      this.bounds.topLeft,
      this.config,
    )
  }

  /**
   * Detune Amount
   */
  public createDetuneAmtDial() {
    this.detuneAmtCtrl = ParamController.create(
      this.detuneInputGain.gain,
      this.spec.dials[ENodeControls.DETUNE_AMOUNT],
      this.setDetuneAmt,
      this.resetDetuneAmt,
      this.bounds.topLeft,
      this.config,
    )
  }

  private setFrequency = (value?: number) => {
    if (!this.freqCtrl) {
      return
    }
    this.freqCtrl.value = value ? value : this.freqCtrl.currentRotation('log')
    this.freqCtrl.setText(this.freqCtrl.value.toFixed(0) + ' hz')
  }

  private resetFrequency = () => {
    this.setFrequency(this.spec.frequency)
    this.freqCtrl.rotate(this.spec.frequency, 'log')
  }

  private setFrequencyAmt = (value?: number) => {
    if (!this.freqAmtCtrl) {
      return
    }
    const currRotationVal = this.freqAmtCtrl.currentRotation('linear')
    this.freqAmtCtrl.value =
      typeof value === 'number'
        ? value
        : currRotationVal < 0.01
        ? 0
        : currRotationVal
  }

  private resetFrequencyAmt = () => {
    this.setFrequencyAmt(fmGainRanges.valMin)
    this.freqAmtCtrl.rotate(this.freqAmtCtrl.value, 'linear')
  }

  private setQ = (value?: number) => {
    if (!this.qCtrl) {
      return
    }
    this.qCtrl.value =
      typeof value === 'number' ? value : this.qCtrl.currentRotation('linear')
  }

  private resetQ = () => {
    this.setQ(this.spec.Q)
    this.qCtrl.rotate(this.qCtrl.value, 'linear')
  }

  private setQAmt = (value?: number) => {
    if (!this.qAmtCtrl) {
      return
    }
    const currRotationVal = this.qAmtCtrl.currentRotation('linear')
    this.qAmtCtrl.value =
      typeof value === 'number'
        ? value
        : currRotationVal < 5
        ? 0
        : currRotationVal
  }

  private resetQAmt = () => {
    this.setQAmt(qAmtRanges.valMin)
    this.qAmtCtrl.rotate(this.qAmtCtrl.value, 'linear')
  }

  private setGain = (value?: number) => {
    if (!this.gainCtrl) {
      return
    }
    this.gainCtrl.value =
      typeof value === 'number'
        ? value
        : this.gainCtrl.currentRotation('linear')
  }

  private resetGain = () => {
    this.setGain(this.spec.gain)
    this.gainCtrl.rotate(this.spec.gain, 'linear')
  }

  private setGainAmt = (value?: number) => {
    if (!this.gainAmtCtrl) {
      return
    }
    const currRotationVal = this.gainAmtCtrl.currentRotation('linear')
    this.gainAmtCtrl.value =
      typeof value === 'number'
        ? value
        : currRotationVal < 5
        ? 0
        : currRotationVal
  }

  private resetGainAmt = () => {
    this.setGainAmt(fmGainRanges.valMin)
    this.gainAmtCtrl.rotate(this.gainAmtCtrl.value, 'linear')
  }

  private resetDetune = () => {
    this.setDetune(this.spec.detune)
    this.detuneCtrl.rotate(this.spec.detune, 'linear')
  }

  private setDetune = (value?: number) => {
    if (!this.detuneCtrl) {
      return
    }
    this.detuneCtrl.value =
      typeof value === 'number'
        ? value
        : this.detuneCtrl.currentRotation('linear')
  }

  private setDetuneAmt = (value?: number) => {
    if (!this.detuneAmtCtrl) {
      return
    }
    const currRotationVal = this.detuneAmtCtrl.currentRotation('linear')
    this.detuneAmtCtrl.value =
      typeof value === 'number'
        ? value
        : currRotationVal < 5
        ? 0
        : currRotationVal
  }

  private resetDetuneAmt = () => {
    this.setDetuneAmt(detuneRanges.valMin)
    this.detuneAmtCtrl.rotate(this.detuneAmtCtrl.value, 'linear')
  }

  /**
   * Buttons
   */
  private createBtns() {
    this.filterTypeBtns = new ButtonGroup(
      'radio',
      Object.values(this.spec.buttons).map(spec =>
        this.createBtnCtrl(spec.text, (button: Button) => {
          button.setData({
            onMouseDown: () =>
              this.setFilterType(spec.text as EBiquadFilterTypes),
          })
        }),
      ),
    )

    this.filterTypeBtns.setSelected(this.spec.filterType)
  }

  private setFilterType(type: EBiquadFilterTypes) {
    this.filter.type = type
  }
}

export default BiquadFilter
