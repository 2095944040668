import { ISoundcloudTrack } from '../../../../soundcloud/soundcloud.model'
import { ITrackPlayerComponent } from './trackPlayer.models'

////////////////////////////////////////////////////////////////////////////////////
// Add Track
////////////////////////////////////////////////////////////////////////////////////

export class AAddTrackToPlayer {
  public static readonly type = 'TRACK_PLAYER/ADD_TRACK'

  public static create(id: ISoundcloudTrack['id']): AAddTrackToPlayer {
    return { type: AAddTrackToPlayer.type, id }
  }

  public readonly type = AAddTrackToPlayer.type
  public readonly id: ISoundcloudTrack['id']
}

export class AAddTrackFailed {
  public static readonly type = 'TRACK_PLAYER/ADD_TRACK_FAILED'

  public static create(err: any): AAddTrackFailed {
    return { type: AAddTrackFailed.type, err }
  }

  public readonly type = AAddTrackFailed.type
  public readonly err: any
}

export class ATrackPlayerCreated {
  public static readonly type = 'TRACK_PLAYER/COMPONENT_CREATED'

  public static create(
    trackPlayerComponent: ITrackPlayerComponent,
  ): ATrackPlayerCreated {
    return { type: ATrackPlayerCreated.type, trackPlayerComponent }
  }

  public readonly type = ATrackPlayerCreated.type
  public readonly trackPlayerComponent: ITrackPlayerComponent
}

////////////////////////////////////////////////////////////////////////////////////
// Transport
////////////////////////////////////////////////////////////////////////////////////

export class APlayTrack {
  public static readonly type = 'TRACK_PLAYER/PLAY'

  public static create(): APlayTrack {
    return { type: APlayTrack.type }
  }

  public readonly type = APlayTrack.type
}

export class ATrackPlaying {
  public static readonly type = 'TRACK_PLAYER/PLAYING'

  public static create(): ATrackPlaying {
    return { type: ATrackPlaying.type }
  }

  public readonly type = ATrackPlaying.type
}

export class APlayTrackFailed {
  public static readonly type = 'TRACK_PLAYER/FAILED_TO_PLAY'

  public static create(reason: string): APlayTrackFailed {
    return { type: APlayTrackFailed.type, reason }
  }

  public readonly type = APlayTrackFailed.type
  public readonly reason: string
}

export class APauseTrack {
  public static readonly type = 'TRACK_PLAYER/PAUSE'

  public static create(): APauseTrack {
    return { type: APauseTrack.type }
  }

  public readonly type = APauseTrack.type
}

export class ATrackPaused {
  public static readonly type = 'TRACK_PLAYER/PAUSED'

  public static create(): ATrackPaused {
    return { type: ATrackPaused.type }
  }

  public readonly type = ATrackPaused.type
}

export class APauseTrackFailed {
  public static readonly type = 'TRACK_PLAYER/PAUSE_FAILED'

  public static create(reason: string): APauseTrackFailed {
    return { type: APauseTrackFailed.type, reason }
  }

  public readonly type = APauseTrackFailed.type
  public readonly reason: string
}

////////////////////////////////////////////////////////////////////////////////////
// Volume
////////////////////////////////////////////////////////////////////////////////////

export class AIncreaseVolume {
  public static readonly type = 'TRACK_PLAYER/INCREASE_VOLUME'

  public static create(): AIncreaseVolume {
    return { type: AIncreaseVolume.type }
  }

  public readonly type = AIncreaseVolume.type
}

export class AIncreasedVolume {
  public static readonly type = 'TRACK_PLAYER/INCREASED_VOLUME'

  public static create(volume: number): AIncreasedVolume {
    return { type: AIncreasedVolume.type, volume }
  }

  public readonly type = AIncreasedVolume.type
  public readonly volume: number
}

export class AIncreaseVolumeFailed {
  public static readonly type = 'TRACK_PLAYER/INCREASE_VOLUME_FAILED'

  public static create(error: Error): AIncreaseVolumeFailed {
    return { type: AIncreaseVolumeFailed.type, error }
  }

  public readonly type = AIncreaseVolumeFailed.type
  public readonly error: Error
}

export class ADecreaseVolume {
  public static readonly type = 'TRACK_PLAYER/DECREASE_VOLUME'

  public static create(): ADecreaseVolume {
    return { type: ADecreaseVolume.type }
  }

  public readonly type = ADecreaseVolume.type
}

export class ADecreasedVolume {
  public static readonly type = 'TRACK_PLAYER/DECREASED_VOLUME'

  public static create(volume: number): ADecreasedVolume {
    return { type: ADecreasedVolume.type, volume }
  }

  public readonly type = ADecreasedVolume.type
  public readonly volume: number
}

export class ADecreaseVolumeFailed {
  public static readonly type = 'TRACK_PLAYER/DECREASE_VOLUME_FAILED'

  public static create(error: Error): ADecreaseVolumeFailed {
    return { type: ADecreaseVolumeFailed.type, error }
  }

  public readonly type = ADecreaseVolumeFailed.type
  public readonly error: Error
}

////////////////////////////////////////////////////////////////////////////////////
// VISUALISER
////////////////////////////////////////////////////////////////////////////////////

export class ACreateTrackVisualiser {
  public static readonly type = 'TRACK_PLAYER/CREATE_VISUALISER'

  public static create(
    trackPlayerComponent: ITrackPlayerComponent,
  ): ACreateTrackVisualiser {
    return { type: ACreateTrackVisualiser.type, trackPlayerComponent }
  }

  public readonly type = ACreateTrackVisualiser.type
  public readonly trackPlayerComponent: ITrackPlayerComponent
}

export class ATrackVisualiserCreated {
  public static readonly type = 'TRACK_PLAYER/VISUALISER_CREATED'

  public static create(
    trackPlayerComponent: ITrackPlayerComponent,
  ): ATrackVisualiserCreated {
    return { type: ATrackVisualiserCreated.type, trackPlayerComponent }
  }

  public readonly type = ATrackVisualiserCreated.type
  public readonly trackPlayerComponent: ITrackPlayerComponent
}

export class ACreateTrackVisualiserFailed {
  public static readonly type = 'TRACK_PLAYER/CREATE_VISUALISER_FAILED'

  public static create(e: Error): ACreateTrackVisualiserFailed {
    return { type: ACreateTrackVisualiserFailed.type, e }
  }

  public readonly type = ACreateTrackVisualiserFailed.type
  public readonly e: Error
}
