import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { ActionCreator } from 'redux'
import jrLogo from '../../../assets/images/site/jr-circle.svg'
import {
  getTrackDate,
  ISoundcloudEmbed,
  ISoundcloudTrack,
} from '../../../soundcloud/soundcloud.model'
import AddToPlayerButton from '../addToPlayerButton/addToPlayerButton.container'
import {
  AAddTrackToPlayer,
  APauseTrack,
  APlayTrack,
} from '../trackPlayer/store/trackPlayer.actions'
import { ITrackPlayerComponent } from '../trackPlayer/store/trackPlayer.models'
import './selected-track.scss'

export interface ISelectedTrackStateProps {
  embed: ISoundcloudEmbed
  tpc: ITrackPlayerComponent
  track: ISoundcloudTrack
}

export interface ISelectedTrackDispatchProps {
  add: ActionCreator<AAddTrackToPlayer>
  play: ActionCreator<APlayTrack>
  pause: ActionCreator<APauseTrack>
}

export interface ISelectedTrackProps
  extends ISelectedTrackStateProps,
    ISelectedTrackDispatchProps,
    RouteComponentProps {}

export const SelectedTrackComponent = (props: ISelectedTrackProps) =>
  props.track ? (
    <div className="selected-track">
      <div className="selected-track__header">
        <span className="selected-track__img">
          <img src={props.track.artwork_url || jrLogo} alt=" " />
        </span>
        <span>
          <h4>{props.track.title}</h4>
          <span className="selected-track__subheader">
            {getTrackDate(props.track)}
            <AddToPlayerButton trackId={props.track.id} />
          </span>
        </span>
      </div>
      <p>{props.track.description}</p>
    </div>
  ) : null
