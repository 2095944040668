import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IAppState } from '../../../store/store.model'
import {
  AAddTrackToPlayer,
  APauseTrack,
  APlayTrack,
} from '../trackPlayer/store/trackPlayer.actions'
import {
  AudioElement,
  IAudioElementDispatch,
  IAudioElementOwnProps,
  IAudioElementStateProps,
} from './AudioElement'

const mapStateToProps = (
  state: IAppState,
  ownProps: IAudioElementOwnProps,
): IAudioElementStateProps => ({
  ...ownProps,
  tpc: state.trackPlayerComponent,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: IAudioElementOwnProps,
): IAudioElementDispatch => ({
  play: () => dispatch(APlayTrack.create()),
  pause: () => dispatch(APauseTrack.create()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioElement)
