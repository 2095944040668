import { EPosLocations } from '../patching/patching.model'
import { Omit } from '../setup/config'
import { gainRanges, IDialRange } from '../utils/dimensions-and-ranges'
import { EColours } from '../utils/colors'

export enum EControlTypes {
  DIAL = 'dial',
  BUTTON = 'button',
}

export interface IModuleCtrlSpecBasic {
  text?: string
  textPos?: EPosLocations
  fontSize?: number
  pos?: [number, number]
  props?: {
    fontSize?: number
    fillColor?: EColours
    strokeColor?: EColours
    strokeWidth?: number
  }
  range?: IDialRange
  showValue?: boolean
  symbolName?: string
  symbolHeight?: number
  symbolWidth?: number
  height?: number
  width?: number
}

export interface IModuleCtrlSpec extends IModuleCtrlSpecBasic {
  pos: [number, number]
  showValue: boolean
}

export interface IModuleDialSpec extends IModuleCtrlSpec {
  range: IDialRange
}

export interface IModuleButtonSpec extends IModuleCtrlSpec {
  text: string
  height?: number
  props?: {
    fontSize?: number
    fillColor?: EColours
    strokeColor?: EColours
    strokeWidth?: number
  }
}

export const defaultDialSpec: IModuleDialSpec = {
  fontSize: 1,
  pos: [0, 0],
  range: gainRanges,
  showValue: true,
  width: 1,
}

export const defaultButtonSpec: IModuleButtonSpec = {
  pos: [0, 0],
  text: '',
  textPos: EPosLocations.CENTER,
  height: 1,
  width: 1,
  showValue: true,
  props: {
    fillColor: EColours.ORANGE,
  },
}

export const createDialSpec = (
  props: IModuleCtrlSpecBasic,
): IModuleDialSpec => ({ ...defaultDialSpec, ...props })

export const createButtonSpec = (
  props: IModuleCtrlSpecBasic,
): IModuleButtonSpec => ({ ...defaultButtonSpec, ...props })
