import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import {
  EP5_COMPONENTS,
  IP5Opts,
} from '../../features/visual/store/visuals.model'
import { IAppState } from '../../store/store.model'
import { P5Canvas } from './p5Canvas/P5Canvas.component'

import './p5.scss'

interface IP5OwnProps {
  name: EP5_COMPONENTS
}

export interface IP5StateProps extends IP5OwnProps {
  name: EP5_COMPONENTS
  opts: IP5Opts
}

const mapStateToProps = (
  state: IAppState,
  ownProps: IP5OwnProps,
): IP5StateProps => ({
  name: ownProps.name,
  opts: state.visuals[ownProps.name],
})

export const P5Component = (props: IP5StateProps) => (
  <section className={`p5-container ${props.name}`}>
    <P5Canvas opts={props.opts} />
  </section>
)

export default connect(mapStateToProps)(P5Component)
