import React from 'react'
import './page-title.scss'

interface IPageTitleProps {
  title: string
}

export const PageTitle = (props: IPageTitleProps) => (
  <h1 className="page-title">{props.title}</h1>
)
