import { Point, Rectangle, Size } from 'paper'
import { IModuleCtrlSpec } from '../controlsComponents/controls.model'
import { IConfigDimensions } from '../setup/config'

class PositionController {
  public dimensions!: IConfigDimensions

  public setDimensions(dimensions: IConfigDimensions) {
    this.dimensions = dimensions
  }

  get unit() {
    return this.dimensions ? this.dimensions.unit : 0
  }

  public getCtrlPosition(spec: IModuleCtrlSpec, offset: Point) {
    const [xOff, yOff] = spec.pos
    return new Point(xOff * this.unit, yOff * this.unit).add(offset)
  }

  public getCtrlBounds(position: Point, spec: IModuleCtrlSpec): Rectangle {
    return new Rectangle(
      position,
      new Size((spec.width || 1) * this.unit, (spec.width || 1) * this.unit),
    )
  }
}

const positionController = new PositionController()

export default positionController
