import { createDialSpec } from '../controlsComponents/controls.model'
import { EPosLocations } from '../patching/patching.model'
import { ENodeControls, ENodeTargets } from '../rackModule/nodes.model'
import {
  delayTimeRanges,
  dryWetRanges,
  feedbackRanges,
} from '../utils/dimensions-and-ranges'
import {
  defaultModuleSpec,
  EModuleTypes,
  IModuleSpec,
} from './moduleSpecs.model'

const basicDelay = (pos: [number, number] = [0, 0]): IModuleSpec => ({
  ...defaultModuleSpec,
  type: EModuleTypes.DELAY,
  name: ['Say wot?'],
  namePos: [1.5, 0.65],
  pos,
  width: 3,
  dials: {
    [ENodeControls.DELAY_TIME]: createDialSpec({
      pos: [0.75, 1.9],
      width: 1.5,
      range: delayTimeRanges,
    }),
    [ENodeControls.FEEDBACK]: createDialSpec({
      text: ENodeControls.FEEDBACK,
      pos: [0.75, 4.35],
      width: 1.5,
      range: feedbackRanges,
    }),
    [ENodeControls.DRY_WET]: createDialSpec({
      text: 'mix',
      pos: [1, 6.75],
      width: 1,
      range: dryWetRanges,
    }),
  },
  inputs: [
    {
      name: 'in',
      namePos: EPosLocations.TOP,
      fontSize: 1,
      pos: [0.25, 9],
      width: 1,
      target: ENodeTargets.DELAY,
    },
  ],
  outputs: [
    {
      name: 'out',
      namePos: EPosLocations.TOP,
      fontSize: 1,
      pos: [1.75, 9],
      width: 1,
      target: ENodeTargets.DELAY,
    },
  ],
})

export default basicDelay
