import { Link, LinkGetProps } from '@reach/router'
import React from 'react'
import colors from '../../shared/colors'

export interface INavLinkProps {
  to: string
  text?: string
  children?: any
  partial?: boolean
}

export const NavLink = (props: INavLinkProps) => (
  <Link to={props.to} getProps={getProps(props)}>
    {props.children ? props.children : props.text}
  </Link>
)

const getProps = (props: INavLinkProps) => (linkProps: LinkGetProps): any => ({
  style: {
    color:
      ((props.partial && linkProps.isPartiallyCurrent) ||
        linkProps.isCurrent) &&
      props.to !== '/'
        ? colors.active
        : colors.inactive,
  },
})
