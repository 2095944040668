import { connect } from 'react-redux'
import { IAppState } from '../store/store.model'
import { App } from './App'
import { IAppStateProps } from './App.model'

const mapStateToProps = (state: IAppState): IAppStateProps => ({
  isLoggedIn: true,
})

export default connect(mapStateToProps)(App)
