import { Point, Symbol } from 'paper'
import { IConfig } from '../setup/config'
import { EColours } from '../utils/colors'
import IOBase from './IOBase'
import {
  EPatchTypes,
  IInput,
  IOConnectionCallbacks,
  IoSpec,
} from './patching.model'

class Input extends IOBase implements IInput {
  public static create(
    destNode: any,
    spec: IoSpec,
    pos: Point,
    config: IConfig,
    callbacks: IOConnectionCallbacks,
  ) {
    return new Input(destNode, spec, pos, config, callbacks)
  }

  constructor(
    public node: AudioParam,
    public spec: IoSpec,
    public posOffset: Point,
    public config: IConfig,
    public callbacks: IOConnectionCallbacks,
  ) {
    super(node, spec, posOffset, config, callbacks)
  }

  public build() {
    this.createSymbol(EPatchTypes.INPUT)
    this.symbol.symbol.definition.strokeColor = EColours.WHITE
    this.symbol.set({
      onMouseEnter: this.highlight.bind(this),
      onMouseLeave: this.unhighlight.bind(this),
    })
  }

  private highlight() {
    if (!this.config.patchCtrl.isPatching) {
      return
    }
    this.symbol.symbol.definition.strokeWidth = 8
  }

  private unhighlight() {
    this.symbol.symbol.definition.strokeWidth = 0
  }
}

export default Input
