import { Point, Rectangle, Size } from 'paper'
import {
  IModuleButtonSpec,
  IModuleDialSpec,
} from '../controlsComponents/controls.model'
import { IoSpec } from '../patching/patching.model'
import { EWaveForms } from '../rackModule/nodes.model'
import { EColours } from '../utils/colors'

export enum EModuleTypes {
  BIQUADFILTER = 'biquadfilter',
  COMPRESSOR = 'compressor',
  DELAY = 'delay',
  GAIN = 'gain',
  MULT = 'mult',
  MASTER_OUTPUT = 'master_output',
  MIXER = 'mixer',
  OSCILLATOR = 'oscillator',
  OSCILLOSCOPE = 'oscilloscope',
  VU_METER = 'vu_meter'
}

export interface IModuleSpecBasic {
  name: string[]
  namePos: [number, number]
  pos: [number, number]
  buttons: {
    [key: string]: IModuleButtonSpec
  }
  dials: {
    [key: string]: IModuleDialSpec
  }
  inputs: IoSpec[]
  outputs: IoSpec[]
  type: EModuleTypes
  width: number
}

export interface IModuleSpec extends IModuleSpecBasic {
  bounds: Rectangle
  bgColor: string
}

export const defaultModuleSpec: IModuleSpec = {
  name: [],
  namePos: [0, 0],
  pos: [0, 0],
  buttons: {},
  dials: {},
  inputs: [],
  outputs: [],
  bounds: new Rectangle(new Point(0, 0), new Size(0, 0)),
  width: 1,
  type: EModuleTypes.OSCILLATOR,
  bgColor: EColours.BACKGROUND,
}
