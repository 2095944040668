// import {defaultTerrainFlyoverOpts} from '../store/visuals.model';

export const TerrainCreator = (
  wd: number,
  he: number,
  parentId: string,
  getOpts: any,
) => (s: any) => {
  const getRotation = (divisor: number) => Math.PI / divisor
  let opts = getOpts()
  const scale = 20
  const winW = wd
  const winH = he
  const w = 2200
  const cols = w / scale
  const rows = opts.h / scale
  const terrain = Array(cols)
    .fill(0)
    .map(() => Array(rows).fill(0))

  const zInc = 0.05
  let maxZ = opts.z
  let minZ = -maxZ

  let flying = 0

  s.setup = () => {
    const canvas = s.createCanvas(winW, winH, s.WEBGL)
    canvas.parent(parentId)
    s.frameRate(60)
    s.pixelDensity(4)
    s.noiseDetail(30)
  }
  s.draw = () => {
    s.clear()
    opts = getOpts()
    maxZ = opts.z
    minZ = -maxZ
    drawTerrain()
  }

  const drawTerrain = () => {
    s.rotateX(getRotation(opts.r))
    s.translate(-w / 2, -opts.h / 2)
    s.stroke(102, 255, 102)
    s.fill(50, 100, 50)
    flying -= opts.v
    let yoff = flying
    for (let y = 0; y < rows; y++) {
      let xoff = 0
      for (let x = 0; x < cols; x++) {
        terrain[x][y] = s.noise(xoff, yoff) * (maxZ - minZ) + minZ
        xoff += zInc
      }
      yoff += zInc
    }
    for (let y = 0; y < rows - 1; y++) {
      s.beginShape(s.TRIANGLE_STRIP)
      for (let x = 0; x < cols; x++) {
        s.vertex(x * scale, y * scale, terrain[x][y])
        s.vertex(x * scale, (y + 1) * scale, terrain[x][y + 1])
      }
      s.endShape()
    }
    s.rotateX(-getRotation(opts.r))
    s.translate(w / 2, opts.h / 2)
  }

  return s
}
