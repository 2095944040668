import { ofType } from 'redux-observable'
import { map, withLatestFrom } from 'rxjs/operators'
import { getAudioElement } from '../../../../soundcloud/soundcloud.model'
import { IEpic } from '../../../../store/store.model'
import * as actions from './trackPlayer.actions'

export const createTrackPlayerComponentEpic: IEpic = ($action, $store) =>
  $action.pipe(
    ofType(actions.AAddTrackToPlayer.type),
    withLatestFrom($store),
    map(([action, store]) => {
      try {
        const track = store.soundcloudTracks[action.id]
        const player = getAudioElement(track)
        return actions.ATrackPlayerCreated.create({
          paused: false,
          player,
          track,
          volume: 1,
        })
      } catch (e) {
        return actions.AAddTrackFailed.create(e)
      }
    }),
  )

export const playTrackEpic: IEpic = ($action, $store) =>
  $action.pipe(
    ofType(actions.APlayTrack.type),
    withLatestFrom($store),
    map(([action, store]) => {
      return store.trackPlayerComponent.player
        ? actions.ATrackPlaying.create()
        : actions.APlayTrackFailed.create('No player exists')
    }),
  )

export const pauseTrackEpic: IEpic = ($action, $store) =>
  $action.pipe(
    ofType(actions.APauseTrack.type),
    withLatestFrom($store),
    map(([action, store]) => {
      return store.trackPlayerComponent.player
        ? actions.ATrackPaused.create()
        : actions.APauseTrackFailed.create('No player exists')
    }),
  )

export const increaseVolumeEpic: IEpic = ($action, $store) =>
  $action.pipe(
    ofType(actions.AIncreaseVolume.type),
    withLatestFrom($store),
    map(([action, store]) => {
      const tpc = store.trackPlayerComponent
      if (tpc.player) {
        tpc.player.volume =
          tpc.player.volume > 0.9
            ? 1
            : Math.round((tpc.player.volume + 0.1) * 10) / 10
        return actions.AIncreasedVolume.create(tpc.player.volume)
      } else {
        return actions.AIncreaseVolumeFailed.create(
          new Error('Failed to increase volume'),
        )
      }
    }),
  )

export const decreaseVolumeEpic: IEpic = ($action, $store) =>
  $action.pipe(
    ofType(actions.ADecreaseVolume.type),
    withLatestFrom($store),
    map(([action, store]) => {
      const tpc = store.trackPlayerComponent
      if (tpc.player) {
        tpc.player.volume =
          tpc.player.volume < 0.1
            ? 0
            : Math.round((tpc.player.volume - 0.1) * 10) / 10
        return actions.ADecreasedVolume.create(tpc.player.volume)
      } else {
        return actions.ADecreaseVolumeFailed.create(
          new Error('Failed to decrease volume'),
        )
      }
    }),
  )

export const createTrackVisualiserEpic: IEpic = $action =>
  $action.pipe(
    ofType(actions.ATrackPlayerCreated.type),
    map(action => {
      const tpc = action.trackPlayerComponent
      if (!tpc.player) {
        return actions.ACreateTrackVisualiserFailed.create(
          new Error('no player'),
        )
      }
      try {
        const canvas = document.getElementById(
          'visualiser-canvas',
        ) as HTMLCanvasElement
        const canvasCtx = canvas.getContext('2d')
        if (!canvasCtx) {
          return actions.ACreateTrackVisualiserFailed.create(
            new Error('No canvas context available'),
          )
        }
        const audioCtx = new AudioContext()
        const audioSource = audioCtx.createMediaElementSource(tpc.player)
        const analyser = audioCtx.createAnalyser()
        const draw = () => {
          window.requestAnimationFrame(draw)

          const fbcArray: Uint8Array = new Uint8Array(
            analyser.frequencyBinCount,
          )
          analyser.getByteFrequencyData(fbcArray)
          canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
          canvasCtx.fillStyle = '#000'
          const bars = 200
          for (let i = 0; i < bars; i++) {
            const barX = i * 2
            const barWidth = 1
            const barHeight = -(fbcArray[i] / 3)
            canvasCtx.fillRect(barX, canvas.height, barWidth, barHeight)
          }
        }
        audioSource.connect(analyser)
        analyser.connect(audioCtx.destination)
        draw()
        return actions.ATrackVisualiserCreated.create(tpc)
      } catch (e) {
        return actions.ACreateTrackVisualiserFailed.create(e)
      }
    }),
  )

export default [
  createTrackPlayerComponentEpic,
  playTrackEpic,
  pauseTrackEpic,
  increaseVolumeEpic,
  decreaseVolumeEpic,
  createTrackVisualiserEpic,
]
