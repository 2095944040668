import basicBiquadFilter from './basicBiquadFilter'
import basicDelay from './basicDelay'
import basicMixer from './basicMixer'
import basicMult from './basicMult'
import basicOscillator from './basicOscillator'
import masterOutput from './masterOutput'
import basicCompressor from './basicCompressor'
import vuMono from './vu-mono'
import oscilloscope from './oscilloscope'

export default [
  basicOscillator(),
  basicOscillator([6, 0]),
  basicOscillator([12, 0]),
  basicOscillator([18, 0]),
  basicOscillator([24, 0]),
  basicOscillator([30, 0]),
  basicMult([36, 0]),
  basicMult([38, 0]),
  masterOutput([40, 0]),
  basicBiquadFilter([0, 1]),
  basicBiquadFilter([9, 1]),
  oscilloscope([18, 1]),
  basicCompressor([35, 1]),
  // vuMono([32, 1]),
  basicDelay([41, 1]),
  basicMixer([44, 1]),
]
