import { throttle } from 'lodash'
import paper, {
  Group,
  MouseEvent,
  Path,
  Point,
  PointText,
  Rectangle,
  Symbol,
} from 'paper'
import Button from '../controlsComponents/Button'
import Dial from '../controlsComponents/Dial'
import { IModuleSpec } from '../moduleSpecs/moduleSpecs.model'
import { getItemAtPosition } from '../patching/patching.model'
import { IConfig } from '../setup/config'
import { ELayerNames, getLayerByName } from '../setup/layers'
import { EColours } from '../utils/colors'
import { randomBetween } from '../utils/conversions'
import positionController from '../utils/positionController'
import { IRackModuleBase } from './rackModule.model'

class RackModule implements IRackModuleBase {
  public screw!: paper.Symbol
  protected layer = getLayerByName(ELayerNames.MODULES)

  public constructor(public spec: IModuleSpec, public config: IConfig) {}

  get ctx() {
    return this.config.ctx
  }

  get bounds() {
    return this.spec.bounds
  }

  public draw() {
    this.layer.activate()
    this.screw = this.createScrewSymbol()
    this.drawBg()
  }

  protected createDialCtrl(name: string, callback: (dial: Dial) => void): Dial {
    const spec = this.spec.dials[name]
    const dial = new Dial(
      spec,
      positionController.getCtrlPosition(spec, this.bounds.topLeft),
      this.config,
    )
    if (callback) {
      callback(dial)
    }
    return dial
  }

  protected createBtnCtrl(
    name: string,
    callback?: (btn: Button) => void,
  ): Button {
    const spec = this.spec.buttons[name]
    const btn = new Button(
      spec,
      positionController.getCtrlPosition(spec, this.bounds.topLeft),
      this.config,
    )
    if (callback) {
      callback(btn)
    }
    return btn
  }

  //////////////////////////////////////////////////
  // Create background
  //////////////////////////////////////////////////

  private drawBg(): void {
    this.drawBackPlate()
    this.drawScrews()
    this.drawName()
  }

  private drawBackPlate() {
    const { bgColor, bounds } = this.spec
    const plate = new Path.Rectangle(bounds)
    plate.set({
      fillColor: bgColor,
      strokeColor: EColours.BLACK,
      strokeWidth: 1,
    })
    plate.sendToBack()
  }

  private drawScrews() {
    if (!this.screw) {
      return
    }
    const { rowHeight, unit } = this.config.dimensions
    const {
      pos: [xOffMod, yOffMod],
      width,
    } = this.spec
    const angles = [0, 0, 0, 0].map(() => Math.floor(Math.random() * 360))
    const isLogo = randomBetween(0, 7)
    for (let i = 0; i < 4; i++) {
      const railIdx = i < 2 ? 0 : 1
      const topY = unit / 2
      const bottomY = railIdx * rowHeight - unit / 2
      const xPos = (i % 2) * (width - 1) * unit + unit / 2
      const xOff = xOffMod * unit
      const yOff = yOffMod * rowHeight
      const sX = xOff + xPos
      const sY = yOff + (i < 2 ? topY : bottomY)
      const finalPos = new Point(sX, sY)
      const screw = this.screw.place(finalPos)
      screw.rotate(angles[i])
      if (i === isLogo) {
        const logo = this.config.symbols.logo.place(finalPos)
        logo.scale(unit / 1400)
        logo.rotate(angles[i])
      }
    }
  }

  private drawName(): Group {
    const { unit } = this.config.dimensions
    const {
      name,
      namePos: [nameXOffset, nameYOffset],
    } = this.spec
    return new Group({
      children: name.map((segment, i) => {
        const pos = new Point(
          nameXOffset * unit,
          (nameYOffset + 1 + i) * unit - (i === 0 ? 6 : unit / 2),
        ).add(this.bounds.topLeft)
        return new PointText({
          point: pos,
          content: segment,
          fillColor: EColours.WHITE,
          shadowColor: EColours.BLUE,
          shadowBlur: 3,
          fontFamily: 'sans-serif',
          fontWeight: 'bold',
          fontSize: unit / 1.75,
          justification: 'center',
        })
      }),
    })
  }

  private createScrewSymbol(): paper.Symbol {
    const { screwRadius } = this.config.dimensions
    const sGroup = new Group({
      applyMatrix: false,
    })
    const circle = new Path.Circle(new Point(0, 0), screwRadius)
    circle.fillColor = EColours.WHITE

    const line = new Path()
    line.strokeColor = EColours.GREY
    line.add(new Point(0, -screwRadius))
    line.add(new Point(0, screwRadius))
    sGroup.addChildren([circle, line])
    return new Symbol(sGroup)
  }
}

export default RackModule
